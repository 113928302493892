

.content_box {
  padding: .4rem .15rem;
  .wallet_connect_btn_metamask {
    width: 100%;
    height: 65px; // 52
    background: #BF5925;
    box-shadow: 0px 10px 14px -5px rgba(139,67,30,0.47);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px;
    cursor: pointer;
    border: none;
    .left_icon {
       width: 40px;
       height: 40px;
    }
    .center_name {
       color: #fff;
       font-size: 15px;
       font-weight: 700;
    }
    .right_icon {
       width: 45px;
       height: 45px;
    }
    &:hover, &:active, &:focus {
      background: #BF5925;
    }
  }
  .wallet_connect_btn_walletcon {
     width: 100%;
     height: 65px;
     background: linear-gradient(90deg, #1D73D0 0%, #8600C6 100%);
     box-shadow: 0px 10px 14px -5px rgba(98,54,255,0.05);
     border-radius: 4px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 4px 10px;
     margin-top: 30px;
     cursor: pointer;
     border: none;
     .left_icon {
         width: 40px;
         height: 40px;
      }
      .center_name {
         color: #fff;
         font-size: 15px;
         font-weight: 700;
      }
      .right_icon {
         width: 45px;
         height: 45px;
      }
      &:hover, &:active, &:focus {
        background: linear-gradient(90deg, #1D73D0 0%, #8600C6 100%);
      }
  }


  .btn_wallet_connect {
    width: 100%;
    height: .65rem; // 5.32
    line-height: .18rem;
    background: linear-gradient(90deg,#1d73d0,#8600c6) !important;
    border-radius: .13rem;
    margin: .35rem 0 0 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    .title {
      font-size: .18rem;
      color: white;
      font-weight: 600;
      font-family: "Robot";
    }
    .sub_title {
      font-size: .13rem;
      color: #d9dada;
      font-family: "Robot";
    }
  }
  .btn_create_wallet {
    width: 100%;
    height: .65rem;
    line-height: .18rem;
    background: #ED784B !important;
    border-radius: .13rem;
    margin: 0 0 .2rem 0;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;
    .title {
      font-size: .18rem;
      color: white;
      font-weight: 600;
      font-family: "Robot";
    }
    .sub_title {
      font-size: .13rem;
      color: #e8eaea;
      font-family: "Robot";
    }
  }
}

@media only screen and (max-width: 640px) {
  .content_box {
    padding: .3rem .3rem;
    .wallet_connect_btn_metamask {
      width: 100%;
      height: 55px; // 52
      background: #BF5925;
      box-shadow: 0px 10px 14px -5px rgba(139,67,30,0.47);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 10px;
      cursor: pointer;
      border: none;
      .left_icon {
        width: 40px;
        height: 40px;
      }
      .center_name {
        color: #fff;
        font-size: 15px;
        font-weight: 700;
      }
      .right_icon {
        width: 45px;
        height: 45px;
      }
      &:hover, &:active, &:focus {
        background: #BF5925;
      }
    }
    .wallet_connect_btn_walletcon {
      width: 100%;
      height: 55px;
      background: linear-gradient(90deg, #1D73D0 0%, #8600C6 100%);
      box-shadow: 0px 10px 14px -5px rgba(98,54,255,0.05);
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 4px 10px;
      margin-top: 30px;
      cursor: pointer;
      border: none;
      .left_icon {
        width: 40px;
        height: 40px;
      }
      .center_name {
        color: #fff;
        font-size: 15px;
        font-weight: 700;
      }
      .right_icon {
        width: 45px;
        height: 45px;
      }
      &:hover, &:active, &:focus {
        background: linear-gradient(90deg, #1D73D0 0%, #8600C6 100%);
      }
    }
    .btn_wallet_connect {
      height: .99rem;
      line-height: .36rem;
      margin: .55rem 0 0 0;
      border-radius: .18rem;
      .title {
        font-size: .3rem;
        font-weight: 700;
      }
      .sub_title {
        font-size: .25rem;
        color: rgba(232, 234, 234, 0.71);
      }
    }
    .btn_create_wallet {
      height: .99rem;
      line-height: .36rem;
      margin: 0 0 .4rem 0;
      border-radius: .18rem;
      .title {
        font-size: .3rem;
        font-weight: 700;
      }
      .sub_title {
        font-size: .25rem;
        color: rgba(232, 234, 234, 0.71);
      }
    }
  }
}


