@font-face {
  font-family: 'Robot';
  src: url('../public/font/Roboto-Light.ttf') format("truetype");
  font-weight: 200;
}
@font-face {
  font-family: 'Robot';
  src: url('../public/font/Roboto-Regular.ttf') format("truetype");
  font-weight: 500;
}
@font-face {
  font-family: 'Robot';
  src: url('../public/font/Roboto-Medium.ttf') format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: 'Robot';
  src: url('../public/font/Roboto-Bold.ttf') format("truetype");
  font-weight: 800;
}
@font-face {
  font-family: 'Robot-italic';
  src: url('../public/font/Roboto-BoldItalic.ttf') format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Gats';
  src: url('../public/font/Goetz.otf') format("truetype");
}

@font-face {
  font-family: 'BMDoHyeon-OTF';
  src: url('../public/font/BMDoHyeon-OTF.otf') format("truetype");
}

@font-face {
  font-family: 'Montserrat';
  src: url('../public/font/Montserrat-VariableFont_wght.ttf') format('truetype');
}

@font-face {
  font-family: 'Gothic';
  src: url("../public/font/Gothic/GothicA1-Light.ttf") format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Gothic';
  src: url("../public/font/Gothic/GothicA1-Regular.ttf") format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Gothic';
  src: url("../public/font/Gothic/GothicA1-Medium.ttf") format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Gothic';
  src: url("../public/font/Gothic/GothicA1-Bold.ttf") format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Gothic';
  src: url("../public/font/Gothic/GothicA1-Bold.ttf") format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Gothic';
  src: url("../public/font/Gothic/GothicA1-Black.ttf") format('truetype');
  font-weight: bold;
  font-style: italic;
}

iframe .D8bnZd  {
  background-color: red;
}

* {
  margin: 0;
  padding: 0;
}
htmml, body {
  /* font-family: 'Robot'    !important; */
  /* font-family: Robot, Montserrat, Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif!important; */
  /* font-family:  Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif; */
  font-family: "SF Pro Display","SF Pro Icons","Helvetica Neue","Helvetica","Arial",sans-serif;
  background-color: #000;
}
.game-iframe {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.pc-show {
  display: block;
}
.first-charge-wra {
  display: flex;
  align-items: center;
  /*background-color: #E92A02;*/
  /*padding: 0 10px;*/
  border-radius: 8px;
  margin-right: 30px;
  /*border: 1px solid #5B2300;*/
  cursor: pointer;
}
.first-charge-wra .first-charge-icon {
  width: 100px;
  height: 69px;
  background: url("../src/imgs/home/first_charge.png") top no-repeat;
  background-size: contain;
  /*margin-top: -15px;*/
  /*margin-right: 10px;*/
  /*margin-bottom: 20px;*/
}
.first-charge-wra .text-first {
  color: #FEFF38;
  font-weight: 500;
  font-size: 18px;
  /*margin-right: 30px;*/
  cursor: pointer;
}

.mobile-show {
  display: none;
}
.white {
  color: #fff;
}
.bfb-image {
  width: 100%;
}
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}
.app-layout {
  /* background: #011118; */
  min-height:100vh;
  box-sizing: border-box;
  display: flex;
  flex: auto;
  flex-direction: row;
}
.content-container {
   min-height: 100vh;
   flex: auto;
}
.content-container > .ant-layout  {
   /* border-radius: 30px; */
   /* padding-right: 70px; */
   /* margin-right: 50px; */
}
.app-layout .header-copy {
  height: 90px;
}

.menu-tooltip {
  padding-top: 2px;
  z-index: 2920;
}
.menu-tooltip .ant-tooltip-inner {
  background-color: #132024;
  color: #D8D8D8;
  border-radius: 10px;
  line-height: unset;
  font-size: 15px;
  padding: 8px 24px 8px;
}

.menu-tooltip .ant-tooltip-arrow {
  top: 13px;
}
.menu-tooltip .ant-tooltip-arrow-content {
  width: 9px;
  height: 9px;
  background: #132024;
  top: 2px;
}


.header_get_started_btn{
  background: linear-gradient(294deg,#F488B9 28.05%, #590B57 90.11%);
  width: fit-content;
  height: 40px;
  min-width: 120px;
  line-height: 40px;
  font-size: 14px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  --un-text-opacity: 1;
  color: rgba(255,255,255,var(--un-text-opacity));
  border-width: 3px;
  border-color: rgba(89, 11, 87, 0.3);
  border-radius: 30px;
  border-style: solid;
  padding-left: 20px;
  padding-right: 20px;
  background-clip: padding-box;
  border: unset;
}
.header_get_started_btn:hover{
  background: linear-gradient(294deg,#F488B9 28.05%, #590B57 90.11%);
  color: #FFFFFF;
}

.header_get_started_btn:active{
  margin-top: 10px;
}

.app-layout .aside {
  background: #0c1216;
  z-index: 1100;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
}
.app-layout .aside-copy {
  /* width: 265px; */
  min-width: 265px;
  max-width: 265px;
  width: 265px;

}
.app-layout-close .aside-copy {
  /* width: 96px; */
  min-width: 96px;
  max-width: 96px;
  width: 96px;
}
.app-layout-close .logo {
  /* display: none; */
}
.app-layout-close .aside-content {
  padding: 25px 26px 0 26px!important;
}
.app-layout-close .aside-menu-name {
  /* display: none; */
  /* font-size: 0; */
  display: none!important;
}
.app-layout-close .aside-menu-item {
  padding: 10px!important;
  /* margin-bottom: 0px!important; */
  min-width: 40px;
}
.app-layout-close .aside-title {
  font-size: 0;
  opacity: 0;
}
.app-layout-close .aside-download-app {
  /* display: none; */
  background: unset!important;
  justify-content: center;
  padding-left: 0!important;
}
.app-layout-close .aside-download-app .aside-download-app-icon {
   background: url('http://static-img.igamie.com/static/new_home_pics/download_app_google.png') center no-repeat!important;
   background-size: contain!important;
   width: 23px!important;
   height: 24px!important;
}
.app-layout-close .name {
  display: none;
}
.app-layout-close .aside-community-flex {
  flex-direction: column;
}
.app-layout-close .aside-community-item {
  margin-right: 0!important;
  margin-bottom: 8px;
  padding-left: 0!important;
  display: flex;
  justify-content: center;
}
.app-layout-close .aside-language-select {
  padding: 0!important;
  background: unset!important;
  justify-content: center!important;
}

.app-layout-close .language-content {
  margin:  0 auto;
}

.aside-language-select >span {
    position: relative;
    width: 100%;
    height: 50px;
    /* background: #1a282f; */
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}

.ant-form-item-label {
  display: inline-block;
  flex-grow: 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
  display: flex;
  align-items: center;
}

.language-popover {
  z-index: 2999;
}
.language-popover .ant-popover-inner-content {
  padding: 0;
  background: unset;
}
.language-popover .menu-overlay {
    /* position: absolute; */
    width: 209px;
    background: #132024;
    border-radius: 10px;
    bottom: 60px;
    left: 0;
    transition: height .3s ease;
    padding: 0px 20px 10px 20px;
    overflow: hidden;
    z-index: 9999;

}
.language-popover .language-item {
  color: #D8D8D8;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
  padding: 8px 0 8px 0;
  border-radius: 7px;
  transition: all .2s ease;
  cursor: pointer;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}
.language-popover .language-item:hover {
  background: #1a282f;
}

.app-layout-close .language-name {
   display: none;
}
.app-layout-close .download-app-wrapper {
   margin-bottom: 0!important;
}
.app-layout-close .language-arrow {
  display: none;
}

.mobile-header-logo-new {
   background: url('../src/imgs/mobile_header_logo_label.png') left no-repeat;
   background-size: contain;
    width: 80.2px;
    height: 22px;
   /*margin-left: 8px;*/
}
.mobile-discord-icon {
  width: .5rem;
  height: .5rem;
  background: url('../src/imgs/generate/discord_icon.webp') center no-repeat;
  background-size: contain;
}

.pc-discord-icon {
  width: 35px;
  height: 35px;
  margin-top: 20px;
  background: url('../src/imgs/generate/discord_icon.webp') center no-repeat;
  background-size: contain;
}
.header-menu-icon {
  position: relative;
  width: 22px;
  height: 18px;
  display: inline-block;
  margin-left: 15px;

}
.header-menu-icon .style-menu-line {
  background-color: #fff;
  height: 2.2px;
  /* width: 100%; */
  width: 22px;
  border-radius: 10px;
  position: absolute;
  left: 0;
  -webkit-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}
.header-menu-icon .style-menu-line-2 {
  top: 0;
  bottom: 0;
  right: 0;
  left: unset;
  margin: auto;
  width: 13px;
}
.header-menu-icon .style-menu-line-3 {
  bottom: 0;
  right: 0;
  left: unset;
  width: 18px;
}


.ant-layout {
  background: #000;
}
.footer {
  background: #0c1216;
  padding: 40px 60px;
}
.footer-wrapper {
  display: flex;
  justify-content: space-between;
}
.footer .footer-left {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: self-start;
}
.footer .footer-right {
}
.footer-logo-wrapper {
  display: flex;
  align-items: center;
}
.footer-logo-wrapper img {
  width: 120px;
}
.copyright-wrapper {
  display: flex;
}

.g_apple_download {
  width: 150px;
  height: 41px;
  background: url("../src/imgs/home/apple_download.png") center no-repeat;
  background-size: contain;
  cursor: pointer;
}
.g_android_download {
  width: 150px;
  height: 40px;
  background: url("../src/imgs/home/android_download.svg") center no-repeat;
  background-size: contain;
  cursor: pointer;
}

.copyright-wrapper a, .copyright-wrapper span{
  color: #9CA0A2;
  font-weight: 600;
  margin-right: 50px;
  font-size: 14px;
}
.footer-right {
  color:#D8D8D8;
  font-size: 15px;
  font-weight: 600;
}
.footer-right .footer-link-wrapper{
    /* margin-bottom: 10px; */
    text-align: right;
    padding-bottom: 10px;
    margin-bottom: 10px;
}
.footer-right .footer-link-wrapper:last-child {
  margin-bottom: 0;
}
.footer-right a {
  color: #9CA0A2;
}
.footer-bottom {
  display: none;
}



/**
 *
  airdrop tab
 **/
.airdrop-tabs {
  margin-top: 22px;
}
.airdrop-tabs .ant-tabs-tab {
   color: #fff;
   font-weight: 600;
   font-size: 21px;
   padding: 18px 0;
}
.airdrop-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 60px;
}
.airdrop-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
   font-weight: 700;
   font-size: 21px;
}
.airdrop-tabs .ant-tabs-nav {
  margin-bottom: 0!important;
}
/* .airdrop-tabs .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before{
  border-bottom: none;
} */
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: none!important;
}
.ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before {
  display: none!important;
}
.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar, .ant-tabs-top > div > .ant-tabs-nav .ant-tabs-ink-bar {
  /*width: 0!important;*/
}

/* 首页tab*/
.header-tab-warpper {
  display: flex;
}
.header-tab-warpper .header-tab-item {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 24px;
  margin-right: 30px;
  cursor: pointer;
  font-family: Montserrat-Medium, Montserrat;
}
.header-tab-warpper .header-tab-item-active {
  color: #F488B9;
}


.skeleton-block {
  display: block;
  width: 100%!important;
  height: 100%!important;
  opacity: .8;
}

.fa-skeletons {
  overflow: hidden;
  animation: loadingsk 8s linear infinite;
  background-image: linear-gradient(50deg, #e8e7e726, #e8e7e730 52%, #f1f1f136 55%, #e8e7e733 58%, #e8e7e73c);
  background-size: 400% 100%;
  border-radius: .2rem;
  /* min-height: 2.6rem; */
}

/*
 discover masonry list style
*/
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -20px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 20px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  margin-bottom: 20px;
}



@keyframes loadingsk{ /* 骨架屏的动画 */
  0% {
    background-position: 400% 50%
  }
  to {
      background-position: 0% 50%
  }
}



@keyframes fadeIn {
  from {
    opacity: .6;
  }

  to {
    opacity: 1;
  }
}

.particle-wallet-entry-container .particle-pwe-btn {
  width: .5rem!important;
  height: .5rem!important;
  right: .3rem!important;
  bottom: 294px!important;
  left: unset!important;
  top: unset!important;
}
.fadeIn {
  /* animation-name: fadeIn; */
  /* animation: fadeIn .5s ease-in-out; */
}
.gold-color {
  color: #D4A82B;
}
.success-color {
  color:#9DA9AE;
  display: flex;
  align-items: center;
}
.success-color-connected {
  color: #21BC9A;
  display: flex;
  align-items: center;
  margin-right: 7px;
  font-size: 14px;
  cursor: pointer;
}

.option-color{
  color: #FFFFFF !important;
  background: rgba(255, 255, 255, 0) !important;
  text-align: center;
  height: 45px;
  line-height:45px;
  font-size: 17px !important;
  font-weight: 500 !important;
  cursor: pointer
}
.option-color:hover{
  background: #354C57 !important;
  font-weight: 700 !important;
}

.option-color .ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background: rgba(255, 255, 255, 0);
  color: #FFFFFF;
}


.ms-mobile-select .ms-select-line{
  border: none !important;
  background: #DCE1E2;
  z-index: -1;
  border-radius:5px;
}
.ms-mobile-select .ms-select-container li{
  font-weight: 500;
  font-size: 17px !important;
}
.ms-mobile-select .ms-ensure{
  font-size: 17px;
  font-weight: 600;
}
.ms-mobile-select .ms-cancel{
  font-weight: 600;
  font-size: 17px;
}

.ms-mobile-select .ms-btn-bar{
  border: none !important;
}

.edit-username .ant-input:focus, .ant-input-affix-wrapper-focused .ant-input{
  box-shadow: none !important;
  color: #FFFFFF  !important;
}

.edit-wallet-address{
  margin-left: 30px;
}

.copy_wallet_address{
  cursor: pointer;
}

.wallet-success-icon {
  background: url('../src/imgs/coins_di_check.png') center no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 3px;
}
.unset-link {
  color: unset;
  font-weight: 600;
}
.preload-img {
  position: absolute;
  width: 1px;
  height: 1px;
  opacity: 0;
}

.pk-progress .ant-progress-bg {
  height: .22rem!important;
}
.pk-progress .ant-progress-inner {
  background-color: #c498e2;
}

::-webkit-scrollbar {
  height: 0px;
  width: 1px;
  background: rgba(255,255,255,0);
  display: none;

}

h1,h2,p {
  margin: unset;
}

div::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.recaptcha-wrapper {
  position: fixed;
  background: rgba(0,0,0,.4);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.recaptcha-wrapper-hidden {
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  z-index: -1;
}
.blindbox-donwload-modal {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: .1rem 0 0rem 0;
}
.blindbox-donwload-modal .download-avatar {
   width: 1.6rem;
}
.blindbox-donwload-modal .download-icon {
  width: 2.6rem;
  margin-top: .2rem;
}

.pk-success-wrapper {

}

.line-through {
  text-decoration: line-through;
}

.box-show {
}

.box-hidden {
   visibility: hidden;
   opacity: 0;
}

.editable-text {
  /* color: #728189; */
  color:#9DA9AE;
}

.editing-text {
  color: #828E94;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-space-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ant-switch-checked {
  background-color: #F488B9;
}

.login-avatar-name {
  margin: 0 0px 0 0px;
  display: flex;
  align-items: center;
}

.login-menu-1 {
  z-index: 2999;
}
/* .tanslate-menu {
  transform: translateX(-20px);
} */

.igamie-form-item {
}
.igamie-form-item .ant-form-item-label label {
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
}
.igamie-form-item .ant-form-item-control .ant-form-item-explain-error {
  font-size: 14px;
  font-weight: 600;
}
.imomo-spin {

}
.imomo-spin .ant-spin-dot-item {
  background-color: #F488B9;
}
.imomo-spin .ant-spin-text {
  font-size: 18px;
  font-weight: 600;
  color: #F488B9;
  text-shadow: unset !important;
}

.igamie-collapse-sty {
  background-color: rgba(0, 0, 0, 0);
  border: unset;
  border-radius: 8px;
  overflow: hidden;
}

.igamie-collapse-sty .ant-collapse-item {
  border-bottom: unset;
}

.igamie-collapse-sty .ant-collapse-item .ant-collapse-header {
  padding: 0;
  margin-bottom: 5px;
}

.igamie-collapse-sty .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
  color: #FFFFFF;
}
.igamie-collapse-sty .ant-collapse-content {
  background: rgba(73, 73, 73, 0.26);
  padding: 0 .15rem .15rem;
  border: unset;
  overflow: hidden;
  border-radius: .08rem !important;
}
.imomo-generate-collapse .ant-collapse-content {
  padding: unset;
  background: unset;
}
.igamie-collapse-sty .ant-collapse-content .ant-collapse-content-box {
  padding: unset;
  border-radius: 8px;
  overflow: hidden;
}

.igamie-collapse-head-sty {

}

.imomo-discover-collapse-sty {
  background-color: #132025;
  border: unset;
  border-radius: 8px;
  overflow: hidden;
  padding: 5px 10px 1px;
}

.imomo-discover-collapse-sty .ant-collapse-item {
  border-bottom: unset;
}

.imomo-discover-collapse-sty .ant-collapse-item .ant-collapse-header {
  padding: 0;
  margin-bottom: 5px;
}

.imomo-discover-collapse-sty .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
  color: #FFFFFF;
}
.imomo-discover-collapse-sty .ant-collapse-content {
  background: #132025;
  border: unset;
  border-radius: 8px;
  overflow: hidden;
  padding: 10px;
}
.imomo-discover-collapse-sty .ant-collapse-content .ant-collapse-content-box {
  padding: unset;
  border-radius: 8px;
  overflow: hidden;
}


/**
 * 积分兑换不足弹框
**/

.game-passed-css-mask {
    background-color: rgba(0,0,0,.8);
    bottom: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: flex;
    inset: 0;
    justify-content: center;
    left: 0;
    margin: 0;
    min-width: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 120000;
}

.no-points-modal-content {
    background: url('../src/imgs/no-points-modal-bg.webp') center no-repeat;
    width: 400px;
    height: 521px;
    background-size: contain;
    margin-top: 100px;
    position: relative;
    padding: 1.2rem .5rem .8rem .5rem;
    animation: animation-modal 0.3s ease-in-out 0s 1 normal none running;
}
.no-points-modal-content .no-points-title {
  font-size: .3rem;
  color: #fff;
  font-weight: 600;
  text-align: center;
}
.no-points-modal-content .no-points-cart {
  background: url('../src/imgs/no-points-cart.png') center no-repeat;
  background-size: cover;
  width: 2.76rem;
  height: 1.32rem;
  margin: .15rem auto;
}
.no-points-modal-content .no-points-title-1 {
  font-size: .3rem;
  color: #fff;
  font-weight: 600;
  text-align: center;
  color: rgb(207,162,53);
}

.no-points-modal-content .btn-wrapper {
    position: absolute;
    bottom: 60px;
    height: 70px;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 35px;
}
.no-points-modal-content .cancel-btn {
  /* flex: 1; */
  /* height: 100%; */
  cursor: pointer;
  width: 160px;
  height: 60px;
  color: #fff;
  border: 3px solid rgb(28,66,77);
  border-radius: 10px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
  font-size: 25px;

}
.no-points-modal-content .confirm-btn {
  /* flex: 1;  */
  /* height: 100%; */
  width: 160px;
  height: 60px;
  color: #fff;
  cursor: pointer;
  background: linear-gradient(180deg,#b38f29 42%,#d7b144);
  border: 3px solid rgba(208,170,62,.79);
  border-radius: 10px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
  font-size: 25px;
}


.tanslate-menu .ant-dropdown-menu {
  margin-left: 2px!important;
}
.tanslate-menu .ant-dropdown-menu-item {
  padding: 5px 26px 5px 36px!important;
}

.dropdown-menu-away .ant-dropdown-menu {
  margin-left: unset !important;
  padding-left: 20px !important;
  padding-right: 20px !important;
  background-color: #0F1517 !important;
  padding-bottom: 20px !important;
  border-radius: 16px !important;
}

.dropdown-menu-away .ant-dropdown-menu-item {
  font-size: 14px !important;
  text-align: center !important;
  margin-top: 10px !important;
  padding: 5px 26px 5px 26px!important;
  font-weight: 600 !important;
  border-radius: 7px !important;
}

.dropdown-menu-away .ant-dropdown-menu-item:hover {
  background-color: unset !important;
}


.dropdown-menu-game-sort .ant-dropdown-menu {
  width: 195px !important;
  margin-left: unset !important;
  padding-left: 0 !important;
  padding-right: 0 !important;
  background: #132024 !important;
  padding-bottom: 14px !important;
  padding-top: 14px !important;
  border-radius: 6px !important;
}

.dropdown-menu-game-sort .ant-dropdown-menu-item {
  font-size: 15px !important;
  text-align: start !important;
  padding: 0 15px!important;
  height: 37px;
  line-height: 37px;
  border-radius: 7px !important;
  font-weight: 500 !important;
  color: #FFFFFF;
}

.dropdown-menu-game-sort .ant-dropdown-menu-item:hover {
  background: #1E2F34 !important;
  border-radius: 0 !important;
}

.login-menu-ty {
  margin: 0 0px 0 15px;
}

.notice-popover-i {
  margin-left: 0;
  margin-right: 23px;
}

.notice-popover-i .bellfilled {
   font-size: 21px;
   color: #ccc;
   vertical-align: middle;
   cursor: pointer;
}

.notice-popover {
  z-index: 100;

}

.language-empty {
  height: 290px;
  width: 260px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.language-empty .ant-spin {
  color: #005050!important;
}


.notice-popover .ant-popover-inner {
   background-color: #0F1517;
   border-radius: 10px;
}
.notice-popover .ant-popover-inner-content {
  padding: 0;
}
.notice-popover .ant-popover-arrow {
  /* width: 14.4px;
  height: 14.4px;
  top: 0; */
}
.notice-popover  .ant-popover-arrow-content {
  /* width: 10px;
  height: 10px; */
  background-color: #25363F!important;
  display: none;
}

.login-menu-ty .bellfilled {
   color: rgb(204, 203, 203);
   vertical-align: text-top;
}

.language-menu-dropdown {
  /*width: 100%;*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: #1A282F; */
  /* border-radius: 6px; */
  /* padding: 10px 22px; */
  margin-left: 20px;
  margin-right: 20px;
}
.language-menu-dropdown .global-language {
  background: url('../src/imgs/globalLang.svg') center no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}
.language-menu-dropdown .selected-language {
  color: #fff;
  font-weight: 600;
}
.language-menu-dropdown .selectTri {
  width: 10px;
  margin-left: 12px;
  vertical-align: revert;
}

/*radio*/
.imomo-radio-button {
  border-radius: 8px;
  overflow: hidden;
}
.imomo-radio-button .ant-radio-button-wrapper  {
  background: #132024;
  border: #132024;
  color: #9CA0A2;
  font-size: 15px;
  font-weight: 500;
}

.imomo-radio-button .ant-radio-button-wrapper-checked {
  background: #F488B9 !important;
  border: unset !important;
  color: #FFFFFF;
}

.imomo-radio-button .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background: #F488B9 !important;
}

.en-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  background: url("../src/imgs/generate/en.png") center no-repeat;
  background-size: contain;
}
.ja-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  background: url("../src/imgs/generate/jp.png") center no-repeat;
  background-size: contain;
}


.avatar-circle {
  border: 2px solid rgba(255,255,255,.2);
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: inline-block;

}
.user-logout-wrapper {
  /* width: 162px; */
  height: 40px;
  padding: 0 10px;
  background: #F488B9;
  border-radius: 20px;
  display: flex;
  align-items: center;
}
.user-logout-wrapper-login {
  height: 40px;
  padding: 0 10px;
  border: 1px solid #F488B9;
  /*background: #F488B9;*/
  color: #F488B9;
  border-radius: 20px;
  display: flex;
  align-items: center;
}
.user-logout-wrapper-login .user-logout-txt {
  font-size: 13px;
  color: #F488B9;
  font-weight: 700;
  margin: 0 6px;
}
.user-logout-line {
  width: 1px;
  height: 16px;
  background: #E2A780;
}
.user-logout-txt {
  font-size: 13px;
  color: #590B57;
  font-weight: 700;
  margin: 0 6px;
}


.user-icon-new-logout {
  background: url('../src/imgs/head.svg') center no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
  padding-left: 20px;
  margin-right: 10px;
}

.login-menu-1 .ant-dropdown-menu {
  background: #25363F;
  margin-left: -13px;
  border-radius: 5px;
}
.login-menu-1 .ant-dropdown-arrow {
  border-top-color: #25363F;
  border-left-color: #25363F;
  left: 10px;
  display: none;
}

.login-menu-1 .empty-con {
  width: 20px;
  height: 5px;
}
.login-menu-1 .ant-dropdown-menu-item {
  font-size: 12px;
  color: #ddd;
  padding: 5px 23px;
}
.login-menu-1 .ant-dropdown-menu-item:hover {
  color: #ddd;
  background-color: rgba(255, 255, 255, 0.1);
}
.login-menu-1 .coin-new {
  width: 30px;
}

.login-menu-1 .region-flag {
  width: 14px;
}
.login-menu-1 .context {
  font-weight: 600;
  margin-left: 4px;
  display: inline-block;
}

.ant-notification {
  margin-right: .1rem;
}

.coins-notification {
  background: #17494D;
  border:1px solid #689397;
  border-radius: 100px;
  padding: .04rem .3rem .04rem .04rem;
  width: 360px;
}

.coins-notification .content-des {
   font-size: .15rem;
   color: #fff;
   font-weight: 600;
   margin-left: 4px;

}

.coins-notification .coins-num {
  font-size: .15rem;
  color: #d4a82b;
  font-weight: 600;

}


.coins-notification .coins-content {
  display: flex;
  align-items: center;
}

.coins-notification .ant-notification-notice-message {
  margin-bottom: 0;
}

.coins-notification .coin-icon {
  width: .5rem;
  margin-right: .1rem;
}



.coins-notification .ant-notification-notice-close {
  display: none;
}


.coins-notice-wrapper {
  box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 1200;
    inset: 0px;
    /* background-color: rgba(0, 0, 0, 0.2); */
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.coins-n-con {
  /* background: rgb(5 20 31 / 90%); */
  width: 200px;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: boxScale 1s 1 forwards;
  z-index: 999;
}

.coins-n-con .info-text {
  color: #fff;
  font-size: 20px;

}

.coin-bottom-num {
   color: #fff;
   background: linear-gradient(90deg,#f7150e,#fc6c29);
   font-size: 12px;
   padding: 0px 8px;
   border-radius: 100px;
   position: absolute;
   bottom: -3px;
   left: 50%;
   transform: translateX(-50%);
   opacity: 0;
   transition: all 1s;
   z-index: 9999;

}

.coins-notice-wrapper .coin-icon {
   width: 100px;
   height: 100px;
   /* position: absolute; */
   left:48%;
   top: 58%;
   margin-left: 0px;
   margin-top: 0px;
   position: fixed;
   z-index: 400;
   opacity: 0;
   transition:  height .5s linear, width .5s linear, left .5s linear, top .5s ease-in;
   animation: boxScale 1s 1 forwards;

}

@keyframes boxScale {
  from {
    transform: scale(0);
    opacity: 0;
    /* width: 0px;
    height: 0px; */
    /* opacity: 0; */

  }

  to {
    transform: scale(1);
    opacity: 1;
    /* height: 100px;
    width: 100px; */
    /* opacity: 1; */
  }
}


.animate__animated {
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;
  animation-duration: 1.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both
}
.animate__animated.animate__infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite
}
.animate__tada {
  -webkit-animation-name: tada;
  animation-name: tada
}

.animate__fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp
}

.animate__backInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight
}


.box-s-animation {
  animation: boxScale 1s 1 forwards;
}


@keyframes fadeInUp {
  0% {
      opacity: 0;
      -webkit-transform: translate3d(0,30px,0);
      transform: translate3d(0,30px,0)
  }

  to {
      opacity: 1;
      -webkit-transform: translateZ(0);
      transform: translateZ(0)
  }
}


@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(30px,0,0);
    transform: translate3d(30px,0,0)
  }

  to {
    opacity: 1;
    -webkit-transform: translateZ(0);
    transform: translateZ(0)
  }
}

@keyframes tada {
  0% {
      -webkit-transform: scaleX(1);
      transform: scaleX(1)
  }

  10%,20% {
      -webkit-transform: scale3d(.9,.9,.9) rotate(-10deg);
      transform:  rotate(-10deg)
  }

  30%,50%,70%,90% {
      -webkit-transform: scale3d(1.05,1.05,1.05)  rotate(10deg);
      transform: scale3d(1.05,1.05,1.05) rotate(10deg)
  }

  40%,60%,80% {
      -webkit-transform: scale3d(1.05,1.05,1.05) rotate(-10deg);
      transform: scale3d(1.05,1.05,1.05) rotate(-10deg)
  }

  to {
      -webkit-transform: scaleX(1);
      transform: scaleX(1)
  }
}

.box-gai-animation {
  animation: boxgai .5s  forwards;
}
@keyframes boxgai {
  from {
    transform: rotate(0) translateY(0) translateX(0) scale(1);

  }
  to {
    transform: rotate(-20deg) translateY(-.2rem) translateX(-.2rem) scale(.94);
  }
}

.blindbox-connect-modal {

}
.blindbox-connect-modal .ant-modal-content {
  background: rgba(0,0,0,0);
}
.blindbox-connect-modal .with-draw-modal {
    background: #003049;
    height: 220px;
    border-radius: 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0.75rem;
    padding: 0.35rem 0.4rem 0.5rem;
    position: relative;
}
.blindbox-connect-modal .close-btn {
  position: absolute;
    top: -.7rem;
    right: 0;
    background: url('../src/imgs/close_btn_icon.png') no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.blindbox-connect-modal .modal-title {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}
.blindbox-connect-modal .modal-next-btn  {
    background: linear-gradient(90deg,#1d73d0,#8600c6);
    border: none;
    border-radius: 4px;
    -webkit-box-shadow: 0 10px 14px -5px rgb(98 54 255 / 5%);
    box-shadow: 0 10px 14px -5px rgb(98 54 255 / 5%);
    color: #fff;
    cursor: pointer;
    font-size: 17px;
    height: 50px;
    line-height: 40px;
    margin-top: 0.3rem;
    font-weight: 700;
    text-align: center;
}

.blindbox-connect-modal .air-drop-winner-modal {
  padding: .22rem .4rem .4rem .4rem;
  height: unset;
  min-height: 200px;
  display: block;
}
.air-drop-winner-modal .winners-title {
  color: #fff;
  font-size: .3rem;
  text-align: center;
  margin-bottom: .2rem;
  font-weight: 600;
  color: #e2ecf0;
}
.with-draw-modal .air-drop-winner-line {
  font-weight: 600;
  text-align: center;
  margin-bottom: .15rem;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  color: #b4cbd6;
  white-space: nowrap;
  text-align: left;
}





#awardRe1 {
  background: #150F64;
}
#awardRe1 .award-register-btn {
  color: #fff;
  border: 1px solid rgba(144, 228, 255, 0.72);
  background: linear-gradient(360deg, #152697 0%, #4387E8 53%, #8B5FFA 100%, #A561F4 100%);
}
#awardRe1 .body {
  background: url('https://cdn.igamie.com/static/award_reg_bg1.webp') center no-repeat;
  background-size: contain;
}

#awardRe2 {
  background: #000516;

}
#awardRe2 .award-register-btn {
  color: #fff;
  background: linear-gradient(360deg, #112B50 0%, #4A95C8 100%);
  border: 1px solid rgba(144, 228, 255, 0.72);

}
#awardRe2 .body {
  background: url('https://cdn.igamie.com/static/award_reg_bg2.webp') center no-repeat;
  background-size: contain;
}
#awardRe3 {
  background: #05031d;
}
#awardRe3 .award-register-btn {
  color: #fff;
  background: linear-gradient(360deg, #152697 0%, #4387E8 53%, #8B5FFA 100%, #A561F4 100%);
  border: 1px solid rgba(144, 228, 255, 0.72);
}
#awardRe3 .body {
  background: url('https://cdn.igamie.com/static/award_reg_bg3.webp') center no-repeat;
  background-size: contain;
}

.end-in .ant-statistic-content-value {
   font-size: 13px;
   color: #C14427;
}

.start-in .ant-statistic-content-value {
   font-size: 13px;
   color: #A77D03;
}

.referral-success-modal .ant-modal-content {
  background: unset;
}
.referral-success-modal .ant-modal-body {
  padding: 0;
}

.referral-success-modal .referral-su-img   {
  width: 100%;
}


.rate-icon-wrapper .ant-rate-star {
  font-size: 16px;
  color: #f0774a;
  margin-right: 10px;
}

.home-rate-icon-wrapper .ant-rate-star {
  font-size: 12px !important;
  color: #f0774a;
  margin-right: 3px;
}

input {
  background-color: rgba(255, 255, 255, 0) !important;
  color: #fff!important;
}

.walletconnect-search__input {
  color: #000!important;
}

/* textarea {
  background-color: rgba(255, 255, 255, 0) !important;
  color: #fff!important;
} */

.discover-banner {

}

/* .discover-post-banner .slick-dots li {
  width: 8px!important;
  height: 8px!important;
  display: inline-block;
  border-radius: 50%;
}

.discover-post-banner .slick-dots .slick-active {
  width: 8px!important;
  height: 8px!important;
  border-radius: 50%;
} */

.ant-modal-wrap {
  z-index: 9999;
}

.login-modal .email-form textarea {
  height: auto!important;
}

.complete-profile .login-password-conteiner .email-form {
  display: block;
}

.ant-input:focus, .ant-input-affix-wrapper-focused .ant-input {
  box-sizing: none!important;
  box-shadow: none!important;
}
.ant-input:focus, .ant-input-focused {
  border-color: #d9d9d9;
}

.ant-input:hover{
  border-color: #d9d9d9;
}

.login-page  input:-webkit-autofill {
  -webkit-text-fill-color: #fff!important;
  /* -webkit-box-shadow: 0 0 0px 1000px #003049 inset!important; */
  transition: background-color 5000s ease-in-out 0s;
}

/* input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff !important;
} */

/* input:-webkit-autofill {-webkit-box-shadow: inset 0 0 0 1000px;} */

.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper, .ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: unset;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"]{
  -moz-appearance: textfield;
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    -webkit-text-fill-color: #aaa !important;
    -webkit-box-shadow: 0 0 0px 0px inset !important;
    background-color: transparent;
    background-image: none;
    transition: background-color 50000s ease-in-out 0s;
    color: #fff;
}

/* input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: -internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
  color: -internal-light-dark(black, white) !important;
} */

.bellfilled {
  font-size: 21px;
  margin-top: -3px;
}

.notice-icon {
  width: 32px;
  height: 32px;
  display: inline-block;
  background: url('../src/imgs/header/notice_icon.png') center no-repeat;
  background-size: contain;
  cursor: pointer;
}

.ant-form-item-explain.ant-form-item-explain-error {
  line-height: 1.3;
}

.ant-scroll-number-only-unit {
  transform: scale(.93);
  display: block;
}
.ant-badge-multiple-words {
  padding: 0 3px;
}

.grecaptcha-badge {
  display: none;
}
#serverID .ant-select-selector {
  height: 46px;
}
#serverID .ant-select-selection-placeholder {
  line-height: 46px;
}
#serverID .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #d9d9d9;
}
#serverID .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  box-shadow: none;
  border-color: #d9d9d9;
}


.loading-gray {
  height: 100%;
  width: 100%;
  background: #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading-gray-none {
  height: 0;
  opacity: 0;
}
.loaded-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.loaded-img-none {
  height: 0;
  opacity: 0;

}


.card-body {
  height: 100%;
}

.card-body-image {
  display: inline-block;
  width: 100%;
  height: 100%;
  /*padding: 6px 4px 0 4px;*/
  padding: 0;
  position: relative;
}



.home-banner .ant-carousel .slick-dots-bottom {
  bottom: 0;
  margin-bottom: .12rem;
}
.lucky-box-carousel .slick-dots-bottom {
  bottom: -55px;
}

.gamiezone-avatar {
  width: 32px;
  height: 32px;
  background: url('../src/imgs/header.svg') center no-repeat;
  background-size: cover;
  display: inline-block;
}

.ant-message {
  top: 50%;
  z-index: 199999;
}

.ant-message-notice-content {
   background-color: #1B2429;
   border-color: #3A4347;
   color: #fff;
}

.ant-message-notice-content .anticon {
  font-size: 19px;
}
.ant-message-notice-content span {
  font-size: 19px;
}

.ant-select-focused .ant-select-selector {
  box-shadow: none!important;
}

.whitdraw-select {
  color: #fff;
}
.whitdraw-select .ant-select {
  color:#fff;
}
.whitdraw-select .ant-select-selector {
  background:unset!important;
  height:49px!important;
}
.whitdraw-select .ant-select-selector input {
  /* height:49px!important;
  line-height: 49px!important; */
}

.whitdraw-select .ant-select-selector .ant-select-selection-item {
  height:49px;
  line-height: 49px;
}
.login-password-conteiner .whitdraw-select:hover .ant-select-selector {
  box-shadow:unset;
  border-color:#fff;
}
.login-password-conteiner .ant-select-focused .ant-select-selector {
  border:1px solid #fff!important;
  box-shadow:unset;
  color:#fff;
}

.login-password-conteiner .ant-form-item-control-input-content {
  position: relative;
}

html,body {
  margin: 0;
  padding: 0;
  /*font-family: 'Robot'!important;*/
  /*font-size: 1rem; */
  line-height: 1.5;
}

.center-place {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);

}

.hide-important {
  opacity: 0;
}

.reuqire-field {
  font-size: 12px;
  color: red;
  padding-left: 2px;
  margin-top: 5px;
}

.grecaptcha-badge {
  display: none!important;
}

.back-icon-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #000;
  z-index: 9999;
  display: none;
}

.back-icon {
  width: .5rem;
  height: .4rem;
  /* background: url('../src/imgs/left.svg') center no-repeat; */
  background-size: contain;
  display: block;
}

.my-checkbox {
  cursor: pointer;
  color: #c0c0c0;
}
.my-checkbox .label {
  margin-left: 2px;
  font-size: 13px;
}

.new-style-select {
  /*Chrome同Firefox与IE里面的右侧三角显示的样式不同*/
  /* border: solid 1px #ddd; */
  /*将默认的select选择框样式清除*/
  appearance:none;
  border: none;
  background: transparent;
  -moz-appearance:none;
  -webkit-appearance:none;
  padding-right: 14px;
  color: #fff;
  font-weight: 600;
  font-size: .3rem;

  /*如果要加入自定义图片， 就增加这个属性 background: url或者在html中直接加入图标也行*/
}
.new-style-select::-ms-expand {
  display: none;
}

.checkbox-new {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url('../src/imgs/character/checkbox-checked.svg') center no-repeat;
  background-size: contain;
  margin-right: 6px;
  cursor: pointer;
  margin-top: 1px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.checkbox-no-new {
  width: 16px;
  height: 16px;
  display: inline-block;
  background: url('../src/imgs/checkbox_no.svg') center no-repeat;
  background-size: contain;
  margin-right: 6px;
  cursor: pointer;
  margin-top: 1px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.pointer {
  cursor: pointer;
}
.how-layer7 {
  z-index: auto;
  display: flex;
  flex-direction: column;
  transition: all .2s,opacity .4s;
  overflow: hidden;
}
.how-layer7sh {
   height: auto;
   opacity: 1;
   margin-top: 20px;
}
.how-layer7hi {
   height: 0;
   opacity: 0;
}
.arrow-up {
  transform: rotate(0deg);
  transition: all .2s;
}
.arrow-down {
  transform: rotate(180deg);
  transition: all .2s;
}

.animation-trans {
  transition: all .5s ease;
}
.animation-trans .LazyImage_inView {
  height: 100%;
  justify-content: flex-start!important;
}
.LazyImage_inView  {
  width: 100%;
  height: 100%;
}
.LazyImage_inView .campaign-img {
  width: 100%;
  cursor: pointer;
}
.black-modal .ant-modal-content {
   background: #0F1518;
  border-radius: 27px;
}


.delete_modal_wrap{
  color: #FFFFFF;
  font-size: 18px;
  padding: 30px;
}

.delete_modal_button_flex_wrap{
  display: flex;
  justify-content: end;
  margin-top: 30px;
}

.cancel_btn {
  width: 110px;
  height: 35px;
  border-radius: 5px;
  color: #F488B9;
  border: 1px solid #F488B9;
  text-align: center;
  font-size: 18px;
  line-height: 35px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  cursor: pointer;
  user-select: none;
}
.cancel_btn:hover{
  color: #F488B9;
  border: 1px solid #F488B9;
}
.send_btn {
  width: 110px;
  height: 35px;
  border-radius: 5px;
  color: #590B57;
  border: unset;
  line-height: 35px;
  background-color: #F488B9;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.send_btn:hover{
  background-color: #F488B9;
  color: #FFFFFF;
}

.nft-modal .ant-modal-content {
  background: rgba(1, 17, 24, 0);
}

.modal_close_icon {
  background: url("../src/imgs/igm_modal/close_icon.webp") center no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.black-modal .ant-modal-footer {
  border: 1px solid #000;
}
.black-modal .ant-modal-footer .ant-btn-primary{
  border: 1px solid #BC9529;
  background-image: linear-gradient(270deg,
    rgba(147, 116, 30, 1) 0%,
    rgba(197, 157, 42, 1) 50%,
    rgba(147, 116, 30, 1) 100%);
  box-shadow: 0px 12px 13px -5px rgba(1, 17, 24, 0.36);

}
.black-modal  .preview-header {
  width: 300px;
}
.special-img {
   max-width: 100%;
   max-height: 100%;
   border-radius: 8px;
}

.black-modal-airdrop .winner-modal {
  border: 1px solid rgb(19, 64, 70);

}
.black-modal-airdrop .winner-modal .modal-title-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgb(1, 17, 24);
  padding: 10px 15px;
}
.black-modal-airdrop .winner-modal .title {
  color: #fff;
  font-size: 20px;
}
.black-modal-airdrop .winner-modal .close-btn {
  background: url('../src/imgs/airdrop_close.webp') center no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.black-modal-airdrop .ant-modal-content{
  background: unset;
}
.black-modal-airdrop .winner-modal .modal-body {
   background: rgba(5,25,35);
   padding: 20px;
}
.black-modal-airdrop .winner-modal .air-drop-winner-line {
  color: #fff;
  font-size: 15px;
  text-align: center;
  margin-bottom: 10px;
}

.minigame-share-button {
  top: 50px!important;
  right: 40px!important;
}



a:hover{
  color: #fff;
}
.unhover:hover {
  color: unset;
}

.normal-link:hover {
  color: #1890ff;
}

.ml-5 {
  margin-left: 5px;
}
.mr-5 {
  margin-right: 5px;
}
.mr-10 {
  margin-right: 10px;
}
.mt-10 {
  margin-top: 10px!important;
}
.mt-20 {
  margin-top: 20px!important;
}
.mt-22 {
  margin-top: 22px;
}
.mt-15 {
  margin-top: 15px!important;
}
.mt-5 {
  margin-top: 5px;
}
.mb-4 {
  margin-bottom: 4px;
}
.ml-10 {
  margin-left: 10px;
}
.ml-9 {
  margin-left: 9px;
}
.mb-10 {
  margin-bottom: 10px!important;
}
.mb--20 {
  margin-bottom: -10px!important;
}
.mt-30 {
  margin-top: 30px!important;
}
.mr-20 {
  margin-right: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.mt-50 {
  margin-top: 50px!important;
}
.mb-0 {
  margin-bottom: 0!important;
}
.mt-0 {
  margin-top: 0!important;
}
.mt-26 {
  margin-top: 26px;
}
.mt-19 {
  margin-top: 19px;
}
.mt-27 {
  margin-top: 27px;
}
.mt-22 {
  margin-top: 22px;
}
.white-color {
  color: #011118!important;
}
.height166 {
  height: 166px;
}
.bold-title {
  font-weight: 600;
}
.order-title-l {
  font-size: 20px;
  margin-bottom: 7px;
}
.padd-4 {
  padding: 0 4px 0 4px;
}
.flag-icon {
  width: 15px;
}
.flex-row {
  display: flex;
}
.flex-row-center {
  display: flex;
  align-items: center;
}
.link-style {
  color: #639FCD;
  font-size: 13px;
}
.goto-prev-page{
  display: none;
}

.google-login-icon {
   width: 25px;
   margin-right: 8px;
}

.fb-login-icon {
  width: 25px;
  margin-right: 8px;
}

.hide-style {
  display: none!important;
}

.pay-pending-style {
  color: rgba(242, 110, 76, 1);
}

.pay-success-style {
  color: rgba(66, 168, 162, 1)!important;
}

.pay-fail-style {
  color: #7C878D!important;
}

.img-style {
  max-width: 100%;
  max-height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.shake {
  animation: shakeAnim .1s 4 alternate;
}



.chat_buy_modal{
  display: flex;
  align-items: center;
  background: #0B0C0E;
  padding: 20px;
  border-radius: 16px;
  height: 300px;
  width: 550px;
  position: relative;
}

.chat_buy_modal .left_img{
  flex: 2;
  padding: 10px;
}

.chat_buy_modal .left_img  img{
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-radius: 10px;
}

.chat_buy_modal .right_wrap{
  flex: 3;
  color: #FFFFFF;
  font-size: 18px;
  font-weight: bold;
  padding: 10px;
  margin-left: 20px;
}

.chat_buy_modal .right_wrap  .release_btn{
  width: 100%;
  height: 50px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 18px;
  background: linear-gradient(-60deg, #F488B9, #fb9ec8, #F488B9);
  font-weight: 600;
  color: #590B57;
  display: flex;
  justify-content: center;
  align-items: center;
  border: unset;
  box-shadow: 0 0 5px #e969d8;
  transition: .3s all;
  margin-top: 20px;
}

.close_btn{
  position: absolute;
  top: 7px;
  right: 15px;
  color: #FFFFFF;
  font-size: 24px;
  cursor: pointer;
}

.right_wrap  .release_btn:hover{
  scale: 1.1;
}

.ruby_icon {
  background: url("../src/imgs/generate/ruby_icon.png") center no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
  margin-left: 10px;
}

.ruby_mini_icon {
  background: url("../src/imgs/generate/ruby_icon.png") center no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
}


@keyframes shakeAnim {
  from {
    transform: rotate(50deg);
  }

  to {
    transform: rotate(-50deg);
  }
}

.scaleAndMove {
  animation: scaleAndMoveAnim .2s 2 alternate;
}

@keyframes scaleAndMoveAnim {
  from {
    transform: scale(1);
    left: 2rem;
    bottom: 2rem;
  }

  to {
    transform: scale(0);
    left: 1.2rem;
    bottom: 1.2rem;
  }
}




@keyframes boxanimation {
  from {
    /* width: .3rem; */
    /* height: .6rem; */
    /* font-size: 0; */
    opacity: 0;
    transform: scale(.1);
  }
  to {
     /* width: 4.5rem;
     height: 2.87rem;
    font-size: .4rem; */
    opacity: 1;
    transform: scale(1.6);
  }
}

.box-animation {
  animation: boxanimation .6s ease-in-out;
  animation-fill-mode:forwards;
  /* width: 4.5rem;
  height: 2.87rem; */
}

.box-animation1s {
  animation: boxanimation .5s ease-in-out .3s;
  animation-fill-mode:forwards;
  /* width: 4.5rem;
  height: 2.87rem; */
}

@keyframes invitegiftanimation {
  from {
    opacity: 0;
    transform: scale(.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.invite-gift-animationn {
  animation: invitegiftanimation .6s ease-in-out;
  animation-fill-mode:forwards;
}


@keyframes likes {
  0% {
    transform: scale(1.2);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.2);
  }
}
.anima {
  animation-name: likes;
  animation-direction: alternate;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-duration: 1s;
}


.op0 {
  position: absolute;
  opacity: 0;
  width: 1px;
  height: 1px;
}

@keyframes textBiger {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.text-biger {
  animation: textBiger 1s ease-in-out;
  animation-fill-mode:forwards;
}

@keyframes textBiger1 {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.text-biger1 {
  animation: textBiger1 1s ease-in-out;
  animation-fill-mode:forwards;
}

/* @keyframes percentAnim {
  from {
    margin-left: -10rem;
    opacity: 0;
  }
  to {
    margin-left: unset;
    opacity: 1;
  }
}
.percent-anim {
  animation: percentAnim 1s ease-in-out;
  animation-fill-mode:forwards;
} */

@keyframes percentAnimRight {
  from {
    margin-left: -12rem;
    opacity: 0;
  }
  to {
    /* margin-left: unset; */
    opacity: 1;
  }
}
.percent-anim {
  animation: percentAnimRight 1s ease-in-out;
  animation-fill-mode:forwards;
}


@keyframes coinFont {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.coin-animation {
  animation: boxanimation 1.5s ease-in-out;
  animation-fill-mode:forwards;
}

@keyframes opacity1 {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
    background-color: unset;
  }
}
.opa1 {
  animation: opacity1 .5s ease-in-out;
}

@keyframes selectIcon {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes animation-modal {
  0% {
      transform: scale(0.95);
  }
  66% {
      transform: scale(1.05);
  }
  100% {
      transform: scale(1);
      opacity: 1;
  }

}

.notice-bar {
  position: absolute;
  bottom: .3rem;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  height: .55rem;
  overflow: hidden;
  font-family: 'Gats';
}
.lucky-notice-bar {
  height: 0.4rem;
  bottom: 0.45rem;
  width: 3.5rem;
}

.notice-bar .slick-arrow {
  display: none!important;
}
.notice-bar .slick-list {
  height: .55rem!important;
}
.notice-bar .item {
  height: .55rem;
  font-size: .26rem;
  padding-top: .08rem;
  white-space: nowrap;
}
.lucky-notice-bar .item {
   font-size: .3rem;
}


.notice-bar-x {
  color: #fff;
  margin: 20px 0 20px 30px;
  background: url('../src/imgs/notice_bar_pc.png') center no-repeat;
  background-size: 100% 100%;
  height: 75px;
  font-size: 18px;
  font-weight: 700;
  padding-left: 80px;
  padding-right: 40px;
  position: relative;
  width: calc(100vw - 245px);


}
.notice-bar-x-collapsed {
  width: calc(100vw - 417px);
  /* max-width: 1350px; */
}

@media only screen and (min-width: 1900px) {
  .notice-bar-x {
    width: calc(100vw - 256px);
    max-width: 1550px;
  }
  .notice-bar-x-collapsed {
    width: calc(100vw - 417px);
    max-width: 1350px;
  }
}

.notice-bar-x::after {
  content: '';
  width: 80px;
  height: 80px;
  background: url('../src/imgs/notice_bar_music.png') center no-repeat;
  background-size: contain;
  position: absolute;
  left: -30px;
  top: 50%;
  transform: translateY(-50%);
}

.notice-bar-x .notice-bar-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}
.notice-bar-x .notice-bar-content {
  list-style-type: none;
  font-size: 16px;
  color: #fff;
  margin-left: -35px;
  height: 80px;
}
.notice-bar-x .notice-bar-wrap {
  display: flex;
}
.notice-bar-wrap-trans {
  /* transition: -webkit-transform 2000ms linear 0s;
  transition: transform 2000ms linear 0s; */
  transition:  all .6s;
}

.notice-bar-x .notice-bar-content li {
  line-height: 70px;
  margin-left: 50px;
  white-space: nowrap;
}




.notice-bar-x-mobile {
  display: none;
}

.free-notice-bar {
  font-family: 'Robot';
  font-weight: 600;
  height: .6rem;
  bottom: .4rem;

}
.free-notice-bar .item {
  font-size: .34rem;
}
.free-notice-bar .slick-slide {
  width: 6.5rem!important;
}

.pre-page {
  cursor: pointer;
  background: #4e4c2e;
  border: 1px solid rgba(212, 168, 43, 0.79);
  padding: 0px 30px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  color: #fff;
}

.next-page {
  cursor: pointer;
  background: #4e4c2e;
  border: 1px solid rgba(212, 168, 43, 0.79);
  padding: 0px 30px;
  text-align: center;
  height: 30px;
  line-height: 30px;
  color: #fff;
}

.display-none {
  display: none;
}

.select-icon-animation {
  animation: selectIcon .2s ease-in;
}

.skeleton-column {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.skeleton-column .skeleton-column-226 {
  width: 226px;
  height: 300px;
}
.skeleton-column .skeleton-column-180 {
  width: 180px;
  height: 240px;
}
.skeleton-column .skeleton-column-480 {
  width: 580px;
  height: 240px;
}
.skeleton-column .ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle{
  width: 100%;
  border-radius: 0;
  height: 300px;
}
.big-z-sk {
  margin-bottom: 20px;
  opacity: .1;
}
.big-z-sk .ant-skeleton-avatar {
  width: 259px;
  height: 189px;
  border-radius: unset!important;
}

.normal-z-sk .ant-skeleton-avatar {
  width: 140px;
  height: 110px;
  border-radius: unset !important;
}

.big-z-sk-m {
  margin-bottom: 10px;
  opacity: .1;
}
.big-z-sk-m .ant-skeleton-avatar {
  width: 2.59rem;
  height: 1.89rem;
  border-radius: unset!important;
}

.order-notify {
  position: relative;
}
.order-notify .anticon-close {
  position: absolute;
  right: 0;
}
.order-notify .order-no-wra{
  padding-top: 20px;
}
.order-notify .notify-item {
  padding: 4px 2px;
  /* margin: 0 -20px; */
  /* margin-bottom: 10px; */
}
.order-no-wra .divi-line {
  border-top: 1px solid #4C585E;
  margin: 4px auto;
}
.ant-popover{
}
.ant-popover-arrow{
  /* display: none; */
}
.notify-item .content {
  display: block;
  font-size: 14px;
  color: #fff;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  cursor: pointer;
}
.notify-item .noti-num {
  color: #f08b71;
}
.notify-item .content:hover {
  text-decoration: underline;

  /* color: #F26E4C; */
}






.language-select {

}
.language-select .nav-header-d {
  opacity: 1;
  height: auto;
  visibility: visible;
  -webkit-transition: all .1s ease-in-out;
  -o-transition: all .1s ease-in-out;
  transition: all .1s ease-in-out;
  background: #2a2a2a;
}



/**
 *  language seelct
 **/
.select-container, .select-container-click {
  display: inline-block;
  padding: 10px 18px;
  position: relative;
  cursor: pointer;
}
.select-container-click .order-noti-tri {
  position: absolute;
  bottom:  1px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}
.select-container-click .nav-order-item {
  font-size: 18px;
}

.select-container .ant-badge, .select-container-click .ant-badge {
  color: unset;
}
.select-container-click .ant-badge-count {
  box-shadow: 0 0 0 2px #AD1F20;
  background: #AD1F20;
}
.ant-badge-count {
  box-shadow: 0 0 0 2px #AD1F20;
  background: #AD1F20;
  font-size: 12px;
}

.user-avatar-wrapper {
  padding: 10px 10px;
}

.user-avatar-wrapper .ant-avatar {
  border: 2px solid rgba(255,255,255,.2);
}

.user-avatar-wrapper .login-menu {
  padding: 4px 0px 4px 0px;
  left: 10px!important;
  transform: unset!important;
  top: 55px!important;
  /* width: 195px; */
  /* overflow: unset!important; */
}

.user-avatar-wrapper .menu-relative {
  position: relative;
}

.menu-relative .profile-menu-arrow {
  background: url('../src/imgs/sanjiao.svg') center no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
  display: inline-block;
  position: absolute;
  top: -18px;
  left: 35px;
  z-index: 9999;
}


.user-avatar-wrapper .login-menu .language-select-item {
  justify-content: unset;
  padding-left: 20px;
  padding-right: 20px;
  font-size: 13px;
}
.user-avatar-wrapper .login-menu .language-select-item  span {
  font-size: 12px;
}


.header-coins {
  background: url('https://cdn.igamie.com/static/coins_logo.gif?x-oss-process=image/format,webp/resize,m_lfit,w_20,h_20/quality,q_10') center no-repeat;
  background-size: contain;
  width: 48px;
  height: 48px;
  display: inline-block;
  /* margin-left: 28px; */
  /* margin-right: 17px; */
  cursor: pointer;
  position: relative;
  z-index: 199;
}
.header-coins-logout {
   margin-left: 28px;
   margin-right: 3px;
}
.header-coins-login {
  margin-right: 17px;
  margin-left: 16px;
}

.notice-menu{
  width: 320px;
  padding: 15px;
}

.notice-menu .notice-menu-title{
  width: 100%;
  line-height: 30px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  font-weight: 600;
}

.notice-menu .notice-item{
  border-radius:5px;
  padding: 7px 5px 7px 12px;
  margin: 10px 0px;
  background: #36454d44;
  cursor: pointer;
  font-weight: 600;
}

/* .notice-menu .notice-item:hover{
  background: #36454d;
} */

.notice-menu .notice-item .notice-item-title{
  font-size: 15px;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
}
.notice-menu .notice-item .notice-item-title .notice-item-tips{
  width: 7px;
  height: 7px;
  background: #E02020;
  border-radius: 3.5px;
  margin-top: 7px;
  margin-right: 10px;
}
.notice-menu .notice-item .notice-item-date{
  font-size: 12px;
  color: rgba(255,255,255,.6);
}

.notice-menu .notice-view{
  text-align: right;
  color: #F488B9;
  text-decoration: underline;
  font-weight: 600;
}
.notice-menu .notice-view-btn {
  cursor: pointer;
}



.select-container-regions {
  padding-right: 0;
  position: relative;
  padding: 15px 30px 15px 12px;
}



.select-container span,  .select-container-click span{
  /* font-size: 18px; */
  font-weight: 600;
}
.select-container .language-select-item span,  .select-container-click .language-select-item span{
  font-size: 13px;
}
.select-container img,  .select-container-click img{
  width: 14px;
  margin-left: 4px;
}
.select-container  .ant-avatar img{
  width: 100%;
  margin-left: 0px;
}

.select-container .language-icon, .select-container-click .language-icon  {
  vertical-align: middle;
  margin-right: 10px;
}
.select-container .user-icon, .select-container-click .user-icon {
  font-size: 26px;

}

.select-container .global-language {
  background: url('../src/imgs/globalLang.svg') center no-repeat;
  background-size: contain;
  width: 18px;
  height: 18px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 10px;
}

.select-container .user-icon-new {
  background: url('../src/imgs/head.svg') center no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
}

.select-container .hi-nickname, .select-container-click .hi-nickname {
  vertical-align: middle;
  margin-left: 9px;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}


.select-container .selectTri, .select-container-click .selectTri {
  /* transform: translateY(-44%); */
  margin-left: 12px;
  vertical-align: revert;
  width: 10px;
}
.select-container:hover .selectTri, .select-container-click .selectTri-s {
  transform: rotate(180deg);
  transition: all .2s;
}
.select-container .selectTri-s, .select-container-click .selectTri-s  {
  transform: rotate(180deg);
  transition: all .2s;
}
.select-container .selectTri-h, .select-container-click .selectTri-h {
  transform: rotate(0deg);
  transition: all .2s;
}
.select-container .language-menu,  .select-container-click .language-menu{
  /* width: 137px; */
  /* min-width: 180px; */
  /* background-color: #fff; */
  background-color: #25363F;
  position: absolute;
  /* color: #333; */
  color: #ccc;
  top: 50px;
  /* right: 16px; */
  z-index: 9999;
  box-shadow: 0 12px 60px rgb(14 32 66 / 15%),
    0 1px 2px rgb(14 32 66 / 5%);
  /* padding: 5px 10px 5px 10px; */
  opacity: 0;
  transition: all .5s;
  height: 0px;
  overflow: hidden;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
}
.select-container:hover .language-menu {
  opacity: 1;
  /* margin-top: 10px; */
  top: 47px;
  height: unset;
}
.select-container:hover {
  color: #fff;
}
.select-container-active {
  color: #fff;
}
.select-container-active .language-menu {
  opacity: 1;
  /* margin-top: 10px; */
  top: 38px;
  height: unset;
}


.select-container-regions .language-menu {
  position: absolute;
  right: 0;
  transform: unset;
  -webkit-transform: unset;
  left: unset;
  width: 135px;
  padding: 4px 0 4px 0;
  top: 54px!important;
}

.select-container-click .order-notify-menu {
  width: unset;
  min-width: 160px;
  background: rgba(1,17,24,.6);
  border: 1px solid #4D616D;
  transition: unset;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
  right: unset;
  z-index: 999;
}
.order-notify-menu .order-no-wra {
  padding: 5px 10px 20px 10px;
}
.order-no-wra .order-no-close {
  cursor: pointer;
  text-align: right;
  z-index: 9999999;
  /* position: absolute; */

}


.select-container select {
  border: none;
  background-color: rgb(234, 232, 232);
  position: auto;
  height: 100%;
  padding: 8px 35px;
  appearance: none;
  -moz-appearance: none;
  text-align: center;
  -webkit-appearance: none;
  transform: scale(.93);
}
.select-container .language-menu-show {
  opacity: 1;
  height: unset;
}
/* .select-container .language-menu-hidden {
  height: 0px;
  opacity: 0;
} */
.select-container .language-menu-hidden {
  height: 0px!important;
  opacity: 0px!important;
}
.language-menu .language-select-item {
  color: #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px 0 18px;
  line-height: 34px;
  position: relative;
}
.select-container .regions-menu {
  /* overflow: unset; */
}
.regions-menu .language-select-item {
  justify-content: unset;
}
.regions-menu .region-flag {
  margin-right: 8px;
  margin-left: 11px;
}
.regions-menu .menu-relative {
  position: relative;
}
.menu-relative .region-menu-arrow {
  left: 25px;
}

.select-container .language-menu .language-select-item:hover {
  /* color: #f79c37; */
  /* background-color: #fbf2ee; */
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.gray-line {
  margin: 0 10px;
  height: 19px;
  display: inline-block;
  border-right: 1.5px solid #ccc;
  vertical-align: middle;
}

/**
 * game table
**/
.game-table {
  /* padding: 0 15px 0 15px; */
  /* background: rgba(1, 17, 24, 0.24); */
  /* border-radius: 5px; */
  /* background-color: rgba(1, 17, 24, 0.24); */
}
.game-table .ant-table {
  background-color: rgba(1, 17, 24, 0.24);
  padding: 0 15px 30px 15px;
  border-radius: 5px;
}
.game-table .ant-table-thead>tr>th{
  background: rgba(0,0,0,0);
  color: rgba(255, 255, 255, 0.57);
}
.game-table .ant-table-thead>tr>th{
  border-bottom: none;
}
.game-table .ant-table-tbody>tr>td {
  border-bottom: none;
}
.game-table .ant-table-tbody {
  background: rgba(0,0,0,0);
}
.game-table .ant-table-row {
  background: rgba(255, 255, 255, 0.045);
  margin-bottom: 5px;
}
.game-table .ant-table-tbody>tr.ant-table-row:hover>td {
  background-color: rgba(255, 255, 255, 0.31);
}
.white-light-color {
  color: #fff;
}
.gray-item-color {
  color: #A7AFB3;
}
.white-gray-color {
  color: rgba(255, 255, 255, 0.4);
}

.item-font-size-16-bold{
  font-size: 15px;
  font-weight: bold;
  color: #FFFFFF;
}

.gold-light-color {
  color: rgba(179,143,41,1);
  cursor: pointer;
}
.gold-light-color:hover {
  color: rgba(179,143,41,1);
  text-decoration: underline;
}
.cancel-gray {
  color: #A7AFB3;
}
.complete-green {
  color: #42A8A2;
}
.pending-red {
  color: #F26E4C;
  position: relative;
}
.pending-red::after {
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #E02020;
  display: inline-block;
  position: absolute;
  top: -5px;
  right: -10px;

}
/**
 * 搜索框
 **/
.search-bar-wrapper {

}




.game-table .ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link{

}
.ant-form-item-has-error :not(.ant-input-disabled).ant-input, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper, .ant-form-item-has-error :not(.ant-input-disabled).ant-input:hover, .ant-form-item-has-error :not(.ant-input-affix-wrapper-disabled).ant-input-affix-wrapper:hover {
  background-color: unset;
  box-shadow: none;
}
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis{
  color: #fff;
}
.ant-pagination-total-text {
  color: #fff;
}
.ant-pagination-prev:focus .ant-pagination-item-link,
.ant-pagination-next:focus .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link{
  color: #fff;
  border-color: #fff;
}

.game-pagination {
  float: right;
  padding: 30px 10px 0 10px;
}
.game-pa-btn {
  border: 1px solid rgba(147, 116, 30, 1);
  background: linear-gradient(to right, rgba(147, 116, 30, 1) 0%, rgba(197, 157, 42, 1) 50%, rgba(147, 116, 30, 1) 100%);
  color: #fff;
}
.game-pa-btn:hover {
  border: 1px solid rgba(147, 116, 30, 1);
  background: linear-gradient(to right, rgba(147, 116, 30, 1) 0%, rgba(197, 157, 42, 1) 50%, rgba(147, 116, 30, 1) 100%);
  color: #fff;
}
.game-pa-btn-disable {
  color: rgba(255, 255, 255, 0.59);
  border: 1px solid rgba(147, 116, 30, 1);
  background: linear-gradient(to right, rgba(147, 116, 30, 1) 0%, rgba(197, 157, 42, 1) 50%, rgba(147, 116, 30, 1) 100%);
  opacity: .67;
}
.game-pa-btn-disable:hover {
  color: rgba(255, 255, 255, 0.59);
  border: 1px solid rgba(147, 116, 30, 1);
  background: linear-gradient(to right, rgba(147, 116, 30, 1) 0%, rgba(197, 157, 42, 1) 50%, rgba(147, 116, 30, 1) 100%);
  opacity: .67;
}

.game-table .ant-pagination-item-link {
    /* background: linear-gradient(to right, rgba(147, 116, 30, 1) 0%, rgba(197, 157, 42, 1) 50%, rgba(147, 116, 30, 1) 100%); */
    border: none;
    color: #fff;
    background-color: rgba(0,0,0,0);
}
.game-table .ant-pagination-item-link .anticon {
  font-size: 15px;
}

.game-table .ant-pagination-item {
  background: rgba(0,0,0,0);
  color: #fff;
  border: none;
}

.game-table .ant-pagination-item-active {
   background: #4e4c2e;
   color: #fff;
   border: 1px solid rgba(212, 168, 43, 0.79);
   border-radius: 100px;
}
.game-table-ranking {
  text-align: center;
}
.game-table-ranking .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #fff;
}
/* .game-table-ranking .ant-pagination-jump-prev-custom-icon {
  display: none;
}
.game-table-ranking .ant-pagination-jump-next-custom-icon {
  display: none;
} */
.game-table-ranking .ant-pagination-item-active {
  background: #0c1e25ed;
  border: none;
  font-size: .28rem;
  width: 0.4rem;
  height: 0.4rem;
  line-height: .4rem;
  min-width: unset;
}
.game-table-ranking .ant-pagination-item {
  font-size: .28rem;
  width: 0.4rem;
  height: 0.4rem;
  line-height: .4rem;
}
.game-table-ranking .ant-pagination-item a {
  color: rgba(255, 255, 255, 0.356)!important;
}
.game-table-ranking .ant-pagination-item-active a {
  color: #fff!important;
}
.game-table-ranking .ant-pagination-item-link .anticon {
  font-size: .26rem;
}
.game-table-ranking .ant-pagination-prev {
  vertical-align: text-top;
  margin-top: -0.02rem;
}
.game-table-ranking .ant-pagination-next {
  vertical-align: text-top;
  margin-top: -0.02rem;
}
.game-table-ranking .ant-pagination-disabled .ant-pagination-item-link {
  color: rgba(255, 255, 255, 0.356)!important;
}
.ranking-loading {

}
.ranking-loading .anticon-loading {
  font-size: .6rem!important;
  color: #566c68f0;
}

.game-table-discover {
  text-align: right;
  position: absolute;
  bottom: 0;
  right: 0;
  font-weight: 600;
}
.game-table-discover .ant-pagination-item-active {
   background: #0050503f;
   color: #fff;
   border: 2px solid #145f784d;
   border-radius: 100px;
   transition: unset;
}

.game-table .ant-pagination-item a {
  color: #fff;
}
.game-table .ant-empty-description{
  color: #fff;
}
.game-table .ant-spin-dot-item{
  background-color: #D4A82B;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #f5f5f5;
}

.login-menu {

}
.login-menu .user-avatar {
  width: 2rem;
}


.login-menu .ant-dropdown-arrow {
  border-top-color: #152A33;
  border-left-color: #152A33;
  background-color: #152A33;
  background: #152A33;
}

.login-menu .ant-dropdown-arrow:before {
  background-color: #152A33;
}
.post-list-sort .ant-dropdown-arrow:before {
  background-color: #152A33;
}
.login-menu .ant-dropdown-menu {
  background-color: #152A33;
  color: #fff;
  border-radius: 5px;
}
.post-list-sort .ant-dropdown-menu {
  background-color: #152A33;
  color: #fff;
  border-radius: 5px;
  border: 1px solid #22424F;
}
.post-list-sort .ant-dropdown-menu {
  background-color: #022735;
  color: #fff;
  border-radius: 5px;
}
.login-menu .ant-dropdown-menu-item {
  color: #fff;
  padding: 5px 15px 5px 15px;
  min-width: 170px;
}
.login-menu .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
  padding-bottom: 8px;
}
.post-list-sort .ant-dropdown-menu-item .ant-dropdown-menu-title-content {
  padding-bottom: 8px;
}
.login-menu .ant-dropdown-menu .ant-dropdown-menu-item:last-child .ant-dropdown-menu-title-content {
  padding-bottom: 0;
}
.login-menu .ant-dropdown-menu-title-content {
  border-bottom: 1px solid #32434B;
  padding-bottom: 4px;
}
.post-list-sort .ant-dropdown-menu-title-content {
  border-bottom: 0;
  padding-bottom: 4px;
}
.login-menu .ant-dropdown-menu .ant-dropdown-menu-item:nth-child(10) .ant-dropdown-menu-title-content {
  border-bottom: none;
}
.login-menu .flag-wrapper {
  display: flex;
  align-items: center;
}
.login-menu .region-flag {
  width: 18px;
  margin-right: 3px;
}
.login-menu .ant-dropdown-menu-item:hover, .login-menu .ant-dropdown-menu-submenu-title:hover {
  background-color: #152A33;
}
.post-list-sort .ant-dropdown-menu-item:hover, .login-menu .ant-dropdown-menu-submenu-title:hover {
  background-color: #103340;
}
.language-menu  .coin-new {
  width: 30px;
  height: 12px;
}

.coin-new-m {
  width: .6rem;
  height: .24rem;
  margin-left: .1rem;
}



/**
 * show add to screen
 **/
.show-add-to-screen {
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translate(-50%);
  background: #fff;
  z-index: 999;
  display: flex;
  padding: .2rem .28rem;
  border-radius: 70px;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 10%);
}
.show-add-to-screen .show-app-logo {
  width: .4rem;
  height: .4rem;
}
.show-add-to-screen .show-acreen-text{
  font-size: .28rem;
  margin-left: .1rem;
}
.add-to-screen-drawer {

}
.add-to-screen-drawer .ant-drawer-body{
   padding: 0;
}
.add-to-screen-drawer .ant-drawer-content-wrapper{
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-height: 470px;
  overflow: hidden;
}
.add-to-screen-drawer .drawer-header {
  padding: .2rem;
  border-bottom: 1px solid #1d21271f;
}
.add-to-screen-drawer .drawer-header-title{
   color: #1d2127;
   font-size: .3rem;
   font-weight: 600;
}
.add-to-screen-drawer .drawer-header-des {
  color: #868c92;
  font-size: .2rem;
}
.add-to-screen-drawer .drawer-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.add-to-screen-drawer .add-screen-img {
  width: 3rem;
  margin-top: .2rem;
}
.add-to-screen-drawer .add-line-1 {
  color: #1d2127;
  font-size: .26rem;
}
.add-to-screen-drawer .add-icon-1{
  width: .5rem;
}
.add-to-screen-drawer .add-line-2 {
  color: #1d2127;
  font-size: .28rem;
  margin-top: .1rem;
}
.add-to-screen-drawer .add-icon-2 {
  width: .5rem;
  margin: 0 5px;
}
.add-to-screen-drawer .add-text-2{
  font-weight: 600;
}
.add-to-screen-drawer .add-to-screen-mask {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  width: 250px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 0 auto;
  height: 44px;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(rgba(248, 248, 249, 0)));
  background: -webkit-gradient(linear, left top, left bottom, from(#fff), to(rgba(248, 248, 249, 0)));
  background: linear-gradient(180deg, #fff, rgba(248, 248, 249, 0));
  background: linear-gradient(180deg, #fff, rgba(248, 248, 249, 0));
}

.add-to-screen__button-show{
  width: 232px;
  height: 23px;
  padding: 6px 6px 6px 12px;
  border-bottom: .33px solid rgba(29, 33, 39, .12);
  border-bottom: .33px solid #1d21271f;
  background: #f5f7f8;
  background: #f5f7f8;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.caption-m8-w12{
  font-size: .2rem;
}
.add-to-screen__button-icon{
  width: .3rem;
}
.add-to-screen__button-click{
  width: 250px;
  height: 24px;
  padding: 6px 6px 6px 12px;
  background: #fff;
  background: #fff;
  border: 1.22936px solid #15c5ce;
  border: 1.22936px solid #f0774a;
  -webkit-box-shadow: 0 2.45872px 7.37615px rgb(0 0 0 / 18%);
  box-shadow: 0 2.45872px 7.37615px rgb(0 0 0 / 18%);
  border-radius: 6.28612px;
  margin: -1px auto 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.add-to-screen__reason{
  text-align: center;
  color: #868c92;
  font-size: .24rem;
  margin: .2rem 0 .2rem 0;
}
.add-to-screen__arrow-img{
  width: .4rem;
  -webkit-animation: downward .8s ease-in-out infinite;
  animation: downward .8s ease-in-out infinite;
  animation-fill-mode: forwards;

}
@-webkit-keyframes downward {
  0% {
    -webkit-transform: translate(-50%);
    transform: translate(-50%)
  }

  50% {
    -webkit-transform: translate(-50%, 10px);
    transform: translate(-50%, 10px)
  }

  to {
    -webkit-transform: translate(-50%);
    transform: translate(-50%)
  }
}

@keyframes downward {
  0% {
    -webkit-transform: translate(-50%);
    transform: translate(-50%)
  }

  50% {
    -webkit-transform: translate(-50%, 10px);
    transform: translate(-50%, 10px)
  }

  to {
    -webkit-transform: translate(-50%);
    transform: translate(-50%)
  }

}


/**
 * navbar
 **/
.nav-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1099;
  /* transition: all .8s ease; */
  background: #0000000a;
  display: flex;
  flex-direction: row-reverse;
  padding: 0 60px 0 0;
}
.nav-active-color {
  background: #000;
}
.nav-bar .nav-con,
.nav-con .nav-menu{
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
}
.nav-menu .nav-menu-item {
  font-size: 18px;
  color: #fff;
  /* margin-left: .5rem; */
  cursor: pointer;
  font-weight: 600;
  margin-left: 70px;
  white-space: nowrap;
}
.nav-menu .nav-menu-item-active {
  font-size: 18px;
  color: #d4a82b;
  /* margin-left: .7rem; */
  cursor: pointer;
}
.nav-menu .nav-menu-item:hover {
  /* color: #d4a82b; */
  transform: scale(1.05);
  transition: all .3s ease-out;
}
.nav-menu .main-logo{
  width: 90px;
  cursor: pointer;
  margin-top: -5px;
}
.nav-bar .nav-con {
  /*max-width: 1350px;*/
  width: 100%;
  height: 90px;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  align-items: center;
}
.imomo-logo-wrapper {
  display: flex;
  align-items: flex-start;
}
.imomo-logo-wrapper .imomo-logo {
  margin-left: 20px;
  background: url("../src/imgs/imomo_logo.png") center no-repeat;
  background-size: contain;
  width: 140px;
  height: 80px;
  cursor: pointer;
}
.imomo-logo-wrapper .bate {
  font-size: 12px;
  font-weight: 500;
  color: #F488B9;
  padding-top: 10px;
}

.imomo-footer-logo {
  background: url("../src/imgs/imomo_logo.png") center no-repeat;
  background-size: contain;
  width: 180px;
  height: 100px;
}

.nav-right-con {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  color: #ccc;
  white-space: nowrap;
}
.nav-right-con .search-container {
    display: flex;
    position: relative;
    /* max-width: 380px; */
    min-width: 480px;
    /* width: 100%; */
    height: 60px;
    align-items: center;
    justify-content: flex-end;
}
.nav-right-con .search-icon {
  /* font-size: 20px; */
  /* position: absolute; */
  /* right: 20px; */
  /* width: 17px; */
  cursor: pointer;
  font-size: 14px;
  width: 22px;
}
.home-search-container {
  width: 500px;
  height: 400px;
  border-radius: 3px;
  padding: 30px;
}
.home-search-container .search-input {
  background: rgba(255,255,255,.1);
  height: 40px;
  position: relative;
}
.home-search-container .search-hed {
  position: absolute;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 20px;
}
.home-search-container .search-input-icon {
  width: 18px;
}
.home-search-container .search-pa {
   width: 100%;
   position: relative;
   height: 100%;
}
.home-search-container .se-input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding-left: 50px;
}
.home-search-container .search-loading {
   text-align: center;
   padding: 80px 100px;
}

.menu-icon {
  background: #f0774a;
  position: absolute;
  right: 0;
  z-index: 9999999;
  cursor: pointer;
  display: block;
  width: .84rem;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-icon .menu-icon-w {
   position: relative;
   width: 24px;
   height: 20px;
}
.navbar-toggler .style-menu-line{
    background-color: #fff;
    height: 2px;
    width: 100%;
    border-radius: 2px;
    position: absolute;
    left: 0;
    -webkit-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}
.navbar-toggler[aria-expanded=false] .style-menu-line-2{
    top: 0;
    bottom: 0;
    margin: auto;
}
.navbar-toggler[aria-expanded=false] .style-menu-line-3{
  bottom: 0;
}
.navbar-toggler[aria-expanded=true] .style-menu-line-1 {
  /* -webkit-transform: translateY(9px) translateY(-50%) rotate(-45deg);
  transform: translateY(9px) translateY(-50%) rotate(-45deg); */
  /* width: 28px; */
}
.navbar-toggler[aria-expanded=true] .style-menu-line-2{
  opacity: 0;
}
.navbar-toggler[aria-expanded=true] .style-menu-line-3{
  /* -webkit-transform: translateY(-10px) translateY(50%) rotate(45deg);
  transform: translateY(-10px) translateY(50%) rotate(45deg);
  bottom: 0; */
  /* width: 28px; */
}


.menu-drawer {
  /* position: relative;  */
  z-index: 9990;
}
.menu-drawer .ant-drawer-content-wrapper{
  /* width: 90% !important; */
  /* position: relative; */
  /* width: 100%!important; */
  /* margin-left: 10%; */
}

.menu-drawer .ant-drawer-content {
  background: #011118;
}
.menu-drawer .ant-drawer-body {
  padding: 1rem 0 0 0;
}
.menu-drawer .m-menu-list {
   color: #fff;
   font-size: .3rem;
}
.m-menu-list .m-menu-item {
  padding: .2rem .4rem .13rem .4rem;
  border-bottom: 1px solid #454756;
}
.menu-drawer .close-icon{
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 20px;
  color: #fff;
}

.search-container .search-input-wrapper {
  display: block;
  width: 100%;
  position: relative;
  bottom: unset;
  top: unset;
  right: unset;
  left: unset;
  padding: 0 8px;
  opacity: 1;
  transition: all .2s ease-in-out;
}
.search-container .search-input-wrapper-hidden {
  opacity: 0;
  width: 0;
}

.search-list {
   transition: all .2s  ease .3s;
   position: absolute;
   bottom: -400;
   left: 0;
   right: 0;
   width: 464px;
   margin: 0 auto;
   opacity: 1;
   max-height: 300px;
   overflow: scroll;
   /* position:relative; */
}

.search-input-wrapper .animate-search {
  position: absolute;
  width: 120px;
  left: 50%;
  transform: translateX(-50%);
  top: 150px;
  z-index: 9999;
}

.search-list .no-results {
   color: #fff;
   margin-top: 20px;
   display: inline-block;
}


.home-search-container .search-list-close {
  position: absolute;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  font-size: 18px;
}

.search-input-wrapper-hidden .search-list {
  height: 0;
  background: rgba(1, 17, 24, .8);
  transition: all .2s  ease .3s;
  opacity: 0;
  display: none;

}


.search-input-h {
    display: block;
    width: 100%;
    outline: 0;
    line-height: 18px;
    color: #fff;
    background: rgba(1, 17, 24, 0.54);
    border-radius: 4px;
    padding: 10px 10px;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-animation: fadeIn-data-v-1a91c7a5 .8s ease;
    animation: fadeIn-data-v-1a91c7a5 .8s ease;
}
.nav-bar-mobile {
  display: none;
}


.search-drawer .ant-drawer-body {
   padding:  0;
}
.nav-mobile-search {
  padding: .8rem .4rem .4rem .4rem;
}
.nav-mobile-search .search-header-m {
  display: flex;
  align-items: center;
}
.search-header-m .search-icon {
   font-size: .34rem;
   color: #fff;
}
.search-header-m .search-input {
  background: rgba(0,0,0,0);
  border: none;
  color: #fff;
  font-size: .28rem;
}
.search-header-m .search-input:focus{
  box-shadow: none;
}

.nav-mobile-search .game-list {
  color: #fff;
  padding-top: .3rem;
  height: 3rem;
  overflow: scroll;
}
.game-list .hot-search {
   font-size: .28rem;
}
.hot-search .hot-text {
    font-size: .36rem;
}
.game-list .game-name {
  margin-top: .17rem;
  padding-left: .2rem;
  font-size: .28rem;
}

.nav-right-con .search-list {
  padding: 20px;
}
.hot-search-wrapper {
  font-size: 15px;
}
.hot-search-wrapper .hot-text {
  font-size: 18px;
}
.search-list .game-name {
  margin-top: 10px;
  padding-left: 4px;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  color: #fff;
}
.search-list .game-name:hover{
  background:rgba(255, 255, 255, 0.2);


}

/**
 * app footer
 **/
.app-footer {
  background: rgb(2,17,23);
  font-family: 'Gats'!important;

}
.app-footer .footer-wrapper-1 {
   background: rgb(2,26,35);
   padding: 18px 0;
   letter-spacing: 1px;

}
.footer-wrapper-1 .footer-bo {
  width: 1100px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 50px 0;
}

.footer-wrapper-1 .footer-bo-new {
  width: 1100px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}


.footer-bo-new .left {


}
.footer-bo-new .left .footer-logo-img {
  width: 120px;
  cursor: pointer;
}
.footer-bo-new .left .footer-logo-img img {
  max-width: 100%;
  max-height: 100%;
}
.footer-bo-new .right {

}
.footer-bo-new .right .footer-join {
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.footer-bo-new  .right-new {
  display: none;
}

.footer-bo-new .left .zendesk-link {
  display: flex;
}
.footer-bo-new .zendesk-link  {
  font-size: 20px;
}
.footer-bo-new .zendesk-link a {
  color: #fff;
  margin-right: 50px;
}
.footer-bo-new .footer-commu {
  display: flex;
}
.footer-bo-new .footer-commu img {
  width: 40px;
  margin-left: 20px;
}
.footer-wrapper-1 .footer-tc {
    width: 600px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: rgba(255, 255, 255, 0.73);
    padding: 20px;
}








.footer-wrapper-1 .footer-t-name {
  font-size: 15px;
  color: #fff;
  margin-bottom: 20px;
}
.footer-wrapper-1 .select-container {
  padding: 10px 0px;
  color: #fff;
}
.footer-wrapper-1 .select-container .language-menu{
  left: 0;
  right: unset;
}
.footer-wrapper-1 .pay-support-list {
  width: 200px;
}
.footer-wrapper-1 .pay-support-icon{
  width: 54px;
  margin-right: 10px;
  margin-top: 10px;
}

.app-footer .footer-wrapper-2 {
   width: 800px;
   margin: 0 auto;
   display: flex;
   align-items: center;
   justify-content: space-between;
   color: rgba(255, 255, 255, 0.73);
   padding: 20px;
}

.footer-wrapper-2 a,
.footer-wrapper-2 span {
  color: rgba(255, 255, 255, 0.73);
  text-align: center;
}

.footer-wrapper-1 .Follow-us{
   display: flex;
}
.Follow-us .contact-us {
  margin-right: 70px;
}
.contact-us .contact-us-wrapper {
  text-align: center;
}

.footer-wrapper-1 .linkedin-link {
   color: #fff;
   font-size: 20px;

}
.linkedin-link .linkin-logo-footer-1{
  width: 23px;
}

.footer-wrapper-1 .ins-link {
   color: #fff;
   font-size: 20px;
   margin-left: 20px;

}
.linkedin-link img {
  width: 22px;
}
.ins-link img {
  width: 22px;
}









/**
 *
 ant 主题颜色修改
 **/

 .ant-input-affix-wrapper-focused {
   border-color: #fff;
 }

 .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
   border-color: #fff;
 }

 .ant-btn-primary {
   background: #F488B9;
   border-color: #F488B9;
 }
 .ant-btn-primary:hover {
   background: #F488B9;
   border-color: #F488B9;
 }
 .ant-checkbox-checked .ant-checkbox-inner{
   background-color: #F488B9;
   border-color: #F488B9;
 }

 .gold-checkbox .ant-checkbox-checked .ant-checkbox-inner {
   background-color: #D4A82B;
   border-color: #D4A82B;
 }

 .ant-checkbox-wrapper:hover .ant-checkbox-inner,
 .ant-checkbox:hover .ant-checkbox-inner,
 .ant-checkbox-input:focus+.ant-checkbox-inner{
   border-color: #f0774a;
 }

 .gold-checkbox:hover .ant-checkbox-inner,
 .gold-checkbox .ant-checkbox:hover .ant-checkbox-inner,
 .gold-checkbox .ant-checkbox-input:focus+.ant-checkbox-inner {
    border-color: #D4A82B;
 }

 .ant-checkbox-checked::after{
   border-color: #f0774a;
 }
 .gold-checkbox .ant-checkbox-checked::after{
   border-color: #D4A82B;
 }

 .ant-btn-primary:hover,
 .ant-btn-primary:focus{
   background: #F488B9;
   border-color: #F488B9;
 }


 .login-page .ant-checkbox-checked .ant-checkbox-inner::after {
 }

 .login-page .ant-input:hover, .login-page .ant-input:focus {
   border-color: #095359!important;
 }
 .login-page .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
   border-color: #095359!important;
 }

 .ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus {
   border-color: unset;
 }
 .ant-tooltip {
   max-width: 700px;
 }
 .ant-tooltip-inner {
   background-color: rgba(0, 0, 0, 0.85);
 }

 .ant-upload.ant-upload-select {
   display: inline!important;
 }

 #launcher {
   right: 50px!important;
   bottom: 40px!important;
   margin-right: 0!important;
 }
 .ant-back-top {
  bottom: 120px;
  right: 50px;
}
.ant-back-top-content {
  width: 47.9px;
  height: 47.9px;
  border-radius: 50%;
  line-height:50px !important;
  padding-top: 5px;
  background-color: #17494D;
}
.ant-back-top-content:hover {
 background-color: #1f747a;
}


 .ant-input-password .ant-input-password-icon{
   color: #fff;
 }
 .ant-radio-inner::after{
   /* background: #D4A82B; */
   background: url('../src/imgs/check_icon.svg') center no-repeat;
   background-size: contain;
   width: 16px;
   height: 12px;
   left: 1px;
   top: 0px;
 }
 .ant-radio-wrapper:hover .ant-radio,
 .ant-radio:hover .ant-radio-inner,
 .ant-radio-input:focus+.ant-radio-inner{
   border-color: #D4A82B;
 }
 .ant-radio-wrapper{
   color: #fff;
 }

 .voucher-status-radio .ant-radio-button-wrapper{
   color: #D4A82B;
 }
 .voucher-status-radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
   background: #D4A82B;
   border-color: #D4A82B;
 }

 .voucher-status-radio .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{
   border-right-color:#D4A82B;
 }
 .voucher-status-radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
    background: #D4A82B;
    border-color: #D4A82B;
 }
  .voucher-status-radio .ant-radio-button-wrapper:hover{
    color: #D4A82B;
  }
  .voucher-status-radio .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
    background-color: #D4A82B;
  }

 .ant-form-item-explain.ant-form-item-explain-error{
   font-size: 13px;
   margin: 5px 0 5px 0;
 }

 .ant-table-tbody>tr.ant-table-placeholder:hover>td {
   background:unset;
 }

 .ant-message .anticon {
   vertical-align: text-bottom;
 }

 .ant-radio-checked .ant-radio-inner {

 }
 .voucher-status-radio .ant-radio-checked .ant-radio-inner {
   border-color: #D4A82B;
 }
 .ant-radio-wrapper-checked {
   color: #D4A82B;
 }

.ant-modal-mask {
  z-index: 9999;
}


 .ant-modal-mask,
 .ant-image-preview-mask{
   background-color: rgba(1,17,24,.65);
 }
 .order-notify-modal .ant-modal-content {
   background: rgba(1,17,24,.6);
 }
 .order-notify-modal .ant-modal-body {
   padding: 12px;
   text-align: center;
 }
 .order-notify-modal .ant-modal-close{
   color: #fff;
 }

 .ant-popconfirm {

 }
 .ant-popover-arrow .ant-popover-arrow-content{
   background: rgba(1,17,24,.8);
 }

 .ant-popover-inner  {
  background: rgba(1,17,24,.88);
 }


 .ant-popconfirm .ant-popover-inner {
   background: rgba(1,17,24,.6);
   border: 1px solid #4D616D;
 }
 .ant-popover-placement-top .ant-popover-arrow-content, .ant-popover-placement-topLeft .ant-popover-arrow-content, .ant-popover-placement-topRight .ant-popover-arrow-content {
  background: rgba(1,17,24,.6);
 }
 .ant-popover-inner-content .ant-popover-message-title{
  color: #fff;
 }
 .ant-popover-inner-content .ant-btn {
  background: #305E65;
  color: #819DA1;
  border: none;
 }
 .ant-popover-inner-content .ant-btn-primary {
  background: #42A8A2;
  color: #fff;
 }

 .cancel-order-confirm-modal {

 }
 .cancel-order-confirm-modal .ant-modal-content {
  background: rgba(1,17,24,.6);
  color: #fff;
  border: 1px solid #4D616D;
 }
 .cancel-order-confirm-modal .ant-modal-confirm-title {
   color: #fff;
 }
 .cancel-order-confirm-modal .ant-btn {
    background: #305E65;
    color: #819DA1;
    border: none;
 }
 .cancel-order-confirm-modal .ant-btn-primary {
    background: #42A8A2;
    color: #fff;
 }

 .ant-badge-dot {
   box-shadow: none;
 }


.blindbox-rule-modal .ant-modal-content {
  background: url('https://cdn.igamie.com/static/blindbox_rule_bg.jpg') center no-repeat;
  background-size: contain;
  height: 900px;
}
.blindbox-rule-modal .ant-modal-close  .anticon{
  color: #fff;
  font-size: 25px;
}

.blindbox-rule-modal .ant-modal-body {
  padding: 160px 40px 0 40px;

}
.blindbox-rule-modal .ant-modal-body .rule-wrapper {
  height: 600px;
  overflow-y: scroll;
}


.blindbox-rule-modal .ant-modal-body p, .blindbox-rule-modal .ant-modal-body ul {
  font-size: 20px;
  color: #fff;
}


.free-challenge-progress .ant-progress-inner{
  background: #c7b7fd;
}
.free-challenge-progress .ant-progress-bg {
  height: .3rem!important;
}

.nft-order-complete-modal {

}

.nft-order-complete-modal .ant-modal-body {
  padding: 24px 18px;
}

.nft-order-complete-modal .ant-modal-content {
  background: #022b3c;
  height: 400px;
}
.nft-order-complete-modal .ant-modal-body {
  height: 100%;
}

.nft-order-complete-modal {
  width: 5.24rem!important;
  zoom: .8;
  margin-top: 100px;
}

.nft-order-complete-modal .ant-modal-body {
 padding: .6rem .6rem;

}
.nft-order-complete-modal .nft-order-modal-close-btn  {
 width: .4rem;
 height: .4rem;
 background: url('https://static-img.igamie.com/static/nftStatic/nft-complete-modal-close.png') center no-repeat;
 background-size: contain;
 position: absolute;
 top: -.8rem;
 right: 0;
 cursor: pointer;
}
.nft-order-complete-modal .nft-order-c-icon {
 width: 1.7rem;
 height: 1.7rem;
 margin: 0 auto;
}
.nft-order-complete-modal .icon-img {
 max-width: 100%;
 max-height: 100%;
}

.nft-order-complete-modal .nft-order-m-title {
 font-size: .3rem;
 color: #fff;
 margin-top: .25rem;
 font-weight: 600;
 text-align: center;
}
.nft-order-complete-modal .nft-order-m-des  {
 font-size: .24rem;
 color: #A3ACAF;
 margin-top: .25rem;
 text-align: center;
}
.nft-order-complete-modal .nft-order-modal-btn {
 background: linear-gradient(90deg, #1D73D0 0%, #8600C6 100%);
 box-shadow: 0px 10px 14px -5px rgba(98,54,255,0.05);
 border-radius: .08rem;
 width: 4rem;
 height: .82rem;
 margin-top: .3rem;
 color: #fff;
 line-height: .82rem;
 font-weight: 600;
 text-align: center;
 font-size: .28rem;
 cursor: pointer;
}

.nft-order-modal-btn-confirm{
  width:1.75rem !important;
  box-shadow: 0px 10px 14px -5px rgba(98,54,255,0.05);
  border-radius: 8px;
  background: none !important;
}

.nft-order-modal-btn-confirm-cancel{
  box-shadow: 0px 10px 14px -5px rgba(98,54,255,0.05);
  border-radius: 8px;
  border: 1px solid #1C66A2;
  color: #FFFFFF !important;
}
.nft-order-modal-btn-confirm-jp{
  width:4.1rem !important;
  margin: 0 auto;
}




.nft-order-complete-modal .ant-modal-content {
 background: url('http://static-img.igamie.com/static/nftStatic/nft-order-modal-c-bg.png') center no-repeat;
 height: 6.11rem;
 background-size: contain;
}

.nft-order-complete-modal-confirm .ant-modal-content{
  height: 4.3rem;
  background: url('../src/imgs/order_confirm.png') center no-repeat;
  background-size: contain;
}
.nft-order-complete-modal-confirm-jp  .ant-modal-content{
  width: 5.5rem;
  height: 5.3rem;
  background: url('../src/imgs/order_confirm.png') center no-repeat;
  background-size: contain;
}

.nft-order-complete-modal .ant-modal-body {
 height: 100%;
}



.free-collect-modal .ant-modal-body {
   padding: 24px 18px;
}
.free-collect-modal  .ant-modal-content{
   background: url('http://static-img.igamie.com/free-collect-modal-bg.webp') center no-repeat;
   background-size: contain;
   height: 533px;
}
.free-collect-modal .place-1 {
  height: 100px;
  padding-top: 50px;
  font-size: 28px;
  color: #fff;
  text-align: center;
  font-weight: 700;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}
.free-collect-modal .place-2 {
  height: 280px;
  padding-top: 50px;
}

.free-collect-modal .free-modal-banner {
  width: 204px;
  height: 172px;
  margin: 0 auto;
}

.free-collect-modal .free-modal-banner-pic {
  max-width: 100%;
  max-height: 100%;
}
.free-collect-modal .free-modal-banner-name {
  color: #fff;
  font-size: 20px;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 700;
}

.free-collect-modal .place-3 {
  height: 180px;
  padding: 10px 0px 0;
}

.free-collect-modal .share-wrapper {
  font-size: 17px;
  color: #fff;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.free-collect-modal .share-checkbox {
    background: url('../src/imgs/free_check1.png') center no-repeat;
    background-size: contain;
    width: 13px;
    height: 13px;
    display: inline-block;
}

.free-collect-modal .no-share-checkbox {
    background: url('../src/imgs/free_check2.png') center no-repeat;
    background-size: contain;
    width: 13px;
    height: 13px;
    display: inline-block;
}

.free-collect-modal .share-txt {
  margin-left: 6px;
}

.free-collect-modal .collect-yes-btn {
  width: 322px;
  height: 53px;
  background: linear-gradient(180deg, #e20ffb 0%,  #6d1df9 100%);
  box-shadow: 0px 3px 1px 2px rgb(32 16 58 / 24%);
  border-radius: 100px;
  margin: 0 auto;
  color: #fff;
  font-size: 22px;
  font-weight: 700;
  text-align: center;
  line-height: 50px;
  margin-top: 10px;
}
.free-collect-modal .collect-no-btn {
  width: 290px;
  height: 53px;
  background: #d3dfff;
  box-shadow: 0px 3px 1px 2px rgb(32 16 58 / 24%);
  border-radius: 100px;
  margin: 0 auto;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  text-align: center;
  line-height: 53px;
  margin-top: 10px;
}

.free-collect-modal .free-has-reward {
  margin-top: 50px;
  text-align: center;

}
.free-collect-modal .free-has-reward .left {
   font-size: 17px;
   font-weight: 700;
   color: #fff;
}
.free-collect-modal .free-has-reward .right {
  font-size: 17px;
  font-weight: 700;
  color: #fc29bb;
  text-decoration: underline;
}

.free-help-modal .ant-modal-body  {
  padding: 0rem .18rem 0;
}
.free-help-modal .ant-modal-close {
  color: #fff;
  top: -.9rem;
  right: -.36rem;
}
.free-help-modal .ant-modal-close .ant-modal-close-x {
  font-size: .44rem;
}
.free-help-modal .ant-modal-content {
  background: url('https://static-img.igamie.com/static/nftStatic/free_help_modal_1.png') center no-repeat;
  background-size: contain;
  /* height: 533px; */
  width: 5.71rem;
  height: 7.52rem;
  margin:  0 auto;
  padding-top: 1rem;
  padding-top: 0;
}
.free-help-modal .ant-modal-content .help-title {
  text-align: center;
  position: relative;
  top: -.36rem;
}
.free-help-modal .red-title {
   color: rgb(242,0,171);
   font-size: .5rem;
   margin-left: .2rem;
   font-weight: 600;
}
.free-help-modal .white-title {
   color: #fff;
   font-size: .4rem;
   font-weight: 700;
   margin-left: .2rem;
   line-height: 1.3;
   margin-bottom: .1rem;
}
.free-help-modal .award-title {
  margin-top: .3rem;
  color: #fff;
  font-size: .5rem;
  text-align: center;
}
.free-help-modal .award-wrapper {
  width: 100%;
  height: 4.9rem;
  padding: .4rem .4rem 0 .4rem;
}
.free-help-modal .award-pic-wra {
   width: 3rem;
   height: 2.6rem;
   margin: 0 auto;
}
.free-help-modal .award-pic {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
}
.free-help-modal .award-name {
  font-size: .4rem;
  color: #fff;
  font-weight: 700;
  text-align: center;
  margin-top: .15rem;
}
.free-help-modal .modal-bottom-btn {
  background: linear-gradient(180deg, #c731ff 0%, #8641fe 100%);
  border-radius: 40px;
  width: 5rem;
  height: .9rem;
  line-height: .9rem;
  text-align: center;
  font-weight: 700;
  color: #fff;
  position: absolute;
  bottom: .35rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: .34rem;
  cursor: pointer;
}

.free-help-modal-complete .ant-modal-body  {
  padding: 0rem .18rem 0;
}
.free-help-modal-complete .ant-modal-content {
 background: url('https://static-img.igamie.com/static/nftStatic/free_help_modal_2.png') center no-repeat;
 background-size: contain;
 /* height: 533px; */
 width: 5.71rem;
 height: 7.47rem;
 margin:  0 auto;
 padding-top: 1rem;
 padding-top: 0;
}
.free-help-modal-complete .ant-modal-content .help-title {
 text-align: center;
 position: relative;
 top: 0.5rem;
}
.free-help-modal-complete .white-title {
 color: #fff;
 font-size: .4rem;
 font-weight: 600;
 margin-left: .2rem;
 line-height: 1.3;
}
.free-help-modal .award-pic-wra {
margin-top: .2rem;
}
.free-help-modal-complete .award-progress {
display: none;
}
.free-help-modal-complete .modal-bottom-btn {
background: unset;
font-size: .4rem;
bottom: .1rem;
}

.free-help-modal-complete .award-wrapper {
  width: 100%;
  height: 4.9rem;
  padding: 1.7rem .4rem 0 .4rem!important;
}


.free-time-left-wrap .ant-statistic .ant-statistic-content-value {
  color: #8A14CE;
  font-size:.24rem;
  vertical-align: middle;
  font-weight: 600;
  margin-left: 4px;
}



/**
 * login modal
**/

.login-modal {
  border-radius: 1px 1px 35px 1px;
  overflow: hidden;
}

.login-modal .ant-modal-content {
  border: 1px solid #34a088;
  background: rgba(255, 255, 255, 0);
  border-radius: 8px 8px 25px 1px;
  overflow: hidden;

}

.login-modal .ant-modal-close {
  margin-right: 8px;
}

.login-modal .ant-modal-close-icon {
  color: #fff;
  font-size: 20px;
}

.login-modal .avatar-upload_wrapper {
  display: flex;
  justify-content: space-between;

}
.avatar-upload_wrapper .avatar-label {
  color: rgba(255, 255, 255, 0.72);
  font-size: 14px;
}

.avatar-upload_wrapper .avatar-img {
  object-fit: cover;
  border-radius: 50%;
  width: 65px;
  height: 64px;
  background: #D8D8D8;
  border: 3px solid rgba(255, 255, 255, 0.93);
}


.avatar-upload_wrapper .upload-btn {
  /* width: 70px; */
  /* height: 28px; */
  /* line-height: 28px; */
  text-align: center;
  border-radius: 4px;
  border: 1px solid rgba(208, 166, 44, 0.62);
  color: rgba(208, 166, 44, 0.62);
  position: relative;
  background: rgba(0,0,0,0);
  cursor: pointer;
  font-weight: 600;
  cursor: pointer;
}
.upload-btn .txt {
  cursor: pointer;
}

.avatar-upload_wrapper .upload-input {
   opacity: 0;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   cursor: pointer;
}

.select-arrow {
  position: absolute;
  right: 10px;
  top: 50%;
  width: 20px;
  transform: translateY(-50%);
}


.login-modal .ant-modal-body {
  padding: 0;
  /* background: rgba(2, 39, 53, 0.6); */
  overflow: hidden;
  box-shadow: 0px 30px 24px 0px #011118;
}
.login-modal label {
  color: rgba(255, 255, 255, 0.72);
  font-size: 14px;
}
.login-modal .form-select {
    height: 49px;
    border-radius: 4px;
    width: 100%;
    background: rgba(0,0,0,0);
    border-radius: 6px;
    border: 2px solid rgba(255, 255, 255, 0.54);
    appearance:none;
  -moz-appearance:none;
  -webkit-appearance:none;
   color: #fff;
   font-weight: 600;
   padding-left: 10px;
}

.login-modal .select-con .ant-form-item-control-input-content:after {
  width: 20px;
  height: 12px;
  background: url('https://cdn.igamie.com/static/ap_arrow.png') center no-repeat;
  background-size: contain;
  content: '';
  position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
}


.login-modal .login-btn-form-item .ant-form-item-control-input-content:after {
  background: unset;
}

.login-modal .form-select::-ms-expand { display: none; }


.login-modal .veri-header {
    background: rgba(3, 28, 37, 0.81);
    padding: 15px 30px 0px 30px;
    font-size: 18px;
    color: #fff;
}

.login-modal .veri-ext {
  padding: 10px 30px 20px 30px;
  color: #fff;
}



.login-modal .veri-body {
    padding: 30px 30px 46px 30px;
    background: rgba(2, 39, 53, 0.6);
}

.remark-modal .veri-body {
  padding: 0px 20px 16px 20px;
}

.remark-modal .ant-modal-header {
  background: rgba(1, 20, 27, 0.8);
  font-weight: 600;
  border-bottom: none;
}

.remark-modal .ant-modal-header .ant-modal-title {
  color: #fff;
  font-weight: 600;
}

.remark-modal textarea {
  border: 1px solid #4b5e64;
  background: rgba(33, 57, 65, .85);
  color: #fff;
}

.remark-modal textarea:hover {
  border: 1px solid #4b5e64!important;
}

.remark-modal .ant-form-item {
  margin-bottom: 10px;
}


.login-modal .veri-body .register-tab {
    width: 100%;
    height: 40px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px 50px;
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 24px;
}
.login-modal .veri-body .tab-item {
      width: 194px;
      height: 100%;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 600;
      line-height: 36px;
      text-align: center;
      /* color: #495666; */
      /* color: #17494D; */
      color: #fff;
      text-decoration: none;
      -webkit-transition: all .2s cubic-bezier(.645,.045,.355,1);
      -o-transition: all .2s cubic-bezier(.645,.045,.355,1);
      transition: all .2s cubic-bezier(.645,.045,.355,1);
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
}
.login-modal .veri-body .tab-item-active {
  /* background-color: #17494D; */
  /* color: #fff; */
  color: #f0774a;
  position: relative;
}
.login-modal .veri-body .tab-item-active::after {
  content: '';
  width: 30px;
  height: 2px;
  background-color: #f0774a;
  display: inline-block;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transition: all .2s cubic-bezier(.645,.045,.355,1);
  -o-transition: all .2s cubic-bezier(.645,.045,.355,1);
  transition: all .2s cubic-bezier(.645,.045,.355,1);
}



.login-modal .email-form .ant-input {
    height: 49px;
    border-radius: 4px;
}
.login-modal .code-form  {
    /* height: 49px;
    border-radius: 4px; */
    margin-bottom: 14px;
}
.login-modal .send-again {
    font-size: 16px;
    margin: 0px 0 15px 0;
    cursor: pointer;
    text-align: right;
    color: #639fcd;
}
.login-modal .code-form .ant-input {
    height: 49px;
    border-radius: 4px;
}
.login-modal .password-form .ant-input-password {
    height: 49px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
}
.login-modal .email-signup-form .ant-input {
    height: 49px;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0);
    color: #fff;
}
.login-modal .wrapper-des {
   padding: 0 30px 0 30px;
   color: #fff;
   font-size: 15px;
}

.login-modal .register-container .agree {
    font-size: 14px;
    margin-top: 4px;
    color: #999;
    width: 100%;
    display: flex;
    align-items: center;
}

.login-modal .register-container .by-sign-up {
  color: #999;
  margin-left: 5px;
  font-size: 13px;
}

.login-modal .ant-input:focus,
.login-modal .ant-input-focused,
.login-modal .ant-input:hover{
    box-shadow: none;
    /* border-color: unset; */
    border-color: #fff;
}

.login-modal .ant-input-affix-wrapper:focus,
.login-modal .ant-input-affix-wrapper-focused,
.login-modal .ant-input-affix-wrapper:hover{
    box-shadow: none;
    border-color: #fff;
}
.login-modal .login-password-container .ant-btn-primary {
    background: #42A8A2;
    border-radius: 4px;
    color: #fff;
    height: 48px;
    border: none;
    font-weight: 600;
}
.login-modal .login-password-container .checkout-guest {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.74);
  font-size: 15px;
  text-decoration: underline;
  text-align: center;
}
.checkout-guest span {
  cursor: pointer;
}
.social-login-new {
  width: 160px;
  margin: 15px auto 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.social-login-new .google-span {
  background-color: #fff;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  padding: 6px;
  cursor: pointer;
}
.social-login-new .google-login-icon-n {
  max-width: 100%;
  max-height: 100%;
}
.social-login-new .fb-login-icon-n {
  width: 40px;
  height: 40px;
  cursor: pointer;
}
.login-modal .google-div-wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   color: #fff;
   cursor: pointer;
}
.login-modal .fb-div-wrapper {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   color: #fff;
   cursor: pointer;
}

.login-modal .social-btn-text {
  font-size: 13px;
  margin-top: 6px;
}
.login-modal .phone-input-wrapper {
   position: relative;
}
.login-modal .phone-input-wrapper .phone-area-code {
  position: absolute;
  left: 4px;
  top: 13px;
  color: #fff;
}
.phone-input-wrapper .ant-form-item {
  margin-bottom: 14px;
}
.phone-input-wrapper .ant-input {
  box-shadow: none;
  padding-left: 70px;
  height: 49px;
}
.login-modal .phone-area-code img {
  margin-left: 5px;
  width: 20px;
}
.login-modal .phone-area-code span {
  padding-left: 4px;
}
.login-modal .register-container .remember-me-check {
  color: #c0c0c0;
}
.login-modal .register-container .ant-checkbox-inner {
  background: rgba(1, 17, 24, 0);
  border-radius: 0;
  border-color: #c0c0c0;
}
.login-modal .register-container .ant-checkbox:hover .ant-checkbox-inner{
    border-color: #c0c0c0;
    background-color: rgba(0,0,0,0);
}
.login-modal .register-container .ant-checkbox-checked  .ant-checkbox-inner {
  background-color: #42A8A2;
  border-color: #42A8A2;
}
.login-modal .register-container .ant-checkbox-checked:hover .ant-checkbox-inner {
  border-color: #c0c0c0;
  background-color: #42A8A2;
}
.login-modal .register-container .ant-checkbox-checked::after  {
  border-color: #42A8A2;
}
.login-modal .register-container .forget-pwd {
    font-size: 13px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
    color: #639FCD;
    text-decoration: underline;
}
.login-modal .register-container .or {
    text-align: center;
    color: #9b99a2;
    font-size: 15px;
    font-weight: 500;
    margin: 15px auto 15px;
}
.login-modal .fb-img-wrapper {
    background-color: #fff;
    color: #1877F2;
    text-align: center;
    padding: 12px 8px;
    font-size: 14px;
    border-radius: 4px;
    width: 100%;
    height: 49px;
}
.login-modal .guest-btn {
  background-color: #f0774a;
  color: #fff;
  border: none;
  text-align: center;
  padding: 12px 8px;
  font-size: 14px;
  border-radius: 4px;
  width: 100%;
  height: 49px;
  cursor: pointer;
}
.login-modal .fb-img-wrapper span {
   vertical-align: bottom;
}
.login-modal .google-img-wrapper {
    width: 100%;
    height: 49px;
    background-color: #fff;
    color: #1E191A;
    margin-bottom: 15px;
    text-align: center;
    padding: 12px 8px;
    font-size: 14px;
    border-radius: 4px;
}
.login-modal .register-container .register-not-yet {
    color: #999;
    text-align: center;
    font-size: 15px;
    margin-top: 40px;
}
.login-modal .register-container .register-btn-wrapper {
   text-align: center;
   margin-top: 14px;
}
.login-modal .register-container .register-btn {
    color: #fff;
    font-size: 20px;
    text-decoration: underline;
}
.login-modal .intro-content {
  color: rgba(255, 255, 255, 0.69);
  font-size: 15px;
}
.login-modal .send-code-wrapper {
    display: flex;
    margin-top: 20px;
}
.login-modal .send-code-wrapper .code-input {
  border: 1px solid #87BAB2;
  padding-top: 10px;
  font-size: 16px;
  padding-bottom: 10px;
}
.login-modal .send-code-wrapper .code-input:focus {
  box-shadow: 0 0 0 2px rgba(16, 199, 148, .2);
}
.login-modal .send-code-btn {
  border-radius: 4px;
  border: 1px solid #87BAB2;
  background: rgba(0, 0, 0, 0);
  color: #46ddd5;
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
  width: 200px;
  padding-top: 6px;
  padding-bottom: 6px;
  height: unset;
}
.login-modal .send-code-btn-dis {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0);
  margin-left: 10px;
  width: 200px;
  color: rgba(255, 255, 255, 0.38);
  border: 1px solid rgba(119, 129, 134, 1);
  padding-top: 6px;
  padding-bottom: 6px;
  height: unset;
}
.login-modal .submit-wrapper {
  margin-top: 40px;
}
.login-modal .submit-wrapper .submit-btn {
  background: #f0774a;
  border-radius: 4px;
  color: #fff;
  width: 100%;
  height: 48px;
  border: none;
  font-weight: 600;
}
.login-modal .submit-wrapper .submit-btn-dis {
  background: #305E65;
  color: #819DA1;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  border: none;
  font-weight: 600;
}


.css-header {
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
}
.css-body {
  padding: 14px 0 10px;
}
.language-body {
  /* max-height: 220px; */
  /* height: 5.8rem; */

  overflow-y: scroll;
  box-sizing: content-box;
  padding-bottom: 0;
  padding-top: 0;
}
.css-body .css-flag-wrapper {
  display: flex;
  align-items: center;
  font-size: 15px;
  padding: 7.5px 0;
  font-weight: 600;
  cursor: pointer;
  justify-content: space-between;
  border-top: 1px solid rgba(151, 151, 151, 0.15);
}

.css-flag-wrapper .css-flag-wrapper-left{
  display: flex;
  align-items: center;
}

.css-flag-wrapper .icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.css-flag-wrapper .flag-icon {
  width: 30px;
  height: 20px;
  margin-right: 10px;
  box-shadow: 0 7px 9px 0 rgb(1 17 24 / 8%);

}

.create-wallet-loading {
  color: red;
  position: absolute;
  left: 50%;
  top: 65%;
  transform: translateX(-50%) translateY(-50%);
}
.create-wallet-loading .ant-spin-dot {
  font-size: 26px;
}

.create-wallet-loading .ant-spin-dot .ant-spin-dot-item{
  width: 13px;
  height: 13px;
  background-color: #D4A82B;
}

.create-wallet-btn-loading {
  color: red;
}
.create-wallet-btn-loading .ant-spin-dot {
  font-size: 26px;
}
.create-wallet-btn-loading .ant-spin-dot .ant-spin-dot-item{
  width: 13px;
  height: 13px;
  background-color: #D4A82B;
}

.ant-input-number-change {

}
.ant-input-number-change .ant-input-number-handler-wrap {
  display: none !important;
}
.ant-input-number-change .ant-input-number-input-wrap input {
  text-align: right;
}

.img-dep-modal-select {
  width: 100%;
}

.img-dep-modal-select .ant-select-selector {
  width: 100%;
  height: 72px !important;
  /*padding: 0px 0 0 10px !important;*/
  /*padding: 0 23px !important;*/
  background-color: #0F1518 !important;
  border: 1px solid #2B3234 !important;
  color: white;
  font-size: 17px;
  font-weight: 600;
  border-radius: 16px !important;

}
.img-dep-modal-select .ant-select-arrow {
  color: #6B7980;
  right: 23px !important;
  width: 17px;
  height: 9px;
  margin-top: -3px;
  background: url("../src/imgs/igm_withdrawal/down_icon.png") center no-repeat;
  background-size: contain;
}
.img-dep-modal-select .ant-select-selection-item {
  line-height: 72px !important;
}

.img-dep-modal-select-option {
  display: flex;
  align-items: center;
  color: white;
  font-size: 18px;
  font-weight: 600;
  padding: 0 10px;
}

.img-dep-modal-select-option .usdt-icon-igm-modal-erc20 {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  background: url("../src/imgs/igm_withdrawal/eth_icon.png") center no-repeat;
  background-size: contain;
}

.img-dep-modal-select-option .usdt-icon-igm-modal-bep20 {
  width: 32px;
  height: 32px;
  margin-right: 10px;
  background: url("../src/imgs/igm_withdrawal/bep_icon.png") center no-repeat;
  background-size: contain;
}

.img-dep-modal-select-dropdown {
  width: 202px !important;
  min-width: 202px !important;
  height: 123px !important;
  border-radius: 13px;
  background-color: #132024;
  padding: 10px 0;
}
.img-dep-modal-select-dropdown .ant-select-item {
  margin: 5px 0;
}

.img-dep-modal-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(255, 255, 255, 0.85) !important;
  background-color: unset !important;

}


.img-dep-modal-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: rgba(255, 255, 255, 0.85) !important;
  background-color: #1E2F34 !important;

}

.img-dep-modal-select-dropdown :hover {
  background-color: unset !important;
}

.img-modal-select-minigame .ant-select-selector {
  width: 207px;
  height: 56px !important;
  padding: 0px 0 0 15px !important;
  background-color: #1E2F34 !important;
  border: none !important;
  color: white;
  font-size: 16px;
  font-weight: 500 !important;
  border-radius: 14px !important;
}
.img-modal-select-minigame .ant-select-arrow {
  olor: #6B7980;
  right: 16px !important;
  width: 17px;
  height: 9px;
  margin-top: -5px;
  background: url("../src/imgs/gameDetails/down_icon.png") center no-repeat;
  background-size: contain;
}

.img-modal-select-minigame-isopen .ant-select-arrow {
  olor: #6B7980;
  right: 16px !important;
  width: 17px;
  height: 9px;
  margin-top: -5px;
  background: url("../src/imgs/gameDetails/up_icon.png") center no-repeat;
  background-size: contain;
}

.img-modal-select-minigame .ant-select-arrow svg {
  display: none;
}
.img-modal-select-minigame .ant-select-selection-item {
  line-height: 56px !important;
}

.img-modal-select {
  width: 170px;
}

.img-modal-select .ant-select-selector {
  width: 170px;
  height: 45px !important;
  padding: 0px 0 0 10px !important;
  background-color: #182227 !important;
  border: none !important;
  color: white;
  font-size: 17px;
  font-weight: 600;
  border-radius: 8px !important;
}
.img-modal-select .ant-select-arrow {
  color: #6B7980;
  right: 7px !important;
}
.img-modal-select .ant-select-selection-item {
  line-height: 45px !important;
}

.img-modal-select-option {
  display: flex;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: 600;
}
.img-modal-select-option-minigame {
  display: flex;
  align-items: center;
  color: white;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  /*padding: 10px 0;*/
}

.img-modal-select-option .usdt-icon-igm-modal-erc20 {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  background: url("../src/imgs/igm_modal/usdt_icon.webp") center no-repeat;
  background-size: contain;
}

.img-modal-select-option-minigame .usdt-icon-igm-modal-erc20-mini-game {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  background: url("../src/imgs/igm_modal/usdt_icon.webp") center no-repeat;
  background-size: contain;
}

.img-modal-select-option .usdt-icon-igm-modal-bep20 {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  background: url("../src/imgs/igm_modal/usdt_icon.png") center no-repeat;
  background-size: contain;
}

.img-modal-select-option-minigame .usdt-icon-igm-modal-bep20-mini-game {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  background: url("../src/imgs/igm_modal/usdt_icon.png") center no-repeat;
  background-size: contain;
}

.ant-select-dropdown {
  z-index: 9999;
}

.img-modal-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(255, 255, 255, 0.85) !important;
  background-color: #388ed357 !important;

}


.img-modal-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: rgba(255, 255, 255, 0.85) !important;
  background-color: unset !important;

}

.img-modal-select-dropdown :hover {
  background-color: unset !important;
}

.img-modal-select-dropdown-mini-game {
  height: 56px;
  padding-top: 8px;
}

.img-modal-select-dropdown-mini-game .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(255, 255, 255, 0.85) !important;
  background-color: #1E2F34 !important;

}


.img-modal-select-dropdown-mini-game .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: rgba(255, 255, 255, 0.85) !important;
  background-color: unset !important;

}

.img-modal-select-dropdown-mini-game :hover {
  background-color: unset !important;
}
.header-igm-box {
  display: flex;
  /* width: 90px; */
  height: 40px;
  background-color: #58345d7a;
  align-items: center;
  padding: 0 2px 0 10px;
  border-radius: 40px;
  margin-left: 22px;
  cursor: pointer;
  font-family: Roboto;
  position: relative;
  justify-content: space-between;
}
.header-igm-box .igm-icon {
  width: 38px;
  height: 38px;
  background: url("../src/imgs/IGM@2x.webp") center no-repeat;
  background-size: contain;
}
.header-igm-box .ruby-icon {
  width: 35px;
  height: 35px;
  background: url("../src/imgs/generate/ruby_icon.webp") center no-repeat;
  background-size: contain;
}
.ruby-icon-m {
  width: .3rem;
  height: .3rem;
  margin-left: .1rem;
  background: url("../src/imgs/generate/ruby_icon.webp") center no-repeat;
  background-size: contain;
}
.m-header-dropdown {
  background: unset !important;
}
.header-igm-box .igm-number {
  width: 52px;
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  color: #fff;
}
.header-igm-box .igm-add-btn {
  background: url('https://static-img.igamie.com/static/new_home_pics/community_css_new.png') -10px -130px;
  width: 76px; height: 74px;
  zoom: .4;
}
.header-igm-box .points-icon {
  width: 38px;
  height: 38px;
  background: url('../src/imgs/Points@2x.webp') center no-repeat;
  background-size: contain;
}


/**/

.wallet-modal {
  border-radius: 1px 1px 1px 1px;
  overflow: hidden;
}
.wallet-modal .ant-modal-content {
  border: 1px solid #1F454C;
  background: rgba(255,255,255,0);
  border-radius: 8px 8px 8px 8px;
  overflow: hidden;
  margin-bottom: 10px;
}

.wallet-modal .ant-modal-close {
  /*margin-right: 8px;*/
  margin-right: .07rem;
}
.wallet-modal .ant-modal-close-icon {
  color: #fff;
  font-size: 20px;
}

.wallet-modal .ant-modal-close-x{
  line-height:46px;
}

.wallet-modal .ant-modal-body {
  padding: 0;
  overflow: hidden;
}

.wallet-modal .veri-header {
  background: #031E35;
  /* padding: 15px 30px 15px 30px; */
  padding: .07rem .3rem .07rem .3rem;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
}

.blind-box-modal {
  border-radius: 1px 1px 1px 1px;
  /*overflow: hidden;*/
}
.blind-box-modal .ant-modal-content {
  /*border: 1px solid #1F454C;*/
  background: rgba(255,255,255,0);
  box-shadow: none;
  /*background: red;*/
  border-radius: 8px 8px 8px 8px;
  /*overflow: hidden;*/
  padding-bottom: .5rem;
  padding-top: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.blind-box-modal .ant-modal-close {
  /*margin-right: 8px;*/
  margin-right: .76rem;
}
.blind-box-modal .ant-modal-close-icon {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  color: #3E4E92;
}

.blind-box-modal .ant-modal-close-x{
  line-height:46px;
}

.blind-box-modal .ant-modal-body {
  padding: 0;
  overflow: hidden;
}

.blind-box-modal .veri-header {
  background: #031E35;
  /* padding: 15px 30px 15px 30px; */
  padding: .07rem .3rem .07rem .3rem;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  width: 100%;
  height: .5rem;
  background: rgba(255,255,255,0);
  display: flex;
  justify-content: flex-end;
}

/*注册弹窗*/
.register-modal {
  border-radius: 1px 1px 1px 1px;
  text-align: center;
  /*overflow: hidden;*/
}
.register-modal .ant-modal-content {
  /*border: 1px solid #1F454C;*/
  background: rgba(255,255,255,0);
  /*background: red;*/
  border-radius: 8px 8px 8px 8px;
  /*overflow: hidden;*/
  padding-bottom: .5rem;
  padding-top: .5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: none;
}

.register-modal .ant-modal-close {
  /*margin-right: 8px;*/
  margin-right: .06rem;
}
.register-modal .ant-modal-close-icon {
  font-size: 30px;
  font-weight: 700;
  color: #3E4E92;
}

.register-modal .mobile-close-icon-container {
  width: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  margin-top: .2rem;
}

.register-modal .mobile-close-icon-container .ant-modal-close-icon-mobile {
  font-size: 30px;
  font-weight: 700;
  color: #3E4E92;
}

.register-modal .ant-modal-close-x{
  line-height:46px;
}

.register-modal .ant-modal-body {
  padding: 0;
  overflow: hidden;
}

.register-modal .veri-header {
  background: #031E35;
  /* padding: 15px 30px 15px 30px; */
  padding: .07rem .3rem .07rem .3rem;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  width: 100%;
  height: .5rem;
  background: rgba(255,255,255,0);
  display: flex;
  justify-content: flex-end;
}

.register-modal .img-container {
  width: 470px;
  height: 540px;
  cursor: pointer;
}

.register-modal .img-container .register-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/*IGM 兑换弹窗*/
.IGM-modal {
  border-radius: 1px 1px 1px 1px;
  text-align: center;
  /*overflow: hidden;*/
}



.IGM-modal .ant-modal-content {
  border: 2px solid #142129;
  background: #021117;
  /*background: red;*/
  border-radius: 10px;
  /*overflow: hidden;*/
  padding: 37px 28px;
  /*height: 712px;*/
}

.IGM-modal .ant-modal-close {
  margin-right: -8px;
  /*margin-right: .06rem;*/
}
.IGM-modal .ant-modal-close-icon {
  font-size: 18px;
  font-weight: 700;
  color: #909A9F;
}

.IGM-modal .ant-modal-close-x{
  line-height:40px;
}

.IGM-modal .ant-modal-body {
  padding: 0;
  overflow: hidden;
}

.IGM-modal .veri-header {
  background: #031E35;
  /* padding: 15px 30px 15px 30px; */
  padding: .07rem .3rem .07rem .3rem;
  font-size: 16px;
  color: #fff;
  font-weight: 600;
  width: 100%;
  height: .5rem;
  background: rgba(255,255,255,0);
  display: flex;
  justify-content: flex-end;
}

.login-new-modal{
}

.login-new-modal .ant-modal-content{
  background: #0F1517;
  border: 1px solid #132024;
  position: relative;
  border-radius: 28px;
  padding: 15px 15px;
}
.login-new-home-modal .ant-modal-content{
  background: #131727;
  border: none;
  position: relative;
  border-radius: 28px;
  padding: 35px;
}
.login-new-modal-box {
  width: unset!important;
}


.login-new-login-modal .ant-modal-content{
  background: #FFF !important;
  border: none;
  position: relative;
  border-radius: 20px;
  padding: 15px;
}

.login-new-login-modal .ant-modal-close{
  position: absolute;
  right: 40px;
  display: none;
  top: 46px;
  color: #00000000;
  background: url("../src/imgs/airdrop_close.webp") no-repeat;
  width: 23px;
  height: 23px;
  background-size: cover;
}

.login-new-modal-box .ant-modal-content{
  background: rgba(0,0,0,0);
  border: none;
  position: relative;
  border-radius: 28px;
  padding: 15px 15px;
}
.login-new-modal .ant-modal-close{
  position: absolute;
  right: 40px;
  top: 46px;
  color: #00000000;
  background: url("../src/imgs/airdrop_close.webp") no-repeat;
  width: 23px;
  height: 23px;
  background-size: cover;
}

.login-new-home-modal .ant-modal-close{
  position: absolute;
  right: 30px;
  top: 30px;
  color: #00000000;
  background: url("../src/imgs/airdrop_close.webp") no-repeat;
  width: 23px;
  height: 23px;
  background-size: cover;
}

.login-new-modal-box .ant-modal-close {
  right: 0px;
  top: 60px;
  width:23px;
  height: 23px;
}

.login-new-modal .ant-modal-close svg{
  display: none;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color:#727677;
  box-shadow: none;
}

.login-new-modal .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover{
  border-color:#727677;
}

.skyhorse-modal .ant-modal-content {
  border-radius: 20px;
  padding: 0 30px;
}

.skyhorse-modal .ant-modal-close{
  position: absolute;
  right: 40px;
  top: 36px;
  color: #00000000;
  background: url("../src/imgs/airdrop_close.webp") no-repeat;
  width: 23px;
  height: 23px;
  background-size: cover;
}

.login-new-modal input {
  /*box-shadow: rgb(0, 48, 73) 0px 0px 0px 1000px inset!important;*/
  color: #FFFFFF !important;
  /*-webkit-text-fill-color: white !important;*/
  border-radius: 0;
  font-size: 16px;
}
.login-new-modal input:-webkit-autofill {
  box-shadow: #0F1517 0px 0px 0px 1000px inset!important;
  color: #FFFFFF !important;
  -webkit-text-fill-color: white !important;
  border-radius: 16px;
  background: #0F1517 !important;
}

.login-new-login-modal input {
  /*box-shadow: rgb(0, 48, 73) 0px 0px 0px 1000px inset!important;*/
  color: #000 !important;
  /*-webkit-text-fill-color: white !important;*/
  border-radius: 0;
  font-size: 16px;
}

.login-new-login-modal .ant-input-affix-wrapper > .ant-input:focus{
  color: #000000 !important;
}

.login-new-login-modal input:-webkit-autofill {
  box-shadow: rgba(255, 255, 255, 1) 0px 0px 0px 1000px inset!important;
  color: #000000 !important;
  -webkit-text-fill-color: #000000 !important;
  border-radius: 6px;
  background: #ffffff !important;
}

.login-new-modal .phone-flag {
  width: 20px;
}
.login-new-modal .ant-modal-body {
  overflow: unset;
}
.login-modal-mobile .phone-flag {
  width: 20px;
}
.login-new-modal .veri-header {
  font-size: 26px;
  color: #fff;
  font-weight: 800;
}
.login-new-modal .intro-content {
    color: hsla(0,0%,100%,.7);
    font-size: 15px;
    margin-bottom: 20px;
    margin-top: 20px;
}
.edit-password-modal .ant-input {
  background: #0f1517!important;
  border: 0px solid #252a2c;
  border-radius: 16px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  height: 70px;
}
.edit-password-modal .ant-input-affix-wrapper {
  background: rgba(0,0,0,0);
  border: 1px solid #252a2c;
  border-radius: 16px;
}
.edit-password-modal input:-webkit-autofill {
  -webkit-text-fill-color: #fff!important;
    background: #0f1517!important;
    border-radius: 16px;
    -webkit-box-shadow: inset 0 0 0 1000px #0f1517!important;
    box-shadow: inset 0 0 0 1000px #0f1517!important;
    color: #fff!important;
}
.edit-password-modal input[type='password']:-webkit-autofill {
  -webkit-text-fill-color: #fff!important;
    background: #0f1517!important;
    border-radius: 16px;
    -webkit-box-shadow: inset 0 0 0 1000px #0f1517!important;
    box-shadow: inset 0 0 0 1000px #0f1517!important;
    color: #fff!important;
    border-radius: 0;
    /* height: 60px; */
}
.edit-password-modal .submit-wrapper {
  text-align: right;
}
.edit-password-modal .submit-btn {
  background: linear-gradient(90deg,#005050,#003554)!important;
    border: none;
    border-radius: 16px;
    font-size: 16px;
    font-weight: 700;
    height: 54px;
    padding: 5px;
    width: 150px;
    color: #fff;
    margin: 20px 0 0 0;

}


.ant-modal-root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.ant-modal {
  padding-bottom: 0px;
}

.IGMDeposit-modal {
  top: 0 !important;
  bottom: 0 !important;
}

.IGMDeposit-modal .ant-modal-content {
  border: 1px solid #142129;
  background: #0F1518;
  /*background: red;*/
  border-radius: 15px;
  padding: 40px 32px 31px 32px;
  /*overflow: hidden;*/
  /*height: 712px;*/
}

.IGMDeposit-modal .ant-modal-close {
  /*margin-top: 20px;*/
  /*margin-right: 10px;*/
}

.IGMDeposit-modal .ant-modal-close-x {
  display: inline-block !important;
  width: 25px;
  height: 25px;
  margin-top: 47px;
  margin-right: 32px;
  background: url("../src/imgs/igm_withdrawal/close_icon.webp") center no-repeat;
  background-size: contain;
}

.IGMDeposit-modal svg {
  display: none !important;
}


.IGMWithdrawal-modal .ant-modal-content {
  border: 1px solid #142129;
  background: #0F1518;
  /*background: red;*/
  border-radius: 15px;
  padding: 40px 32px 31px 32px;
  /*overflow: hidden;*/
  /*height: 712px;*/
}
.IGMWithdrawal-modal .ant-modal-close {
  /*margin-top: 20px;*/
  /*margin-right: 10px;*/

}
.IGMWithdrawal-modal .ant-modal-close-x {
  display: inline-block !important;
  width: 25px;
  height: 25px;
  margin-top: 47px;
  margin-right: 32px;
  background: url("../src/imgs/igm_withdrawal/close_icon.webp") center no-repeat;
  background-size: contain;
}

.IGMWithdrawal-modal svg {
  display: none !important;
}

.IGMWithdrawal-modal-input-number {

}

.IGMWithdrawal-modal-input-number .ant-input-number-handler-wrap {
  display: none !important;
}

.IGMWithdrawal-modal-input-number-disable .ant-input-number-input {
  color: #55595B !important;
}

.ant-notification {
  margin-right: 0rem;
  margin-top: .9rem;

}

.IGMWithdrawal-notification {
  font-size: 18px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 24px;
  padding-top: 25px;
  padding-bottom: 25px;
  width: 4.5rem;

}

.IGMWithdrawal-notification .ant-notification-notice-message {
  display: none;
}

.IGMWithdrawal-notification .ant-notification-notice-close {
  display: none;
}

.IGMWithdrawal-notification-success {
  background: linear-gradient(270deg, #29302A 0%, rgba(29,48,24,0.81) 100%);
  border-radius: 18px 0px 0px 18px;
}
.IGMWithdrawal-notification-failed {
  background: linear-gradient(270deg, #292725 0%, rgba(51,38,12,0.81) 100%);
  border-radius: 18px 0px 0px 18px;
}

.IGMWithdrawal-notification-content {
  display: flex;
  align-items: center;
  min-height: 24px;
  font-size: 20px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 24px;
}

.IGMWithdrawal-notification-content .igm-notification-icon-success {
  width: 31px;
  height: 31px;
  background: url("../src/imgs/igm_withdrawal/notification_success_icon.webp") center no-repeat;
  background-size: contain;
  margin-right: 14px;
}
.IGMWithdrawal-notification-content .igm-notification-icon-failed {
  width: 31px;
  height: 31px;
  background: url("../src/imgs/igm_withdrawal/notification_failed_icon.webp") center no-repeat;
  background-size: contain;
  margin-right: 14px;
}

.IGMWithdrawal-notification-content .igm-notification-content-reason {
  line-height: 30px;
  width: 3.4rem;
}
.nft-sell-order-modal {
  /*background: #0F1518;*/
}
.nft-sell-order-modal .ant-modal-content {
  padding: 0;
  border: 1px solid #142129;
  border-radius: 27px;
  overflow: hidden;
}
.nft-sell-order-modal .ant-modal-close-x {
  margin-top: 25px;
}

/**
  *
**/
.gamecode-modal {
  border-radius: 1px 1px 35px 1px;
  overflow: hidden;
}
.gamecode-modal .ant-modal-content {
  border: 1px solid rgba(34, 188, 154, 0.47);
  background: rgba(255,255,255,0);
  border-radius: 8px 8px 25px 1px;
  overflow: hidden;
}
.gamecode-modal .ant-modal-close {
  margin-right: 8px;
}
.gamecode-modal .ant-modal-close-icon {
  color: #fff;
  font-size: 20px;
}
.gamecode-modal .ant-modal-close-x{
  line-height:46px;
}
.gamecode-modal .ant-modal-body {
  padding: 0;
  overflow: hidden;
}
.gamecode-modal .veri-header {
    background: #001824;
    /* padding: 15px 30px 15px 30px; */
    padding: .15rem .3rem .15rem .3rem;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
}
.gamecode-modal .veri-body {
    /* padding: 30px 30px 46px 30px; */
    padding: .3rem .3rem .46rem .3rem;
    background: #0E212B;
}
.gamecode-modal .intro-content {
    color: rgba(255, 255, 255, 0.69);
    font-size: 15px;
}
.gamecode-modal .send-code-wrapper {
    display: flex;
    margin-top: 20px;
}
.gamecode-modal .send-code-wrapper-relative {
  position: relative;
}

.send-code-wrapper-relative .area-code {
    position: absolute;
    left: 4px;
    top: 17px;
    color: #fff;
}
.send-code-wrapper-relative .phone-flag {
   width: 20px;
   margin-left: 5px;
   margin-bottom: 3px;
}
.send-code-wrapper-relative .code {
  margin-left: 4px;

}



/*igm 弹窗选择器*/

.ant-input-number-change {

}

.ant-input-number-change .ant-input-number-input {
  padding: 0 2px;
}
.ant-input-number-change .ant-input-number-handler-wrap {
  display: none !important;
}
.ant-input-number-change .ant-input-number-input-wrap input {
  text-align: right;
}

.img-modal-select {
  width: 170px;
}

.img-modal-select .ant-select-selector {
  width: 170px;
  height: 45px !important;
  padding: 0px 0 0 10px !important;
  background-color: #182227 !important;
  border: none !important;
  color: white;
  font-size: 17px;
  font-weight: 600;
  border-radius: 8px !important;
}
.img-modal-select .ant-select-arrow {
  color: #6B7980;
  right: 7px !important;
}
.img-modal-select .ant-select-selection-item {
  line-height: 45px !important;
}

.img-modal-select-option {
  display: flex;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: 600;
}

.img-modal-select-option .usdt-icon-igm-modal-erc20 {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  background: url("../src/imgs/igm_modal/usdt_icon.webp") center no-repeat;
  background-size: contain;
}

.img-modal-select-option .usdt-icon-igm-modal-bep20 {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  background: url("../src/imgs/igm_modal/usdt_icon.png") center no-repeat;
  background-size: contain;
}

.ant-select-dropdown {
  z-index: 9999;
}

.img-modal-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(255, 255, 255, 0.85) !important;
  background-color: #388ed357 !important;

}


.img-modal-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: rgba(255, 255, 255, 0.85) !important;
  background-color: unset !important;

}

.img-modal-select-dropdown :hover {
  background-color: unset !important;
}

.img-modal-select-option {
  display: flex;
  align-items: center;
  color: white;
  font-size: 15px;
  font-weight: 600;
}

.img-modal-select-option .usdt-icon-igm-modal-erc20 {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  background: url("../src/imgs/igm_modal/usdt_icon.webp") center no-repeat;
  background-size: contain;
}

.img-modal-select-option .usdt-icon-igm-modal-bep20 {
  width: 30px;
  height: 30px;
  margin-right: 8px;
  background: url("../src/imgs/igm_modal/usdt_icon.png") center no-repeat;
  background-size: contain;
}

.ant-select-dropdown {
  z-index: 9999;
}

.img-modal-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(255, 255, 255, 0.85) !important;
  background-color: #388ed357 !important;

}


.img-modal-select-dropdown .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: rgba(255, 255, 255, 0.85) !important;
  background-color: unset !important;

}

.img-modal-select-dropdown :hover {
  background-color: unset !important;
}



.gamecode-modal .send-code-wrapper-new {
  display: flex;
  flex-direction: column;
}
.gamecode-modal .code-input {
    border: 1px solid #87BAB2;
    padding-top: 10px;
    font-size: 16px;
    padding-bottom: 10px;
}
.gamecode-modal .code-input-phone {
  padding-left: 80px;
}
.gamecode-modal .code-input:focus {
   box-shadow: 0 0 0 2px rgba(16, 199, 148, .2);
}
.gamecode-modal .submit-wrapper {
  margin-top: 40px;
}
.gamecode-modal .register-later {
  text-align: center;
  color: rgba(255, 255, 255, 0.69);
  padding-top: 20px;
}
.gamecode-modal .back-to-login {
  font-size: 15px;
  color: #fff;
  text-align: center;
  margin-top: 15px;
  cursor: pointer;
}
.gamecode-modal .back-to-login .left-img {
  width: 16px;
  margin-bottom: 4px;
}

.gamecode-modal .submit-btn {
    background: #42A8A2;
    border-radius: 4px;
    color: #fff;
    width: 100%;
    height: 48px;
    border: none;
    font-weight: 600;
}
.gamecode-modal .goto-login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
}
.goto-login-wrapper .already-member {
  color: #9d9d9d;
  font-size: 16px;
}
.goto-login-wrapper .login-link {
   color: #639fcd;
   margin-left: 5px;
   font-size: 18px;
   text-decoration: underline;
   font-weight: 600;
   cursor: pointer;
}

.gamecode-modal  .intro-content {
  color: rgba(255, 255, 255, 0.69);
  font-size: 15px;
}
.gamecode-modal  .send-code-wrapper {
    display: flex;
    margin-top: 20px;
}
.gamecode-modal  .send-code-wrapper .code-input {
  border: 1px solid #134C49;
  font-size: 15px;
  padding-bottom: 10px;
  font-weight: 600;
  height: 55px;
  border-radius: 6px;
  background-color: unset;
}
.gamecode-modal  .send-code-wrapper .code-input:focus {
  box-shadow: 0 0 0 2px rgba(16, 199, 148, .2);
}
.gamecode-modal  .send-code-btn {
  border-radius: 4px;
  border: 1px solid #87BAB2;
  background: rgba(0, 0, 0, 0);
  color: #46ddd5;
  font-weight: 600;
  font-size: 14px;
  margin-left: 10px;
  width: 200px;
  padding-top: 6px;
  padding-bottom: 6px;
  height: unset;
}
.gamecode-modal  .send-code-btn-dis {
  border-radius: 4px;
  background: rgba(0, 0, 0, 0);
  margin-left: 10px;
  width: 200px;
  color: rgba(255, 255, 255, 0.38);
  border: 1px solid rgba(119, 129, 134, 1);
  padding-top: 6px;
  padding-bottom: 6px;
  height: unset;
}
.gamecode-modal  .submit-wrapper {
  margin-top: 30px;
}
.gamecode-modal  .submit-wrapper .submit-btn {
  background: #f0774a;
  border-radius: 4px;
  color: #fff;
  width: 100%;
  height: 48px;
  border: none;
  font-weight: 600;
  font-size: 16px;
}
.gamecode-modal  .submit-wrapper .submit-btn-dis {
  background: #305E65;
  color: #819DA1;
  border-radius: 4px;
  width: 100%;
  height: 48px;
  border: none;
  font-weight: 600;
}

.gamecode-modal .status-icon {
  width: 70px;
  height: 70px;
  text-align: center;
  display: block;
  margin: 0 auto;
}

.gamecode-modal .order-s-m {
    padding: 48px 35px;
    background: rgba(2,39,53,.8);
}

.gamecode-modal .status-text {
    color: #fff;
    font-size: 15px;
    text-align: center;
    margin: 10px auto 60px;
}
.gamecode-modal .total-amount {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: hsla(0,0%,100%,.69);
    font-size: 15px;
}

.gamecode-modal .btn-1 {
  background: #B38F29;
  color: #fff;
  width: 100%;
  margin: 20px auto 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;

}

.gamecode-modal .btn-2 {
  color: #fff;
  width: 100%;
  height: 40px;
  line-height: 40px;
  margin: 20px auto 0;
  text-align: center;
  cursor: pointer;

}

.gamecode-modal .btn-3 {
  background: #42A8A2;
  color: #fff;
  width: 100%;
  margin: 20px auto 0;
  height: 40px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;

}







.referral-modal {


}
.referral-modal .ant-modal-content {
  background-color: rgba(255,255,255,0);
  border-radius: 8px;
  overflow: hidden;


}
.referral-modal .ant-modal-close {
  color: #333;
  font-size: 20px;
}
.referral-modal .ant-modal-body {
  padding: 0;

}
.referral-modal .ant-modal-close-icon {
  font-size: 20px;
}
.referral-modal .referral-link {
  /* position: absolute;
  bottom: 30px;
  width: 90%;
  height: 100px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  z-index: 9999; */
}

.referral-modal img {
  width: 100%;
}


.modal {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: auto;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99999;
}
.modal .modal-wrapper {
  background: rgba(0, 0, 0, 0.8);
  border: 2px solid rgba(255, 255, 255, 0.2);
  width: 370px;
  height: auto;
  border-radius: 7px;
  margin: 200px auto;
  padding: 30px;
  color: white;
  transition: all 0.6s;
  z-index: 1000;
  animation: animation-modal 0.3s ease-in-out 0s 1 normal none running ;
}

.modal .title-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.modal .share-img {
  width: 290px;
  height: 290px;
  object-fit: cover;
  position: absolute;
  top: -110px;
  left: 7px;
  border-radius: 7px;
}

.modal .back {
  position: absolute;
  right: -35px;
  top: -120px;
  font-weight: bold;
  cursor: pointer;
}

.modal .share-content-text {
  margin-top: 190px;
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 17px;
}

.modal .share-bottom-text {
  margin-top: 0px;
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.70);
  font-size: 13px;
}

.modal  .share-bottom-text {
  margin-top: 0px;
  width: 100%;
  text-align: center;
  color: rgba(255, 255, 255, 0.70);
  font-size: 13px;
}

.modal .share-third {
  margin-top: 20px;
  padding: 0px 10px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.modal .share-item {
  width: 33.33%;
  display: flex;
  justify-content: center;
  margin-bottom: 22px;
}

.modal .share-copy-button {
  width: 100%;
  background: #f0774a;
  margin: 0px 0px;
  text-align: center;
  padding: 10px 0px;
  font-size: 17px;
  border-radius: 3px;
  cursor: pointer;
}

.modal .copy-icon{
  background: url("https://cdn.igamie.com/static/copy-icon.png");
  width: 14px;
  height: 14px;
  background-size: cover;
  display: inline-block;
}

.modal .face-book {
  background: url("../src/imgs/share/facebook.png") center no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
}

.modal .twitter-book {
  background: url("../src/imgs/share/twitter.png") center no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
}

.modal .message-book {
  background: url("../src/imgs/share/whatsapp.png") center no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
}

.modal .line-book {
  background: url("../src/imgs/share/line.png") center no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
}

.modal .telegram-book {
  background: url("../src/imgs/share/telegram.png") center no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
}

.modal .ins-book {
  background: url("../src/imgs/share/instagram.png") center no-repeat;
  background-size: contain;
  width: 50px;
  height: 50px;
}

.my-coins .ant-picker {
  border: none;
  box-shadow: none;
  background: unset;
  color: #fff;
  padding-right: 0;
  width: 230px;
}

.my-coins .ant-picker-range .ant-picker-active-bar {
  background: #fff;
}
.my-coins .ant-picker-suffix {
  display: none;
}
.my-coins .ant-picker-clear {
  /* color: #fff; */
  color: #fff;
  background: unset;
  right: 0px;
}
.my-coins .ant-picker-separator {
  color: #fff;
  margin-right: 10px;
}
.my-coins .ant-picker-active-bar {
  display: none;
}

.ant-picker-dropdown .ant-btn-primary {
    background-color: #1890ff;
    border-color: #1890ff;
}
.confirm-modal-new {

}
.confirm-modal-new .ant-modal-content {
   background: rgba(2,39,53,.82);
}
.confirm-modal-new .ant-modal-confirm-title {
  color: #fff;
}
.confirm-modal-new .ant-btn {
  background: #305e65;
  color: #819da1;
  border: none;
}
.confirm-modal-new .ant-btn-primary {
  background: #42a8a2;
  color: #fff;
}

.login-page {

}
.login-page .ant-input-password {
  background-color: unset;
}
.login-page .ant-input, .login-page .ant-input-password .ant-input {
  border-radius: 4px!important;
  line-height: 40px;
  background: rgba(0, 0, 0, 0.85);
  border-radius: 0;
  font-weight: 600;
  border-color: #095359;

}
.login-page .ant-checkbox-inner {
    background: rgba(0,0,0,0);
    border-radius: 0;
}
.login-page .phone-flag {
  width: 20px;
}
.login-page .phone-input-wrapper .ant-input {
  padding-left: 70px;
  box-shadow: none;
}
.login-page .phone-input-wrapper .password-input-n .ant-input {
  padding-left: 16px;
  height: 39px;
}
.login-page .phone-input-wrapper .ant-input-password .ant-input {
  padding-left: 0;
  height: 39px;
}
.my-orders-page .ant-spin-dot {
  font-size: 35px;
}
.my-orders-page .ant-spin-dot-item {
  width: 15px;
  height: 15px;
  background-color: #D4A82B;
}
.my-orders-page .game-table .ant-table-cell {
  padding: 20px 16px !important;
}
.my-orders-page .anticon-search {
  color: #aaa;
  cursor: pointer;
  font-size: 20px;
}

.profile-page .pi-form .ant-input {
    background-color: rgba(0,0,0,0);
    border: none;
    box-shadow: none;
    font-size: 18px;
    font-weight: 600;
    color: #728189!important;
}
.profile-page .pi-form .ant-select-selector {
    color: #728189;
    background-color: unset;
    border: none;
    box-shadow: none;
    font-size: 18px;
}

#personal-info_email {
  cursor: pointer;
}

.profile-page .pi-form .ant-radio-wrapper {
  color: #728189;
  font-size: 18px;
}
.profile-page .pi-form .ant-radio-wrapper-checked {
  color: #fff;
}

.profile-page .pi-form .ant-select-arrow {
  color: #fff;
  font-size: 18px;
  /* display: none; */
}
.profile-page .ant-select-arrow .anticon {
  background: url('https://cdn.igamie.com/static/sc_arr.png') center no-repeat;
  background-size: contain;
  width: 13px;
  height: 13px;
  display: inline-block;
}
.profile-page .ant-select-arrow .anticon svg {
  display: none;
}

.profile-page .pi-form .ant-radio-inner{
  background: rgba(0,0,0,0);
}
.profile-page .pi-form .ant-radio-checked .ant-radio-inner{
  border: 1px solid #fff;
}

.profile-page .pi-form .ant-radio-inner::after{
}
.profile-page .ant-form-item-label>label::after{
  content: '';
}

.profile-page .pi-form .ant-form-item-label>label{
  color: #fff;
  font-size: 18px;
  width: 200px;
}

.profile-page .pi-form .ant-form-item{
  color: #fff;
}
.game-detail-page .slick-slide {

}

.clear-rich-text{

}
.clear-rich-text span{

}

.game-detail-page .slick-slide .role-img-wrapper {
  height: 100%;
  display: block !important;

}
.game-detail-page .slick-slide .role-img-wr {
  width: 55px;
  height: 55px;
  margin: 0 auto;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
  transition: all .5s .5s;
  transform: scale(0.68);
}
.game-detail-page .slick-slide .role-img-wr::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, .4);
    transition: all .5s .5s;
}
.game-detail-page .slick-slide .role-img {
    display: block;
    margin: 0 auto;
    transition: all .4s;
    width: 100%;
    height: 100%;
}
.game-detail-page .select-server .ant-select-selector {
    background: #dde1ee!important;
    border-radius: 4px;
    height: 46px;
    border: none;
}
.select-server-sl .ant-select-selector{
  /*background: rgba(14, 33, 43, 0.87) !important;*/
  border-radius: 4px;
  align-items: center;
  height: 46px !important;
  line-height: 46px !important;
  border: 1px solid #dde1ee !important;
  color: #000;
}
.select-server-sl .ant-select-arrow{
  color: #FFFFFF;
}
.game-detail-page .select-server-sl .ant-select-selector {
  background: #FFFFFF !important;
  border-radius: 4px;
  align-items: center;
  color: #000000 !important;
  height: 46px !important;
  line-height: 46px !important;
  border: 1px solid #9B99A2 !important;
}

.game-detail-page .ant-select-arrow{
  color: #FFFFFF;
}

.game-detail-page .select-server  .ant-select-selection-search-input {
  height: 46px!important;
  line-height: 46px;
}
.game-detail-page .select-server  .ant-select-selection-placeholder {
    line-height: 46px;
    color: #011118;
    font-size: 16px;
}

.select-server .ant-select-selection-item {
  line-height: 46px!important;
}

.my-order-page .ant-spin-dot {
  font-size: 35px;
}
.my-orders-page .ant-spin-dot-item {
    width: 15px;
    height: 15px;
    background-color: #D4A82B;
}
.my-orders-page .anticon_search {
    color: #aaa;
    cursor: pointer;
    font-size: 20px;
}
.my-orders-page .ant-table {
  /* font-size: 16px; */
}
.my-orders-page .ant-table-thead>tr>th{
  /* background: rgba(248, 249, 252, 1); */
}
.my-orders-page .ant-statistic {
  line-height: unset;
  margin-left: 10px;
}
.my-orders-page .ant-statistic-content {
  font-size: 18px;
  color: #666;
}

.voucher-detail-page .anticon-search {
    color: #aaa;
    cursor: pointer;
    font-size: 20px;
}

.referral-code-page .ske-wra {
  display: flex;
}
.referral-code-page .discount-ske-item {
   width: 33.33%;
   padding: 0 10px;
}
.referral-code-page .discount-ske {
  width: 100%;
  height: 200px;
}
.referral-code-page .discount-ske-item:nth-child(1) {
  padding-left: 0;
}
.referral-code-page .discount-ske-item:nth-child(3) {
  padding-right: 0;
}
.referral-code-page .receive-modal {

}
.referral-code-page .receive-modal .ant-modal-content {
    background: url('https://cdn.igamie.com/static/ic_lq_tc@2x.png') center no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    box-shadow: none;
}
.referral-code-page .receive-modal .ant-modal-body {
  height: 100%;
}
.referral-modal .voucher-con {
  margin: 140px auto;
  text-align: center;
}
.referral-modal .voucher-con .name {
    font-size: 40px;
    font-weight: 600;
}
.referral-modal .time {
  color: #666;
  font-size: 20px;
  margin-top: 20px;
}

.referral-modal .use-now-btn {
  width: 3.6rem;
  cursor: pointer;
  background: linear-gradient(90deg, #F0D195, #B48031);
  position: absolute;
  bottom: .7rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 100px;
  padding: 7px 0;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
}
.share-modal {

}
.share-modal .ant-modal-content {
    background: url('https://cdn.igamie.com/static/ic_fx_bg@2x.png') center no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    box-shadow: none;
}
.share-modal .ant-modal-body {
    height: 100%;
    padding: 38px 66px;
}
.share-modal .share-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.share-modal .share-button-wrapper svg {
  border-radius: 5px;
}
.share-modal .share-button-wrapper .share-app-txt {
  color: #011118;
  font-size: 14px;
  margin-top: 10px;
}
.share-modal .copy-link-txt{
  color: #47301F;
  margin-top: 30px;
  margin-left: 25px;
  margin-bottom: 10px;
}
.share-modal .copy-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 26px;
}
.share-modal .copy-link{
  border-radius: 4px;
  border: 1px solid #CC9F58;
  width: 250px;
  height: 40px;
  line-height: 40px;
   overflow-x: scroll;
   white-space: nowrap;
   padding-left: .2rem;
}
.share-modal .copy-btn {
  width: 50px;
  height: 50px;
}

.earn-coins-hi {
  max-height: 330px;
  overflow: hidden;
}
.play-to-earn-hi {
  max-height: 575px;
  overflow: hidden;
}

.earn-coins-show {
  height: auto;
}
.play-to-earn-show {
  height: auto;
}

.earn-show-arrow-wrapper {
  display: flex;
  height: 30px;
  background: linear-gradient(180deg, rgb(6, 32, 52) 0%, rgba(6,38,58,1) 100%);
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  font-size: .16rem;
  border-radius: 10px;
  /*padding-top: 5px;*/
  padding-bottom: 8px;
  cursor: pointer;
  color: white;
  /*font-weight: bold;*/
}

.earn-show-arrow {
  width: 30px;
  height: 10px;
  background: url('https://cdn.igamie.com/static/ap_arrow.png') bottom no-repeat;
  background-size: contain;
  display: inline-block;
  /*margin-top: 6px;*/
  cursor: pointer;
  margin-bottom: 0px;
  transition: all .2s;
}

.earn-show-arrow-show {
  width: 30px;
  height: 10px;
  background: url('https://cdn.igamie.com/static/ap_arrow.png') bottom no-repeat;
  background-size: contain;
  display: inline-block;
  margin-top: 6px;
  cursor: pointer;
  margin-bottom: 0px;
  transform: rotate(180deg);
  transition: all .2s;

}

.nav-bar-mobile-new {
  display: none;
}
.coins-app-modal .ant-modal-content{
  background: url('https://igamie.oss-ap-southeast-1.aliyuncs.com/real/api/2022-06-27/3b7dc2da-6b57-4c47-93fc-2ade2db5eedd.image') center no-repeat ;
  background-size: contain;
  width: 100%;
  height: 100%;
}
.coins-app-modal .introduction {
  font-size: .24rem;
  margin: 2.5rem auto 0;
  color: #fff;
  font-weight: 600;
  text-align: center;
  padding: 0 .2rem;
}
.coins-app-modal .get-app-btn {
  width: 1.8rem;
  display: block;
  margin:  0 auto;
  background: #D7B144;
  color: #fff;
  height: .44rem;
  line-height: .44rem;
  font-size: .17rem;
  text-align: center;
  border-radius: 6px;
  margin-top: .3rem;
  font-weight: 600;
}
.coins-app-modal .get-app-btn a {
  color: #fff;
}

.coins-app-modal {
   width: 440px!important;
   height: 506px!important;
}

.redeem-detail-modal {

}

.redeem-detail-modal .ant-modal-content {
  /* width: 405px; */
  /* height: 580px; */
  background: linear-gradient(180deg, #005050 0%, #003554 100%);
  border-radius: 20px;
  /* border: 1px solid; */
  border-image: linear-gradient(180deg, rgba(0, 80, 80, 1), rgba(45, 146, 163, 1), rgba(0, 53, 84, 1)) 1 1;
}
.redeem-detail-modal .ant-modal-body {
  padding: 17px 17px 30px 17px;
}
.redeem-d-content {

}
.redeem-d-content .voucher-banner {
  width: 100%;
  height: 218px;
}
.redeem-detail-modal .voucher-banner-img {
  object-fit: cover;
  width: 100%;
  height: 218px;
}
.redeem-detail-modal .voucher-name {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 20px;
}
.redeem-detail-modal .voucher-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.redeem-detail-modal .voucher-i {
   font-size: 14px;
   color: #9CB4BB;
}
.redeem-detail-modal .voucher-price {

}
.redeem-detail-modal .voucher-price .price-now{
  font-size: 20px;
  color: #B49029;
  font-weight: 600;
}
.redeem-detail-modal .voucher-price .price-origin {
  font-size: 13px;
  color: #9CB4BB;
  text-decoration: line-through;
  font-weight: 600;
  margin-left: 6px;

}

.redeem-detail-modal .voucher-date {
   font-size: 12px;
   color: #fff;
   font-weight: 600;
}

.redeem-detail-modal .voucher-tc {
   color: #9CB4BB;
   margin-top: 20px;
   max-height: 100px;
   overflow-y: scroll;

}
.redeem-detail-modal .voucher-tc-title {
  font-size: 14px;
}
.redeem-detail-modal .voucher-tc-content {
   font-size: 13px;
}
.redeem-detail-modal .redeem-now-btn {
  background: linear-gradient(180deg, #B38F29 42%, #D7B144 100%);
  border: 2px solid rgba(208, 170, 62, 0.79);
  font-size: 19px;
  color: #fff;
  border-radius: 4px;
  height: 54px;
  line-height: 50px;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  width: 100%;
  margin-top: 30px;
}


.discover-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  /* transform: translateY(-50%); */
}
.discover-loading  .ant-spin-dot-item{
  background: #005050;

}

.config-form {
  display: flex;
  flex-wrap: wrap;
}
.config-form .ant-select {
  color: #666;
}
.config-form .ant-select-selection-placeholder {
  line-height: 40px!important;
}
.config-form .ant-form-item {
  width: 262px;
  margin-right: 10px;
}
.config-form .ant-input {
  height: 40px;
  color: #666!important;
  border-color: #999;
}
.config-form .ant-select-selector {
  height: 40px!important;
  border-color: #999!important;
}
.config-form .ant-select-selection-search-input {
  height: 40px!important;
}
.config-form .ant-select-selection-item {
  line-height: 40px!important;
}
.config-form .ant-form-item-label > label {
  height: 40px;
}

.my-order-page-new .ant-table{
  background: #ffffff00;
  color: #fff;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.my-order-page-new .ant-table-thead > tr{
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.my-order-page-new .mr-10, .my-orders-page .ml-5{
  margin: 0;
}

.my-order-page-new .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
  padding: 16px 0;
  border: none;
}

.my-order-page-new .ant-table-thead > tr > th{
  background: #30444E;
  color: #A4ADB0;
  border: none;
  padding: 16px 27px;
  font-size: 18px;
}

.my-order-page-new .ant-table-tbody > tr{
  background: #132630;
  color: #fff;
  border: none;
  padding: 16px 27px;
}

.my-order-page-new .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #132630;
}

.my-order-page-new .ant-empty{
  color: #fff;
}

.order-detail-page .ant-typography{
  color: rgba(255, 255, 255, 0.73);
}
.order-detail-page .ant-typography a.ant-typography, .ant-typography a{
  color: #548094;
}
.card-modal {
  width: 400px!important;
}
.card-modal .ant-modal-content{
   background: unset;
   box-shadow: unset;
}
.card-modal .ant-modal-close {
  color: #fff;
  top: -30px;
  right: 5px;
}
.card-modal .ant-modal-close-x {
  font-size: 20px;
}

.card-modal .card-wrapper {
  overflow: hidden;
}
.card-modal .card-wrapper .card-nft-wrapper {
  background: url('http://static-img.igamie.com/static/nftStatic/nft_item_mobile_bg.webp') center no-repeat;
  background-size: cover;
  width: 352px;
  height: 475px;
  padding: 20px;
  border-radius: 8px;
}
.card-modal .card-wrapper .card-nft-wrapper .nft-banner {
  width: 100%;
  height: 380px;
  object-fit: cover;
}
.card-modal .nft-name {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin: 20px auto 0px;
  text-align: center;
}




.card-modal .card-m-front, .card-modal .card-m-reverse {
  position: absolute;
  backface-visibility: hidden;
  transition: transform 0.3s linear;

}

.card-modal .card-m-front {
  transform: perspective(600px) rotateY(0deg);
  position: relative;
}
.card-modal .card-m-front .click-btn {
  width: 100%;
  bottom: 0px;
  top: 0;
  left: 0;
  right: 0;
  position: absolute;
  cursor: pointer;
}
.card-modal .card-m-front img {
  width: 352px;
}


.card-modal .card-m-front-rotate {
  transform: perspective(600px) rotateY(-180deg);
  position: absolute;
}
.card-modal .card-m-reverse-rotate  {
  transform: perspective(600px) rotateY(360deg);
}
.card-modal .card-name {
  font-size: 19px;
  text-align: center;
  font-weight: 600;
}

.card-voucher-price {
  font-size: 23px;
  font-weight: 600;
  color: #f0774a;
  line-height: 30px;
}
.card-voucher-time {
  color: #666;
  font-size: 13px;
}
.card-description {
  font-size: 16px;
  color: #b3b9d8;
  margin-top: 15px;
}
.card-description-scroll {
  font-size: 16px;
  color: #b3b9d8;
  margin-top: 15px;
  height: 210px;
  white-space: pre-wrap;
  overflow: scroll;
}
.card-nft-modal .card-description-scroll {
  height: 340px;
}
@keyframes delayShow {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


/* .card-nft-modal .card-m-reverse .card-description-scroll {
   opacity: 0;
   transition: all 1s ease 4s;
} */
.card-nft-modal .card-m-reverse-rotate .card-description-scroll {
  animation: delayShow 1.4s linear;
}
.card-user-btn {
  background: linear-gradient(180deg,#b38f29 42%,#d7b144);
  border: 2px solid rgba(208,170,62,.79);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 48px;
  line-height: 18px;
  position: absolute;
  bottom: 35px;
  width: 290px;
  cursor: pointer;
}

.card-user-btn-dis {
  border: 2px solid rgba(208,170,62,.79);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 48px;
  line-height: 18px;
  position: absolute;
  bottom: 35px;
  width: 290px;
  cursor: pointer;
  background: #999;
}



.card-user-btn .text-1, .card-user-btn-dis .text-1{
   font-size: 16px;
   color: #fff;
   font-weight: 600;
}
.card-user-btn .text-2, .card-user-btn-dis .text-2 {
  font-size: 13px;
  color: #F9F5EB;
}

/* .card-modal .card-m-front:hover {
  transform: perspective(600px) rotateY(-180deg);
}
.card-modal .card-m-front:hover .card-modal .card-m-reverse {
  transform: perspective(600px) rotateY(360deg);
} */



.home-banner-img {
  width: 100%;
  height: 346px;
}

.wallet-connect-modal {


}
.wallet-connect-modal .ant-modal-content {
  background: unset;
  background: linear-gradient(180deg, #0E212B 0%, #041118 100%);
  border-radius: 12px;
  border: 1px solid #095359;
}
.wallet-connect-modal .ant-modal-header {
  background: #000B11;
  border-bottom: none;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.wallet-connect-modal .ant-modal-title {
  color: #fff;
}
.wallet-connect-modal .ant-modal-close {
  color: #fff;
}
.wallet-connect-modal .wallet-md-body {

}
.wallet-connect-modal .wallet-md-body-title {
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin-top: 20px;
}
.wallet-connect-modal .wallet-md-con-btn {
  width: 375px;
  height: 52px;
  line-height: 52px;
  text-align: center;
  background: linear-gradient(90deg, #1D73D0 0%, #8600C6 100%);
  box-shadow: 0px 10px 14px -5px rgba(98,54,255,0.05);
  border-radius: 4px;
  font-size: 15px;
  color: #fff;
  margin:  15px auto 38px;
  cursor: pointer;
}

.igamie-spin .ant-spin-dot-item {
  background-color: #005050;
  width: 12px;
  height: 12px;
}
.igamie-spin .ant-spin-dot {
  font-size: 30px;
}

.air-drop-drawer {

}
/* .air-drop-drawer .airdrop-pc-body::-webkit-scrollbar {
  height: unset;
  width: 10px;
  background: #002132;
  display: block;
  position: absolute;
}
.air-drop-drawer .airdrop-pc-body::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 10px;
  border: none;
  margin-right: 13px;
  transition: all 1.5s ease-in;
} */
/* .air-drop-drawer .airdrop-pc-body::-webkit-scrollbar {
  width: unset;
  height: unset;
  background: unset;
  display: block;
} */


.air-drop-drawer .ant-drawer-mask {
  background-color: rgba(0, 0, 0, 0.8);
}

.air-drop-drawer .ant-drawer-content-wrapper {
    /* transform: translateX(-80px);
    top: 100px;
    bottom: unset;
    height: 700px;
    width: 375px!important;
    border-radius: 22px;
    overflow: hidden; */

    position: fixed;
    width: 375px!important;
    /* right: 24px; */
    bottom: 48px;
    top: 90px;
    z-index: 90;
    height: calc(((100% - 0px) - 0px) - 48px);
    overflow: auto;
    filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
    transition: transform 0.2s ease 0s, opacity 0.2s ease 0s;
    visibility: visible;
    transform: translate3d(0px, 0px, 0px);
    opacity: 1;
    border-radius: 22px;
    overflow: hidden;
    padding-bottom: 60px;




}
.air-drop-drawer .ant-drawer-content {
    background: #002132;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}
.air-drop-drawer .drawer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.air-drop-drawer .title {
   font-size: 19px;
   color: #fff;
   font-weight: 700;
}
.air-drop-drawer .close-btn {
  background: url('../src/imgs/airdrop_close.webp') center no-repeat;
  background-size: contain;
  display: inline-block;
  width: 16px;
  height: 16px;
  cursor: pointer;
}
.air-drop-drawer .content-wrapper {
}

.air-drop-drawer .banner-skeleton {
  display: block;
  width: 100%;
  height: 180px;
}
.air-drop-body .banner-skeleton {
  display: block;
  width: 100%;
  height: 3.5rem;
}


.content-container .air-rich-text {
  white-space: break-spaces!important;
  color: #fff;
  font-weight: 600;
}
.content-container .air-rich-text span {
  background: unset!important;
  color: unset!important;
  font-size: unset!important;
}
.content-container .air-rich-text h3 {
  font-size: .3rem;
  color: #fff;
}
.content-container .air-rich-text h4 {
  font-size: .26rem;
  color: #fff;
  margin-top: .2rem;
  margin-bottom: 0.05rem;
}
.content-container .air-rich-text p {
  font-size: .26rem;
  color: #A8B8C0;
  margin-bottom: 0rem;
  min-height: .3rem;
}
.content-container .air-rich-text ul {
  background: #0a364d;
  margin-top: .4rem;
  padding: .2rem;
  border-radius: .1rem;

}
.content-container .air-rich-text  li {
  color: #A8B8C0;
  font-size: .24rem;
}
.content-container .air-rich-text a {
  color: #fff;
  text-decoration: underline;
}

.content-container .air-rich-text-pc {
  white-space: break-spaces!important;
}
.content-container .air-rich-text-pc h3 {
  font-size: 15px;
  color: #fff;
  font-weight: 600;
}
.content-container .air-rich-text-pc h4 {
  font-size: 13px;
  color: #fff;
  margin-bottom: 15px;
}
.content-container .air-rich-text-pc p {
  font-size: 13px;
  color: #A8B8C0;
  margin-bottom: 5px;
  min-height: 8px;
}
.content-container .air-rich-text-pc ul {
  background: #0a364d;
  margin-top: 20px;
  padding: 10px;
  border-radius: 5px;

}
.content-container .air-rich-text-pc  li {
  color: #A8B8C0;
  font-size: 12px;
}
.content-container .air-rich-text-pc a {
  color: #fff;
  text-decoration: underline;
}






@media only screen and (max-width: 820px) {

  .particle-wallet-entry-container .particle-pwe-btn {
    width: 47.9px!important;
    height: 47.9px!important;
    right: .2rem!important;
    bottom: 297px!important;
    left: unset!important;
    top: unset!important;
  }
  .earn-show-arrow-wrapper {
    background: linear-gradient(180deg, rgb(6, 32, 52) 0%, rgba(6,38,58,1) 100%);
    opacity: 0.73;
    width: 100%;
    height: .6rem;
    line-height: .6rem;
    padding-bottom: .05rem;
    border-bottom-left-radius: .2rem;
    border-bottom-right-radius: .2rem;
    margin-top: .1rem;
    font-size: .30rem;
  }
  .earn-show-arrow-wrapper .earn-show-arrow {
    margin: 0;
  }
  .config-form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .config-form .ant-form-item {
    /* width: 260px; */
    width: 100%!important;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .config-form .ant-input {
    height: 40px;
    color: #fff!important;
    border-color: #1A5159;
  }
  .config-form .ant-input:hover {
    border-color: #1B767C;
  }
  .config-form .ant-select-selector {
    height: 40px!important;
    background-color: unset!important;
    border-color: #1A5159!important;
  }
  .config-form .ant-select-selection-search-input {
    height: 40px!important;
  }
  .config-form .ant-select-selection-item {
    line-height: 40px!important;
    color: #fff;
  }
  .config-form .ant-form-item-label > label {
    height: .3rem;
    color: #fff;
  }
  .config-form .ant-select-arrow {
    color: #fff;
  }

  .clear-rich-text{

  }
  .clear-rich-text span{
    background-color: unset !important;
  }

  .clear-rich-text p{
    color: #FFFFFF !important;

  }

  .mobile-list-skeleton {
    margin-bottom: .2rem;
    opacity: .1;

  }
  .mobile-list-skeleton .ant-skeleton-avatar {
    width: 2rem;
    height: 2rem;
    border-radius: unset!important;
  }

  .mobile-list-skeleton-column-2 {
    width: 50%;
    display: flex;
    flex-direction: column;
    opacity: .1;
    margin-bottom: .2rem;
    padding: 0 .1rem 0 .1rem;

  }
  .mobile-list-skeleton-column-2 .ant-skeleton-header {
    width: 100%;
  }
  .mobile-list-skeleton-column-2 .ant-skeleton-header .ant-skeleton-avatar {
    border-radius: 0;
    width: 100%;
    height: 3.5rem;
  }

  .home-banner-img {
    height: 3.5rem;
  }



  .nav-bar{
    display: none;
  }
  .nav-bar-mobile {
    background: rgba(30,30,30);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    padding: .1rem .2rem .1rem .2rem;
    display: block;
    min-height: .85rem;
    max-height: 1.7rem;
    display: flex;
    align-items: center;
    display: none;
  }
  .nav-bar-mobile .nav-bar-wrapper {
    transition: all .4s ease-in-out;
    width: 100%;

  }
  .nav-bar-mobile .header-search-container {
    position: relative;
  }
  .header-search-container .search-mask {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,.7);
    z-index: -1;
  }
  .nav-bar-mobile .header-search-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #fff;
    border-radius: 100px;
    padding: 0 10px 0 0;
    margin-top:10px;
  }
  .nav-bar-mobile .header-search-wrapper-search {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    border: 1px solid #516167;
  }
  .header-search-wrapper .search-icon {
    width: .3rem;
  }
  .header-search-wrapper .search-input {
    border: none;
    padding:  4px 0 4px 15px;
    font-size: 15px;
  }
  .header-search-wrapper .search-input:focus {
    box-shadow: none;
  }
  .nav-bar-mobile .search-game-list {
    position: absolute;
    height: 3rem;
    width: 100%;
    background: rgba(0,0,0,0.8);
    border: 1px solid #516167;
    border-top: none;
    opacity: 1;
    overflow: scroll;
    transition: all .2s ease-in-out;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }
  .nav-bar-mobile .search-game-list-hide {
    opacity: 0;
    /* display: none; */
    height: 0;
  }
  .search-game-list-hide .search-list {
    height: 0;
  }
  .nav-bar-mobile .search-list-m {
    width: 100%;
  }
  .nav-bar-mobile .search-list-m .game-name {
    margin-top: 0;
    padding: 3px;
  }
  .nav-bar-mobile .search-list-m .no-results {
    display: block;
    text-align: center;
  }

  .nav-bar-mobile .nav-bar-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav-bar-mobile .nav-bar-flex .app-download-icon{
    background: url("https://cdn.igamie.com/static/appdownload-logo.jpg") no-repeat;
    width: 37px;
    height: 37px;
    border-radius:10px;
    background-size: cover;
    margin-top: 5px;
  }

  .nav-bar-mobile .nav-bar-flex .app-download-title{
    color: #FFFFFF;
    font-size: .25rem;
    font-weight: 600;
  }
  .nav-bar-mobile .nav-bar-flex .app-download-desc{
    color: #666666;
    font-size: .22rem;
    font-weight: 500;
  }


  .nav-bar-mobile-new .nav-bar-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav-bar-mobile-new .nav-bar-flex .app-download-icon{
    background: url("https://cdn.igamie.com/static/appdownload-logo.jpg") no-repeat;
    width: 37px;
    height: 37px;
    border-radius:10px;
    background-size: cover;
    margin-top: 5px;
  }

  .nav-bar-mobile-new .nav-bar-flex .app-download-title{
    color: #FFFFFF;
    font-size: .25rem;
    font-weight: 600;
  }
  .nav-bar-mobile-new .nav-bar-flex .app-download-desc{
    color: #666666;
    font-size: .22rem;
    font-weight: 500;
  }

  .nav-bar-mobile-new-hid .nav-bar-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .nav-bar-mobile-new-hid .nav-bar-flex .app-download-icon{
    background: url("https://cdn.igamie.com/static/appdownload-logo.jpg") no-repeat;
    width: 37px;
    height: 37px;
    border-radius:10px;
    background-size: cover;
    margin-top: 5px;
  }

  .nav-bar-mobile-new-hid .nav-bar-flex .app-download-title{
    color: #FFFFFF;
    font-size: .25rem;
    font-weight: 600;
  }
  .nav-bar-mobile-new-hid .nav-bar-flex .app-download-desc{
    color: #666666;
    font-size: .22rem;
    font-weight: 500;
  }





  .nav-bar-flex .back-icon {
    width: .4rem!important;
  }
  .nav-bar-flex .logo-img {
    width: 1rem;
  }
  .nav-bar-flex .nav-bar-right {
    color: #fff;
    display: flex;
    align-items: center;
  }
  .nav-bar-flex .nav-bar-item {
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    text-decoration: none;
  }
  .nav-bar-flex .nav-bar-item-active {
    color: #D0A62C;
  }
  .order-poppver {

  }
  .ant-popover-placement-bottomRight .ant-popover-arrow-content {
    background: rgba(1,17,24,.6);
  }
  .ant-popover-placement-bottomRight  .ant-popover-inner{
    background: rgba(1,17,24,.6);
  }
  .ant-popover-inner .anticon-close {
    color: #fff;
    position: absolute;
    right: 6px;
    top: 10px;
  }


  .nav-bar-right .search-icon {
    /* font-size: .48rem; */
    margin-right: .3rem;
    /* width: 20px; */
    width: 18px;
    height: 19px;
  }
  .nav-bar-right .ant-badge {
    color: unset;
  }

  .nav-bar-wrapper .nav-bar-search {
    /* margin-top: .1rem; */
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: all .1s ease-in-out;
    position: absolute;
    top: 0;

  }
  .nav-bar-wrapper .nav-bar-search-show {
    opacity: 1;
    /* height: 1rem; */
    top: 1rem;
    background-color: rgba(0, 0, 0, .8);
    left: 0;
    right: 0;
    overflow: hidden;
    padding: 0 .4rem 0 .4rem;


  }
  .nav-bar-search .ant-input {
    background-color: rgba(0,0,0,0);
  }
  .nav-bar-search .ant-input:focus,
  .nav-bar-search .ant-input:hover {
    box-shadow: none;
    border-color: #fff;
    color: #fff;
  }

  .game-table-ranking .ant-pagination-prev {
    vertical-align: unset;
    margin-top: -0.02rem;
  }
  .game-table-ranking .ant-pagination-next {
    vertical-align: unset;
  }
  .game-table-ranking .ant-pagination-item {
    width: .7rem;
    height: .7rem;
    line-height: .7rem;
  }



  .goto-prev-page {
    display: block;
    position: absolute;
    top: .5rem;
    left: .2rem;
    z-index: 999;
    /* border-radius: 50%; */
    /* background-color: rgba(0,0,0,0.7); */
    padding: .1rem;
  }
  .goto-prev-page .back-icon {
    width: .4rem;
  }
  .goto-prev-page-hide{
    display: none;
  }


  .app-footer {


  }
  .order-com-foot {
    display: none;
  }
  .app-footer .footer-wrapper-1 {
     margin-top: 0;
  }

  .app-footer .footer-bo{
    width: unset;
    flex-direction: column;
    align-items:flex-start;
    padding: .2rem .5rem .4rem .5rem;
  }
  .footer-bo .region {
    display: flex;
    align-items: center;

  }
  .footer-bo .payment-supported {
    margin: .2rem 0 .4rem 0;
  }
  .payment-supported .footer-t-name {
  }

  .app-footer .footer-wrapper-2{
    width: 100%;
    /* flex-direction: column; */
    flex-direction: row;
    font-size: .16rem;
    padding: .2rem;
  }

  .footer-wrapper-1 .Follow-us {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .footer-wrapper-1 .footer-t-name {
    font-size: .25rem;
    margin-bottom: .12rem;
  }

  .footer-wrapper-1 .footer-bo-new {
    width: 100%;
    padding: .3rem 0 .3rem 0;
    justify-content: center;
  }
  .footer-wrapper-1 .footer-bo-new-logo {
    padding: 0rem 0 .1rem 0;
  }



  .footer-wrapper-1 .footer-bo-new-2 {
    display: block;
    padding-top: .3rem;
  }
  .footer-wrapper-1 .right-new {
    margin-top: .35rem;
    margin-bottom: .1rem;
    display: block;
  }

  .footer-wrapper-1 .right-new .footer-join-new {
    color: #fff;
    text-align: center;
    font-size: .26rem;
    color: rgba(255, 255, 255, 0.73);
  }

  .footer-wrapper-1 .left .footer-logo-img {
    width: 1.7rem;
  }
  .footer-bo-new .zendesk-link {
    font-size: .26rem;
    justify-content: space-around;
  }
  .footer-bo-new .zendesk-link-new .zendesk-line-1 {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 0 .5rem;
     margin-bottom: .35rem;
  }
  .footer-bo-new .zendesk-link-new .zendesk-line-2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 .5rem;
    margin-bottom: 0rem;
 }
  .footer-bo-new .zendesk-link-new  a {
    color: #fff;
  }

  .footer-bo-new .right {
    margin-top: .13rem;

  }
  .footer-bo-new .right .footer-join {
    font-size: .26rem;
    display: none;
  }
  .footer-wrapper-1 .zendesk-link {
    display: flex;
    /* flex-direction: column; */
    align-items: center;
    /* flex-direction: column; */
  }

  .footer-wrapper-1 .zendesk-link a {
    /* margin-right: unset; */
    margin-right: .14rem;
    margin-left: .14rem;
  }

  .footer-bo-new .footer-commu {
    justify-content: center;
  }

  .footer-bo-new .footer-commu img {
    margin-left:  0;
    margin-right: 0;
    width: .6rem;
  }
  .footer-bo-new .footer-commu .foot-discord-logo {
    /* margin-right: 0; */
    margin: 0 .5rem;
  }



  .sk-ma-m {
    opacity: .1;
  }
  .sk-ma-m .ant-skeleton-content .ant-skeleton-title {
    margin-top: 0;
  }
  .sk-ma-m .ant-skeleton-content .ant-skeleton-paragraph {
    margin-top: 10px!important;
  }
  .display-block {
    display: unset;
  }
  .display-none {
    display: none;
  }
  .big-z-sk .ant-skeleton-avatar {
    /* width: 259px; */
    height: 289px;
    width: 6.7rem;
    border-radius: unset !important;
  }







  .language-select .nav-header-d {
    opacity: 1;
    height: auto;
    visibility: visible;
    -webkit-transition: all .1s ease-in-out;
    -o-transition: all .1s ease-in-out;
    transition: all .1s ease-in-out;
    background: #2a2a2a;
  }



  /**
 *  language seelct
 **/
  .select-container {
    display: inline-block;
    padding: 10px 18px;
    position: relative;
    cursor: pointer;
  }

  .user-avatar-wrapper {
    padding: 10px 30px;
  }



  .select-container-regions {
    padding-right: 0;
    position: relative;
    display: flex;
    align-items: center;
  }


  .select-container span {
    font-size: .25rem;
    font-weight: 600;
  }

  .select-container .language-select-item span {
    font-size: 13px;
  }

  .select-container img {
    width: 14px;
    margin-left: 4px;
  }

  .select-container .language-icon {
    vertical-align: text-top;
    margin-right: 10px;
  }

  .select-container .user-icon {
    font-size: 20px;

  }
  .select-container:hover .selectTri {
    transform: unset;
    transition: unset;
  }

  .select-container .hi-nickname {
    vertical-align: middle;
    margin-left: 5px;
  }

  .select-container .selectTri {
    /* transform: translateY(-44%); */
    margin-left: 7px;
    vertical-align: revert;
    width: 11px;
  }



  .select-container .selectTri-s {
    transform: rotate(180deg);
    transition: all .2s;
  }

  .select-container .selectTri-h {
    transform: rotate(0deg);
    transition: all .2s;
  }

  .select-container .language-menu {
    /* width: 137px; */
    /* min-width: 180px; */
    /* background-color: #fff; */
    background-color: rgb(31, 47, 56);
    position: absolute;
    /* color: #333; */
    color: #ccc;
    top: 50px;
    /* right: 16px; */
    z-index: 9999;
    box-shadow: 0 12px 60px rgb(14 32 66 / 15%),
      0 1px 2px rgb(14 32 66 / 5%);
    /* padding: 5px 10px 5px 10px; */
    opacity: 0;
    transition: all .5s;
    height: 0px;
    overflow: hidden;
    left: 50%;
    transform: translateX(-50%);
  }

  .select-container:hover .language-menu {
    opacity: 1;
    /* margin-top: 10px; */
    top: 47px;
    /* top: 0; */
    height: unset;
  }

  .select-container-regions .language-menu {
    position: absolute;
    right: 0;
    transform: unset;
    -webkit-transform: unset;
    left: unset;
    width: 124px;

  }


  .select-container select {
    border: none;
    background-color: rgb(234, 232, 232);
    position: auto;
    height: 100%;
    padding: 8px 35px;
    appearance: none;
    -moz-appearance: none;
    text-align: center;
    -webkit-appearance: none;
    transform: scale(.93);
  }

  .select-container .language-menu-show {
    opacity: 1;
    height: unset;
  }

  /* .select-container .language-menu-hidden {
  height: 0px;
  opacity: 0;
} */
  .select-container .language-menu-hidden {
    height: 0px !important;
    opacity: 0px !important;
  }

  .language-menu .language-select-item {
    color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 14px 0 14px;
    line-height: 34px;
    position: relative;
  }

  .select-container .language-menu .language-select-item:hover {
    /* color: #f79c37; */
    /* background-color: #fbf2ee; */
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
  }

  .load-more-wr {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .load-more-wr .load-more-sp {
    color: #fff;
    font-size: 15px;
    font-weight: 500;
    padding: 12px 24px;
    /* background: linear-gradient(90deg, #f79c37, #f0774a); */
    background-image: linear-gradient(270deg, #93741e 0%, #c59d2a 50%, #93741e 100%);
    border-radius: 100px;
    display: inline-block;
    cursor: pointer;
  }

  .Follow-us .contact-us {
    margin-right: unset;
  }

  .shot-game-item-1 {
    padding-left: 0!important;
  }
  .shot-game-item-3 {
    padding-right: 0!important;
  }

  .dialog-img {
    height: 5rem;
    object-fit: cover;
  }

  #launcher {
    right: .3rem!important;
    bottom: 40px!important;
    margin-right: 0!important;
  }
  .ant-back-top {
   bottom: 120px;
   right: 0.32rem;
 }

 .ant-picker-panels {
   flex-direction: column;
 }
 .my-coins .ant-picker-clear {
  /* color: #fff; */
    color: #fff;
    background: unset;
    right: -0.8rem;
 }

 .profile-page .pi-form .ant-input {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    box-shadow: none;
    color: #fff;
    font-size: .3rem;
    padding-left: 0;
  }
  .profile-page .pi-form .ant-form .ant-form-item .ant-form-item-label,
  .profile-page .ant-form .ant-form-item .ant-form-item-control{
      flex: none;
  }
  .profile-page .pi-form .ant-radio-inner {
     background: rgba(0, 0, 0, 0);
  }
  .profile-page .pi-form .ant-radio-checked .ant-radio-inner {
      border: 1px solid #fff;
  }
  .profile-page .pi-form .ant-form-item-label{
    width: 3rem;
    padding: unset;
    white-space: nowrap;
    flex: none;
  }
  .profile-page .pi-form .ant-form-item-label>label {
    color: #fff;
    font-size: .28rem;
  }
  .profile-page .pi-form .ant-form-item-control{
    font-size: .28rem;
    color:#9DA9AE;
  }
  .profile-page .pi-form .ant-form-item {
    color: #fff;
  }

  .profile-page .new-style-select {
    color: #728189;
    direction: rtl;
    padding-right: .33rem;
  }

  .share-modal {

  }

  .share-modal .ant-modal-content {
    background: url('https://cdn.igamie.com/static/ic_fx_bg@2x.png') center no-repeat;
    background-size: contain;
    width: 100%;
    height: 100%;
    box-shadow: none;
  }

  .share-modal .ant-modal-body {
    height: 100%;
    padding: 1.4rem .3rem 0 .3rem;
  }
  .share-modal .share-button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .share-modal .share-button-wrapper svg {
    border-radius: 5px;
  }
  .share-modal .copy-link-txt {
    color: #47301F;
    margin-top: 10px;
    margin-left: .35rem;
  }
  .share-modal .copy-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 .35rem;
  }
  .share-modal  .copy-link {
    border-radius: 4px;
    border: 1px solid #CC9F58;
    width: 250px;
    height: 40px;
    line-height: 40px;
    overflow-x: scroll;
    white-space: nowrap;

  }
  .share-modal  .copy-btn {
    width: 50px;
    height: 50px;
  }
  .black-modal .preview-header {
    width: 2rem;
  }

  .back-icon-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    /* background-color: rgba(47,47,47,.8); */
    z-index: 999;
    display: block;
    padding:  .225rem .35rem .225rem .35rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* border-bottom: 1px solid #282c34;
    box-shadow: 0 4px 10px rgb(0 0 0 / 24%); */
  }
  .login-nav-wrapper {
    background-color: unset;
    padding-top: .35rem;
  }
  .back-icon {
    width: .35rem;
    height: .35rem;
    background: url('../src/imgs/back_icon.png') center no-repeat;
    background-size: contain;
    /* position: absolute; */
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .back-icon-img {
    width: 0.17rem;
    height: .25rem;
  }
  .mobile-nav-right {
    display: flex;
    align-items: center;
  }

  .mobile-nav-right .igm-icon, .mobile-nav-right .points-icon {
     width: .5rem;
     height: .5rem;
  }
  .mobile-nav-right .igm-number {
    /*width: .8rem;*/
    font-weight: 600;
  }
  .mobile-nav-right .header-igm-box {
    background: #54593394;
    height: unset;
    padding: .03rem .04rem .03rem 0;
    margin-left: unset;
  }

  .mobile-nav-right .igm-number {
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
    width: unset;
    min-width: .7rem;
    text-align: center;
    padding: 0 .2rem;
    /*padding-right: 0.05rem;*/
  }

  .user-logout-wrapper {
    height: .7rem;
    padding: 0 .25rem;
  }
  .user-logout-wrapper .user-logout-txt {
    font-size: .28rem;
    white-space: nowrap;
    font-weight: 500;
    margin: 0 2px;
    transform: scale(.85);

  }



  .back-icon-wrapper .ant-dropdown-trigger {
    width: .6rem;
    height: .6rem;
    background: url('../src/imgs/user-avatar-default.svg') center no-repeat;
    background-size: contain;
    display: inline-block;
    /* background: url('../src/imgs/left.svg') center no-repeat; */
    background-size: contain;
    display: block;
    /* position: absolute; */
    z-index: 9999;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .back-icon-wrapper .user-icon {
    color: #fff;
    font-size: .38rem;


    width: 0.6rem;
    height: 0.6rem;
    background: url('../src/imgs/user-avatar-default.svg') center no-repeat;
    background-size: contain;
    display: inline-block;
    /* background: url(../src/imgs/left.svg) center no-repeat; */
    background-size: contain;
    display: block;
    /* position: absolute; */
    z-index: 9999;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gamiezone-avatar {
    width: .5rem;
    height: .5rem;
    background: url('../src/imgs/header.svg') center no-repeat;
    background-size: cover;
    display: inline-block;
  }

  .ant-tooltip-inner {
    max-width: 7rem;

  }
  .header-coins {
    width: .68rem;
    height: .68rem;
  }

  .coins-notification {
    background: #17494D;
    border:1px solid #689397;
    border-radius: 100px;
    padding: .04rem .3rem .04rem .04rem;
    width: unset;
  }

  .coins-notification .content-des {
     font-size: .26rem;
     color: #fff;
     font-weight: 600;
     margin-left: 4px;

  }

  .coins-notification .coins-num {
    font-size: .26rem;
    color: #d4a82b;
    font-weight: 600;

  }


  .coins-notification .coins-content {
    display: flex;
    align-items: center;
  }

  .coins-notification .ant-notification-notice-message {
    margin-bottom: 0;
  }

  .coins-notification .coin-icon {
    width: .7rem;
    margin-right: .1rem;
  }



  .coins-notification .ant-notification-notice-close {
    display: none;
  }

  .coins-notice-wrapper .coin-icon {
    left: 40%;
    top: 60%;
  }

  .login-page .ant-input:hover, .login-page .ant-input:focus {
    box-shadow: none;
  }
  .login-page .ant-input, .login-page .ant-input-password .ant-input {
    box-sizing: none;
    border: 1px solid #095359;
  }
  .login-page .ant-input-password .ant-input {
    border: none;
  }
  .login-page .ant-input-password {
  }

  .earn-coins-hi {
    max-height: 3.5rem;
    overflow: hidden;
  }
  .play-to-earn-hi {
    height: auto;
    overflow: hidden;
  }

  .earn-coins-show {
    height: auto;
  }
  .play-to-earn-show {
    height: auto;
  }
  .earn-show-arrow {
    /*margin-bottom: .2rem;*/
    width: .35rem;
    height: .25rem;
    margin-top: .4rem;
    margin-left: .25rem !important;
  }
  .earn-show-arrow-show {
    margin-bottom: .14rem;
    width: .35rem;
    height: .25rem;
    margin-top: .2rem;
    transform: rotate(180deg);
    transition: all .2s;

  }

  .blindbox-rule-modal {

  }
  .blindbox-rule-modal .ant-modal-content {
     height: 10rem;
  }
  .blindbox-rule-modal .ant-modal-body {
    padding: 1.3rem .7rem 0 .7rem;
  }
  .blindbox-rule-modal .ant-modal-body .rule-wrapper {
    height: 8rem;
    overflow-y: scroll;
  }
  .blindbox-rule-modal .ant-modal-body p {
    font-size: .3rem;
  }

  .rate-icon-wrapper .ant-rate-star {
    font-size: 12px;
    margin-right: 6px;
  }




  .mobile-login-menu-new > * {
    transition: transform .08s ease!important;
  }

  .mobile-login-menu-new .ant-drawer-content-wrapper {
    width: 7rem!important;
    z-index: 9999;
  }
  .mobile-login-menu-new-aside .ant-drawer-content-wrapper {
    width: unset!important;
    z-index: 9999;
  }


  .mobile-login-menu-new .ant-drawer-content{
    background: #011118;
    font-weight: 600;
    /* background: #001529; */
  }
  .mobile-login-menu-new .anticon-close {
    color: #fff;
  }
  .mobile-login-menu-new .ant-drawer-body {
    padding: .36rem .36rem .36rem .54rem;
  }

  .mobile-login-menu-new .ant-drawer-body .ant-layout-sider-children {
    width: 5rem;
  }


  .mobile-menu-user-header {

  }
  .mobile-menu-header-close-language-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
    font-size: .45rem;
  }

  .mobile-menu-header-close-language-wrap .language-select-co {
    position: absolute;
    top: 0;
    right:0;
    bottom: 0;
    left: 0;
    opacity: 0;
  }

  .mobile-menu-header-close-language-wrap .language-wrap{
    display: flex;
    align-items: center;
    font-size: .25rem;
    height: .65rem;
    padding: .1rem .2rem;
    background: rgba(255, 255, 255, 0.14);
    border-top-left-radius: .4rem;
    border-bottom-left-radius: .4rem;
    margin-right: -.36rem;
    position: relative;
  }
  .language-wrap .language-icon{
    width: .45rem;
    height: .45rem;
    background: url('../src/imgs/language-global.svg') center no-repeat;
    background-size: contain;
    display: inline-block;
    margin-right: .15rem;
  }
  .mobile-menu-user-header .user-avatar-wrap{
    position: relative;
  }

  .mobile-menu-user-header .user-avatar-wrap .user-avatar {
    width: 1.69rem;
    height: 1.69rem;
    border-radius: 50%;
    border: 1px solid #FFFFFF;
  }
  .mobile-menu-user-header .user-avatar-wrap .head_pic_edit{
    background: url("../src/imgs/me_account/edit_head_pic_icon.png") no-repeat;
    width: .6rem;
    height: .6rem;
    background-size: contain;
    position: absolute;
    right: 2.2rem;
    bottom: -.1rem;
  }
  .mobile-menu-user-header .user-name {
    font-size: .4rem;
    padding-top: .1rem;
    color: #fff;
    text-align: center;
  }
  .mobile-menu-user-header .user-email {
    font-size: .28rem;
    color: #5C676C;
    text-align: center;
  }

  .mobile-menu-wallet-bind-wrap{
    display: flex;
    justify-content: center;
    margin: .3rem 0;
  }

  .mobile-menu-wallet-bind-wrap  .connect-wallet-btn{
    background: url("../src/imgs/me_account/connect_wallet_icon.png") no-repeat;
    background-size: contain;
    width: 2.55rem;
    height: .63rem;
  }

  .mobile-menu-wallet-bind-wrap .user-wallet-address-wrap{
    width: 4.7rem;
    height: .75rem;
    background: rgba(255, 255, 255, 0.14);
    border-radius: 24px;
    display: flex;
    justify-content: center;
    color: #FFFFFF;
    font-weight: 600;
    align-items: center;
  }

  .mobile-menu-wallet-bind-wrap .user-wallet-address-wrap   .wallet-success-icon {
    background: url('../src/imgs/coins_di_check.png')  no-repeat;
    background-size: contain;
    width: .35rem;
    height: .3rem;
    margin: unset;
    margin-right: .12rem;
  }

  .mobile-menu-wallet-bind-wrap .user-wallet-address-wrap   .copy-btn {
    background: url('../src/imgs/me_account/copy_icon.png')  no-repeat;
    background-size: contain;
    width: .4rem;
    height: .3rem;
    margin: unset;
    margin-left: .15rem;
  }


  .mobile-menu-coins-wrapper {
    background: #1A282F;
    border-radius: .17rem;
    padding: .3rem;
    margin-top: .3rem;
    font-size: .43rem;
    color: #FFFFFF;
    font-weight: bold;
    text-align: center;
  }
  .mobile-menu-coins-section-title{
    font-weight: 500;
    color: #AEB5B8;
    font-size: .3rem;
    margin: .4rem 0 .25rem 0;
  }

  .mobile-menu-coins-my-wallet{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .mobile-menu-coins-my-wallet .my-wallet-item{
    width: calc(50% - 0.13rem);
    background: #1A282F;
    border-radius: .36rem;
    padding: .26rem;
    margin-right: .26rem;
    margin-bottom: .26rem;
  }
  .mobile-menu-coins-my-wallet .my-wallet-item:nth-child(2n){
    margin-right: 0;
  }
  .mobile-menu-coins-my-wallet .my-wallet-item .flex-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mobile-menu-coins-my-wallet .my-wallet-item .title{
    color: #FFFFFF;
    font-weight: 600;
    font-size: .31rem;
  }
  .mobile-menu-coins-my-wallet .my-wallet-item .desc{
    color: #AEB5B8;
    font-weight: 400;
    font-size: .22rem;
  }
  .mobile-menu-coins-my-wallet .my-wallet-item .flex-wrap .my-wallet-icon{
    width: .8rem;
    height: .8rem;
    background-size: contain !important;
  }
  .mobile-menu-coins-my-wallet .my-wallet-item .flex-wrap .voucher-icon{
    background: url("../src/imgs/me_account/voucher_icon.png") no-repeat;
  }
  .mobile-menu-coins-my-wallet .my-wallet-item .flex-wrap .exclusive-icon{
    background: url("../src/imgs/me_account/exclusive_cards.png") no-repeat;
  }
  .mobile-menu-coins-my-wallet .my-wallet-item .flex-wrap .gamecode-icon{
    background: url("../src/imgs/me_account/game_code.png") no-repeat;
  }
  .mobile-menu-coins-my-wallet .my-wallet-item .flex-wrap .nft-icon{
    background: url("../src/imgs/me_account/nft_icon.png") no-repeat;
  }

  .mobile-menu-coins-my-wallet .my-wallet-item .flex-wrap .my-wallet-nums{
    width: .8rem;
    height: .5rem;
    font-size: .25rem;
    text-align: center;
    line-height: .5rem;
    color: #FFFFFF;
    font-weight: 500;
    border-radius: .3rem;
    background: rgba(255, 255, 255, 0.11) !important;
  }

  .mobile-menu-coins-wrapper .coins-title {
    font-size: .28rem;
    color: #FFFFFF;
    font-weight: 600;
    padding: .05rem 0;
    width: 1.7rem;
    border-radius: 3px;
    background: linear-gradient(90deg, #016161 0%, #003D61 100%);
  }
  .redeem-btn{
    background: linear-gradient(90deg, #016161 0%, #003D61 100%);
  }
  .mobile-menu-coins-wrapper .coins-footer {
    margin-top: .15rem;
    display: flex;
    justify-content: center;
  }
  .mobile-menu-coins-wrapper .left {
     font-size: .4rem;
     color: #fff;
  }
  .mobile-menu-coins-wrapper .right {
    color: rgba(255, 255, 255, 0.65);
  }
  .mobile-menu-coins-wrapper .menu-next {
     background: url('../src/imgs/menu_next.svg') center no-repeat;
     background-size: contain;
     width: .2rem;
     height: .2rem;
     display: inline-block;
     vertical-align: middle;
     opacity:.65;
  }
  .mobile-menu-list {
    background: #1A282F;
    border-radius: .3rem;
    padding:0 .26rem;
  }

  .mobile-menu-list .mobile-menu-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .3rem 0 .3rem 0;
    border-bottom: 0.5px solid #33424B;
  }
  .mobile-menu-list .mobile-menu-item:last-child{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .26rem 0 .26rem 0;
    border-bottom: none;
  }

  .mobile-login-menu-new .mobile-menu-language-container {
    position: relative;
    margin-top: .7rem;
  }
  .mobile-menu-language-container .language-select {
    width: 3.7rem;
    height: .9rem;
    background: linear-gradient(122deg, #00fde945 0%, #0095fa45 100%);
    border-radius: 100px;
    /* opacity: 0.27; */
    display: block;
    margin: .2rem auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 .8rem;
  }
  .mobile-menu-language-container .language-select-co {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 3.7rem;
    opacity: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  .mobile-menu-language-container .language-global {
    width: .4rem;
    height: .4rem;
    background: url('../src/imgs/language-global.svg') center no-repeat;
    background-size: contain;
    display: inline-block;
  }
  .mobile-menu-language-container .language-region {
    font-size: .3rem;
    color: #fff;
    margin-left: .2rem;
  }


  .mobile-menu-item .menu-left-item {
    font-size: .31rem;
    color: #fff;
  }
  .mobile-menu-item .menu-right-btn {
    background: url('../src/imgs/menu_next.svg') center no-repeat;
    background-size: contain;
    width: .27rem;
    height: .27rem;
    display: inline-block;
  }



  .nav-bar-mobile-new {
      /* background: rgba(30,30,30); */
      /*background: linear-gradient(180deg, #000018 0%, #0E212B 100%);*/
      background: #011118;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      /* height: 2rem; */
      font-weight: 600;
      padding: 0;
      display: block;
  }
  .nav-bar-mobile-new-hid {
      background: #000018;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 999;
      /* height: 2rem; */
      font-weight: 600;
      padding: 0;
      display: block;
  }

  .mobile-header-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .15rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  }
  .mobile-header-logo {
    background: url('https://cdn.igamie.com/static/footer_logo.png') center no-repeat;
    background-size: contain;
    width: 1.4rem;
    height: .48rem;
    display: block;
  }
  .mobile-header-top-left {
    display: flex;
    align-items: center;
    flex: 1;
    /* padding: 0 .2rem 0 0; */
  }
  .mobile-header-top-right {
    display: flex;
    align-items: center;

  }
  .mobile-header-top-right .user-icon {
    width: .6rem;
    height: .6rem;
    background: url('../src/imgs/user-avatar-default.svg') center no-repeat;
    background-size: contain;
    display: inline-block;
  }
  .mobile-header-top-right .mobile-notice-icon {
    width: .6rem;
    height: .6rem;
    background: url('../src/imgs/mobile-notice.svg') center no-repeat;
    background-size: contain;
    display: inline-block;
  }
  .mobile-header-top-right .mobile-igm-icon {
    width: .6rem;
    height: .6rem;
    background: url('../src/imgs/igm_modal/igm_icon.webp') center no-repeat;
    background-size: contain;
    display: inline-block;
  }
  .mobile-header-top-right .header-coins-mobile {
    background: url('https://cdn.igamie.com/static/coins_1.svg') center no-repeat;
    background-size: contain;
    width: .6rem;
    height: .6rem;
    display: inline-block;
    /* margin-left: 28px; */
    /* margin-right: 17px; */
    cursor: pointer;
    position: relative;
    z-index: 199;
  }
  .mobile-header-top-right .notice-popover-i-mobile {
    margin: 0 .1rem;
    display: flex;
  }
  .mobile-header-input {
    width: 100%;
    height: .6rem;
    margin-left: .2rem;
    border-radius: 100px;
    background: #d8d8d81a;
    position: relative;
  }
  .mobile-header-search {
    background: url('https://cdn.igamie.com/static/ic_search@3x.png')center no-repeat;
    width: .3rem;
    height: .3rem;
    display: inline-block;
    background-size: contain;
    position: absolute;
    right: .2rem;
    top: 50%;
    transform: translateY(-50%);

  }


  .mobile-header-menu-m {
    position: relative;
    padding: .1rem .2rem .08rem .2rem;
  }
  .mobile-menu-wrapper {
    display: flex;
    width: 100%;
    overflow-x: scroll;
    /* justify-content: space-between; */
    align-items: center;
    /* margin-top: 20px; */
    overflow: scroll;
  }
  .mobile-menu-ii {
    /* width: 1rem; */
    color: #fff;
    margin-right: .6rem;
    white-space: nowrap;
    font-size: .26rem;
  }
  .mobile-menu-ii-active {
    color: #D0A62C;
    margin-right: .6rem;
    white-space: nowrap;
    font-size: .3rem;
  }
  .mobile-menu-arrow-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: .8rem;
    background: rgba(90,90,90,.1);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile-menu-arrow {
    background: url('../src/imgs/menu_arrow.svg') center no-repeat;
    background-size: contain;
    width: .23rem;
    height: .23rem;
    display: inline-block;
  }



  .coins-app-modal {
    width: 6rem!important;
    height: 7.8rem!important;
 }
 .coins-app-modal .introduction {
   font-size: .3rem;
   margin: 3.8rem auto 0;
 }
 .coins-app-modal .get-app-btn {
   width: 2.6rem;
   height: .7rem;
   line-height: .7rem;
   font-size: .3rem;
 }
}

.modal-voucher-contaniner-bg {
  background: url("../src/imgs/modalVoucher/tc_bg_02@2x.png") center no-repeat;
  display: flex;
  flex-direction: column;
  background-size: contain;
  align-items: center;
  width: 5.2rem;
  /*width: 400px;*/
  /*height: 638px;*/
  height: 7.3rem;
}
.modal-voucher-title-bg {
  background-size: contain;
  width: 5.0rem;
  /*height: 60px;*/
  height: .35rem;
  /*margin-top: 200px;*/
  margin-top: 2.5rem;
  margin-bottom: .2rem;
}
.modal-voucher-bg {
  background: url("../src/imgs/modalVoucher/vou_bg.webp") center no-repeat;
  background-size: contain;
  /*width: 280px;*/
  width: 6.0rem;
  /*height: 160px;*/
  height: 1.8rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: black;
  /*padding: 10px;*/
  padding: .1rem;
  padding-top: .16rem;
}
.modal-voucher-name{
  font-family: Roboto;
  /*font-size: 20px;*/
  font-size: .24rem;
  text-align: center;
}
.modal-voucher-content{
  font-family: Gats;
  letter-spacing: .02rem;
  font-weight: 500;
  font-size: .40rem;
  color: rgb(224, 117, 80);
}
.modal-voucher-limit {
  /*margin-top: 15px;*/
  margin-top: .25rem;
  /*width: 320px;*/
  width: 5.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(214, 244, 244);
  font-size: .20rem;
}
.modal-voucher-btn {
  margin-top: .25rem;
  background: url("../src/imgs/modalVoucher/button_01.webp") center no-repeat;
  width: 6.0rem;
  /*height: 54px;*/
  height: .56rem;
  background-size: contain;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  font-size: .2rem;
  cursor: pointer;
}
.modal-voucher-btn-txt{
  display: block;
  cursor: pointer;
}
.modal-voucher-limit-time-box {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: .08rem;
}
.modal-voucher-limit-time {
  width: .35rem;
  height: .35rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(33, 83, 102);
  border-radius: .04rem;
  margin-left: .06rem;
  margin-right: .06rem;
  color: rgb(224, 117, 80);
  font-weight: bold;
  font-size: .23rem;
}
.modal-voucher-bottom-txt {
  margin-top: 0.20rem;
  color: rgb(214, 244, 244);
  font-size: .19rem;
}

.gamedetail-tab-wrapper {
  margin-top: .2rem;
}
.gamedetail-tab-wrapper .ant-tabs-tab-btn {
   color: #BABEC0;
  font-size: 14px;
}
.gamedetail-tab-wrapper .ant-tabs-tab {
  padding: 12px 0 8px 0;
}
.gamedetail-tab-wrapper .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #F35C00;
    font-weight: bold;
    font-size: 14px;
}
.gamedetail-tab-wrapper .ant-tabs-top > .ant-tabs-nav::before {
  border-color: rgba(186,190,192,0.16);
}
.gamedetail-tab-wrapper .ant-tabs-ink-bar {
  background-color:#F35C00;
}
.overview-wrap .image-wrap img{

  width: 100%!important;
  margin-top: 30px;
  margin-bottom: 30px;
  border-radius: 6px;
}
.overview-wrap  p {
  margin-bottom: 10px;
  font-weight: 500 !important;
  color: #FFFFFF !important;
}

.overview-wrap  p span{
  color: #FFFFFF !important;
}

/*富文本优化样式*/
.clear-rich-text {
  color: white !important;
  white-space: break-spaces !important;
}
.clear-rich-text p {
  min-height: .01rem !important;
  margin-bottom: .15rem !important;
}

.clear-rich-text span {
  color: white !important;
}
.clear-rich-text p {
  color: white !important;
}
.clear-rich-text strong {
  color: white !important;
}
.clear-rich-text h1 {
  font-size: .46rem !important;
  line-height: .7rem !important;
  color: white !important;
}
.clear-rich-text h2 {
  font-size: .40rem !important;
  line-height: .6rem !important;
  color: white !important;
}
.clear-rich-text h3 {
  font-size: .34rem !important;
  line-height: .5rem !important;
  color: white !important;
}
.clear-rich-text h4 {
  font-size: .28rem !important;
  line-height: .4rem !important;
  color: white !important;
}
.clear-rich-text h5 {
  font-size: .22rem !important;
  line-height: .3rem !important;
  color: white !important;
}
.clear-rich-text h6 {
  font-size: .16rem !important;
  line-height: .2rem !important;
  color: white !important;
}
.clear-rich-text h1 span {
  font-size: .46rem !important;
  line-height: .7rem !important;
  color: white !important;
}
.clear-rich-text h2 span {
  font-size: .40rem !important;
  line-height: .6rem !important;
  color: white !important;
}
.clear-rich-text h3 span {
  font-size: .34rem !important;
  line-height: .5rem !important;
  color: white !important;
}
.clear-rich-text h4 span {
  font-size: .28rem !important;
  line-height: .4rem !important;
  color: white !important;
}
.clear-rich-text h5 span {
  font-size: .22rem !important;
  line-height: .3rem !important;
  color: white !important;
}
.clear-rich-text h6 span {
  font-size: .16rem !important;
  line-height: .2rem !important;
  color: white !important;
}

.clear-rich-text a:hover {
  color: #069def !important;
}

.air-rich-text {
  white-space: break-spaces!important;
}
.air-rich-text span {
  background: unset;
  color: unset;
  font-size: unset;
}
.air-rich-text h3 {
  font-size: .3rem;
  color: #fff;
}
.air-rich-text h4 {
  font-size: .26rem;
  color: #fff;
  margin-top: .2rem;
  margin-bottom: 0.05rem;
}
.air-rich-text p {
  font-size: .26rem;
  color: #A8B8C0;
  margin-bottom: 0rem;
}
.air-rich-text ul {
  background: #0a364d;
  margin-top: .4rem;
  padding: .2rem;

}
.air-rich-text  li {
  color: #A8B8C0;
  font-size: .24rem;
}

.air-rich-text-pc {
  white-space: break-spaces!important;
  color: #fff;
  font-weight: 600;
}
.air-rich-text-pc h3 {
  font-size: 15px;
  color: #fff;
  font-weight: 600;
}
.air-rich-text-pc h4 {
  font-size: 13px;
  color: #fff;
  margin-bottom: 15px;
}
.air-rich-text-pc p {
  font-size: 13px;
  color: #A8B8C0;
  margin-bottom: 5px;
}
.air-rich-text-pc ul {
  background: #0a364d;
  margin-top: 20px;
  padding: 10px;

}
.air-rich-text-pc  li {
  color: #A8B8C0;
  font-size: 12px;
}




/*nft 富文本*/
.nft-rich-text {
  color: #000000 !important;
  white-space: break-spaces !important;
}
.nft-rich-text p {
  min-height: .01rem !important;
  margin-bottom: .15rem !important;
}

.nft-rich-text span {
  color: #000000 !important;
}
.nft-rich-text p {
  color: #000000 !important;
}
.nft-rich-text strong {
  color: #000000 !important;
}
.nft-rich-text h1 {
  font-size: .46rem !important;
  line-height: .7rem !important;
  color: #000000 !important;
}
.nft-rich-text h2 {
  font-size: .40rem !important;
  line-height: .6rem !important;
  color: #000000 !important;
}
.nft-rich-text h3 {
  font-size: .34rem !important;
  line-height: .5rem !important;
  color: #000000 !important;
}
.nft-rich-text h4 {
  font-size: .28rem !important;
  line-height: .4rem !important;
  color: #000000 !important;
}
.nft-rich-text h5 {
  font-size: .22rem !important;
  line-height: .3rem !important;
  color: #000000 !important;
}
.nft-rich-text h6 {
  font-size: .16rem !important;
  line-height: .2rem !important;
  color: #000000 !important;
}
.nft-rich-text h1 span {
  font-size: .46rem !important;
  line-height: .7rem !important;
  color: #000000 !important;
}
.nft-rich-text h2 span {
  font-size: .40rem !important;
  line-height: .6rem !important;
  color: #000000 !important;
}
.nft-rich-text h3 span {
  font-size: .34rem !important;
  line-height: .5rem !important;
  color: #000000 !important;
}
.nft-rich-text h4 span {
  font-size: .28rem !important;
  line-height: .4rem !important;
  color: #000000 !important;
}
.nft-rich-text h5 span {
  font-size: .22rem !important;
  line-height: .3rem !important;
  color: #000000 !important;
}
.nft-rich-text h6 span {
  font-size: .16rem !important;
  line-height: .2rem !important;
  color: #000000 !important;
}
.nft-rich-text a:hover {
  color: #069def !important;
}
/*rewards Voucher 富文本*/
.rewards-rich-text {
  color: #9CB4BB !important;
  white-space: break-spaces !important;
  font-size: 13px;
}
.rewards-rich-text p {
  min-height: .01rem !important;
  margin-bottom: .06rem !important;
  font-size: 13px;
}

.rewards-rich-text span {
  color: #9CB4BB !important;
  font-size: 13px;
}
.rewards-rich-text p {
  color: #9CB4BB !important;
  font-size: 13px;
}
.rewards-rich-text strong {
  color: #9CB4BB !important;
  font-size: 13px;
}
.rewards-rich-text h1 {
  font-size: 13px;
  color: #9CB4BB !important;
}
.rewards-rich-text h2 {
  font-size: 13px;
  color: #9CB4BB !important;
}
.rewards-rich-text h3 {
  font-size: 13px;
  color: #9CB4BB !important;
}
.rewards-rich-text h4 {
  font-size: 13px;
  color: #9CB4BB !important;
}
.rewards-rich-text h5 {
  font-size: 13px;
  color: #9CB4BB !important;
}
.rewards-rich-text h6 {
  font-size: 13px;
  color: #9CB4BB !important;
}
.rewards-rich-text h1 span {
  font-size: 13px;
  color: #9CB4BB !important;
}
.rewards-rich-text h2 span {
  font-size: 13px;
  color: #9CB4BB !important;
}
.rewards-rich-text h3 span {
  font-size: 13px;
  color: #9CB4BB !important;
}
.rewards-rich-text h4 span {
  font-size: 13px;
  color: #9CB4BB !important;
}
.rewards-rich-text h5 span {
  font-size: 13px;
  color: #9CB4BB !important;
}
.rewards-rich-text h6 span {
  font-size: 13px;
  color: #9CB4BB !important;
}
.rewards-rich-text a:hover {
  color: #069def !important;
}

/*referral rich text*/
.referral-rich-text {
  color: white !important;
  white-space: break-spaces !important;
}
.referral-rich-text p {
  min-height: .01rem !important;
  margin-bottom: .15rem !important;
}

.referral-rich-text span {
  color: black !important;
}
.referral-rich-text p {
  color: black !important;
}
.referral-rich-text strong {
  color: black !important;
}
.referral-rich-text h1 {
  font-size: .46rem !important;
  line-height: .7rem !important;
  color: black !important;
}
.referral-rich-text h2 {
  font-size: .40rem !important;
  line-height: .6rem !important;
  color: black !important;
}
.referral-rich-text h3 {
  font-size: .34rem !important;
  line-height: .5rem !important;
  color: black !important;
}
.referral-rich-text h4 {
  font-size: .28rem !important;
  line-height: .4rem !important;
  color: black !important;
}
.referral-rich-text h5 {
  font-size: .22rem !important;
  line-height: .3rem !important;
  color: black !important;
}
.referral-rich-text h6 {
  font-size: .16rem !important;
  line-height: .2rem !important;
  color: black !important;
}
.referral-rich-text h1 span {
  font-size: .46rem !important;
  line-height: .7rem !important;
  color: black !important;
}
.referral-rich-text h2 span {
  font-size: .40rem !important;
  line-height: .6rem !important;
  color: black !important;
}
.referral-rich-text h3 span {
  font-size: .34rem !important;
  line-height: .5rem !important;
  color: black !important;
}
.referral-rich-text h4 span {
  font-size: .28rem !important;
  line-height: .4rem !important;
  color: black !important;
}
.referral-rich-text h5 span {
  font-size: .22rem !important;
  line-height: .3rem !important;
  color: black !important;
}
.referral-rich-text h6 span {
  font-size: .16rem !important;
  line-height: .2rem !important;
  color: black !important;
}

.referral-rich-text a:hover {
  color: #069def !important;
}

.lucky-box-carousel {

}
.ant-carousel .lucky-box-carousel .slick-dots {
  bottom: -26px;
}
.ant-carousel .lucky-box-carousel .slick-dots li {
  width: 7px;
  height: 7px;
}
.ant-carousel .lucky-box-carousel  .slick-dots li button {
  height: 100%;
  border-radius: 50%;
}

.gamiezone-comment-wrapper {
  background: #0F1517;
  border-radius: 5px;
  padding-bottom: 40px;

}
.gamiezone-comment-wrapper textarea {
  height: 100px!important;
  color: #fff;
  padding: 26px 20px 40px 60px;
  font-weight: 600;
  overflow: scroll;
}

.comment-user-avatar {
  position: absolute;
  top: 20px;
  left: 15px;
  z-index: 999;
}

/* .free-help-modal .ant-modal-close {
  color: #fff;
  top: -1.3rem;
  right: -.36rem;
} */

.ant-image-mask {
  border-radius: 27px;
}

.ant-image-preview-wrap {
  z-index: 9999 !important;
}

.gamie-zone-img-preview {
  z-index: 9999 !important;
}
.gamie-zone-img-preview .ant-image-preview-wrap {
  z-index: 9999 !important;
}

.gamie-zone-popover {
  z-index: 9999 !important;
}

.gamie-zone-comment-popover {
  z-index: 9999 !important;
}

.gamie-zone-comment-popover .ant-popover-inner-content {
  padding: 0 !important;
}

.gamie-zone-modal {

}
.gamie-zone-modal-wrap {
  z-index: 9999 !important;
}
.gamie-zone-modal .ant-modal-body {
  padding: 0 !important;

}
.gamie-zone-modal .ant-modal-content {
  background: rgba(0, 0, 0, 0);
  padding: 0 !important;
  border-radius: 27px !important;
}
.gamie-zone-modal .ant-modal-close {
  display: none;
}

.home_header_icon{
  background: url("../src/imgs/character/home.svg") no-repeat;
  width: 27px;
  height: 27px;
  background-size: cover;
  margin-left: 20px;
  cursor: pointer;
}

.header_android_icon{
  background: url("../src/imgs/character/andr.png") no-repeat;
  width: 35px;
  height: calc(35px * (136 / 103));
  background-size: contain;
  margin-left: 20px;
  cursor: pointer;
  margin-top: 17px;
}


.header_ios_icon{
  background: url("../src/imgs/character/ios.png") no-repeat;
  width: 32px;
  height: calc(32px * (136 / 86));
  margin-top: 16px;
  background-size: contain;
  margin-left: 30px;
  cursor: pointer;
}

.ant-select-dropdown{
  background: #011118;
  color: #fff !important;
}
.ant-select-item{
  color: #fff !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled){
  background: rgba(255, 255, 255, 0.2);
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled){
  background: rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width: 640px)  {

  .first-charge-wra {
    display: flex;
    align-items: center;
    background-color: unset;
    padding: unset;
    border-radius: 8px;
    margin-right: unset;
    border: unset;
    cursor: pointer;
  }
  .first-charge-wra .first-charge-icon {
    width: 1rem;
    height: .7rem;
    background: url("../src/imgs/home/first_charge.png") top no-repeat;
    background-size: contain;
    /*margin-top: -.1rem;*/
    margin-right: .2rem;
    /*margin-bottom: 20px;*/
  }
  .first-charge-wra .text-first {
    color: #FEFF38;
    font-weight: 500;
    font-size: .15rem;
    /*margin-right: 30px;*/
    cursor: pointer;
  }



  .chat_buy_modal{
    display: flex;
    align-items: center;
    background: #0B0C0E;
    padding: 15px;
    border-radius: 12px;
    height: 200px;
    width: 300px;
    position: relative;
    margin: 0 auto;
  }

  .chat_buy_modal .left_img{
    flex: 2;
    padding: 10px;
  }

  .chat_buy_modal .left_img  img{
    width: 100%;
    height: 130px;
    object-fit: cover;
    border-radius: 10px;
  }

  .chat_buy_modal .right_wrap{
    flex: 3;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 0px;
    margin-left: 10px;
  }

  .chat_buy_modal .right_wrap  .release_btn{
    width: 100%;
    height: 35px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 14px;
    background: linear-gradient(-60deg, #F488B9, #fb9ec8, #F488B9);
    font-weight: 600;
    color: #590B57;
    display: flex;
    justify-content: center;
    align-items: center;
    border: unset;
    box-shadow: 0 0 5px #e969d8;
    transition: .3s all;
    margin-top: 10px;
  }

  .close_btn{
    position: absolute;
    top: 7px;
    right: 15px;
    color: #FFFFFF;
    font-size: 24px;
    cursor: pointer;
  }

  .ruby_icon {
    background: url("../src/imgs/generate/ruby_icon.png") center no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }

  .imomo-discover-collapse-sty {
    background-color: #132025;
    border: unset;
    border-radius: 8px;
    overflow: hidden;
    padding: .05rem .1rem;
  }

  .imomo-discover-collapse-sty .ant-collapse-item {
    border-bottom: unset;
  }

  .imomo-discover-collapse-sty .ant-collapse-item .ant-collapse-header {
    padding: 0;
    margin-bottom: 5px;
  }

  .imomo-discover-collapse-sty .ant-collapse-item .ant-collapse-header .ant-collapse-arrow {
    color: #FFFFFF;
  }
  .imomo-discover-collapse-sty .ant-collapse-content {
    background: #132025;
    border: unset;
    border-radius: 8px;
    overflow: hidden;
    padding: .05rem;
  }
  .imomo-discover-collapse-sty .ant-collapse-content .ant-collapse-content-box {
    padding: unset;
    border-radius: 8px;
    overflow: hidden;
  }

  .overview-wrap .image-wrap img {
    margin-top: .2rem;
    margin-bottom: .2rem;
  }

  .skyhorse-modal .ant-modal-content {
    padding: 10px 15px;
 }

  /*.ant-layout-sider .aside-content {*/
  /*  padding: 25px 11px 0 26px!important;*/
  /*}*/

  #serverID .ant-select-selector {
    height: 46px;
    background: #0E212B !important;
    color: #FFFFFF !important;
  }
  .modal-voucher-contaniner-bg {
    background: url("../src/imgs/modalVoucher/tc_bg_02@2x.png") center no-repeat;
    display: flex;
    flex-direction: column;
    background-size: contain;
    align-items: center;
    width: 6.2rem;
    /*width: 400px;*/
    /*height: 638px;*/
    height: 9.5rem;
  }
  .modal-voucher-title-bg {
    background-size: contain;
    width: 5.0rem;
    /*height: 60px;*/
    height: .35rem;
    /*margin-top: 200px;*/
    margin-top: 3.4rem;
    margin-bottom: .2rem;
  }
  .modal-voucher-bg {
    background: url("../src/imgs/modalVoucher/vou_bg.webp") center no-repeat;
    background-size: contain;
    /*width: 280px;*/
    width: 6.2rem;
    /*height: 160px;*/
    height: 2.45rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: black;
    /*padding: 10px;*/
    padding: .1rem;
    padding-top: .16rem;
  }
  .modal-voucher-name{
    font-family: Roboto;
    /*font-size: 20px;*/
    width: 3.9rem;
    margin-top: .1rem;
    font-size: .38rem;
    text-align: center;
  }
  .modal-voucher-content{
    font-family: Gats;
    letter-spacing: .02rem;
    font-weight: 500;
    font-size: .63rem;
    color: rgb(224, 117, 80);
  }
  .modal-voucher-limit {
    /*margin-top: 15px;*/
    margin-top: .25rem;
    /*width: 320px;*/
    width: 5.2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(214, 244, 244);
    font-size: .31rem;
  }
  .modal-voucher-btn {
    margin-top: .25rem;
    background: url("../src/imgs/modalVoucher/button_01.webp") center no-repeat;
    width: 6.0rem;
    /*height: 54px;*/
    height: .75rem;
    background-size: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-weight: bold;
    font-size: .30rem;
    cursor: pointer;
  }
  .modal-voucher-btn-txt{
    display: block;
    cursor: pointer;
  }
  .modal-voucher-limit-time-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: .08rem;
  }
  .modal-voucher-limit-time {
    width: .45rem;
    height: .45rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(33, 83, 102);
    border-radius: .04rem;
    margin-left: .06rem;
    margin-right: .06rem;
    color: rgb(224, 117, 80);
    font-weight: bold;
    font-size: .32rem;
  }
  .modal-voucher-bottom-txt {
    margin-top: 0.20rem;
    color: rgb(214, 244, 244);
    font-size: .25rem;
  }

  .no-points-modal-content {

    background: url('../src/imgs/no-points-modal-bg.webp') center no-repeat;
    width: 6rem;
    height: 7.812rem;
    background-size: contain;
    margin-top: 100px;
    position: relative;
    padding: 1.9rem .5rem .8rem .5rem;
    animation: animation-modal 0.3s ease-in-out 0s 1 normal none running;
  }
  .no-points-modal-content .no-points-title {
    font-size: .4rem;
    color: #fff;
    font-weight: 600;
    text-align: center;
  }
  .no-points-modal-content .no-points-cart {
    width: 3.58rem;
    height: 1.716rem;
  }
  .no-points-modal-content .no-points-title-1 {
    font-size: .4rem;
    color: #fff;
    font-weight: 600;
    text-align: center;
    color: rgb(207,162,53);
    margin-top: .2rem;
  }
  .no-points-modal-content .btn-wrapper {
    position: absolute;
    bottom: .7rem;
    height: 70px;
    width: 100%;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 .6rem;
}
  .no-points-modal-content .cancel-btn {
    /* flex: 1; */
    /* height: 100%; */
    cursor: pointer;
    width: 2.3rem;
    height: 1rem;
    color: #fff;
    border: 2px solid rgb(28,66,77);
    border-radius: 10px;
    font-weight: 600;
    line-height: 1rem;
    text-align: center;
    font-size: .4rem;

  }
  .no-points-modal-content .confirm-btn {
    /* flex: 1;  */
    /* height: 100%; */
    width: 2.3rem;
    height: 1rem;
    color: #fff;
    cursor: pointer;
    background: linear-gradient(180deg,#b38f29 42%,#d7b144);
    border: 2px solid rgba(208,170,62,.79);
    border-radius: 10px;
    font-weight: 600;
    line-height: 1rem;
    text-align: center;
    font-size: .4rem;
  }

  .ant-input-number-change {

  }

  .ant-input-number-change .ant-input-number-input {
    padding: 0 .08rem;
  }

  .img-modal-select {
    width: 3.1rem
  }

  .img-modal-select-option {
    font-size: .28rem;
  }

  .img-modal-select-option .usdt-icon-igm-modal-erc20{
    width: .4rem;
    height: .4rem;
  }

  .img-modal-select-option .usdt-icon-igm-modal-bep20 {
    width: .4rem;
    height: .4rem;
  }

  .img-modal-select .ant-select-arrow {
    right: .2rem !important;
  }


  .ant-notification {
    margin-right: 0rem;
    margin-top: unset;
  }

  .ant-notification-top {
    width: 100%;
    top: 1.1rem !important;
    bottom: unset !important;
    margin-right: unset !important;
    right: unset !important;
    left: unset !important;
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
  }

  .IGMWithdrawal-notification-mobile {
    width: 5.6rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: .1rem;
    padding-bottom: .1rem;
  }

  .IGMWithdrawal-notification-mobile .ant-notification-notice-message {
    display: none;
  }

  .IGMWithdrawal-notification-mobile .ant-notification-notice-close {
    display: none;
  }

  .IGMWithdrawal-notification-success {
    background: linear-gradient(270deg, #29302A 0%, rgba(29,48,24,0.81) 100%);
    border-radius: 10px;
  }
  .IGMWithdrawal-notification-failed {
    background: linear-gradient(270deg, #292725 0%, rgba(51,38,12,0.81) 100%);
    border-radius: 10px;
  }

  .IGMWithdrawal-notification-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 24px;
    font-size: .24rem;
    font-weight: 550;
    color: #FFFFFF;
    line-height: 24px;
  }
  .IGMWithdrawal-notification-content .igm-notification-content-header-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .IGMWithdrawal-notification-content .igm-notification-content-header-wrapper .igm-notification-icon-success {
    width: .34rem;
    height: .34rem;
    background: url("../src/imgs/igm_withdrawal/notification_success_icon.webp") center no-repeat;
    background-size: contain;
    margin-right: 10px;
  }
  .IGMWithdrawal-notification-content .igm-notification-content-header-wrapper .igm-notification-icon-failed {
    width: .34rem;
    height: .34rem;
    background: url("../src/imgs/igm_withdrawal/notification_failed_icon.webp") center no-repeat;
    background-size: contain;
    margin-right: 10px;
  }

  .IGMWithdrawal-notification-content .igm-notification-content {
    width: auto;
    text-align: center;
  }

  .IGMWithdrawal-notification-content .igm-notification-content .igm-notification-content-reason {
    line-height: 15px;
    width: auto;
    text-align: center;
  }




  .card-modal {
    width: 7rem!important;

  }

  .card-modal .ant-modal-content{
    background: unset;
    box-shadow: unset;
 }
 .card-modal .ant-modal-close {
   color: #fff;
   top: -30px;
   right: 0px;
 }
 .card-modal .ant-modal-close-x {
   font-size: 23px;
 }

 .card-modal .card-wrapper {
   overflow: hidden;
 }
 .card-modal .card-m-front, .card-modal .card-m-reverse {
   position: absolute;
   backface-visibility: hidden;
   transition: transform 0.5s linear;

 }

 .card-modal .card-wrapper .card-nft-wrapper {
  background: url('http://static-img.igamie.com/static/nftStatic/nft_item_mobile_bg.webp') center no-repeat;
  background-size: cover;
  width: 6.16rem;
  padding: 20px;
  border-radius: 8px;
  height: unset;
}
.card-modal .card-wrapper .card-nft-wrapper .nft-banner {
  width: 100%;
  height: 6.4rem;
  object-fit: cover;
}
.card-modal .nft-name {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin: 20px auto 0px;
  text-align: center;
}




 .card-modal .card-m-front {
   transform: perspective(600px) rotateY(0deg);
 }
 .card-modal .card-m-front img {
   width: 6.16rem;
 }


 .card-modal .card-m-front-rotate {
   transform: perspective(600px) rotateY(-180deg);
 }
 .card-modal .card-m-reverse-rotate  {
   transform: perspective(600px) rotateY(360deg);
 }
 .card-modal .card-name {
   font-size: 18px;
   text-align: center;
   font-weight: 600;
 }

 .card-voucher-price {
   font-size: 23px;
   line-height: 30px;
   font-weight: 600;
   color: #f0774a;
 }
 .card-voucher-time {
   color: #666;
   font-size: 13px;
 }
 .card-description {
   font-size: 14px;
   color: #b3b9d8;
   margin-top: 15px;
 }
  .card-description-scroll {
    font-size: 14px;
    color: #b3b9d8;
    margin-top: 15px;
    height: 200px;
    white-space: pre-wrap;
    overflow: scroll;
  }
  .card-nft-modal .card-description-scroll {
    height: 5.8rem;
  }
 .card-user-btn {
   background: linear-gradient(180deg,#b38f29 42%,#d7b144);
   border: 2px solid rgba(208,170,62,.79);
   border-radius: 4px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   height: 48px;
   line-height: 18px;
   position: absolute;
   bottom: 35px;
   width: 5.075rem;
   padding-top: .05rem;
 }
 .card-user-btn-dis {
  width: 5.075rem;
  background: #999;

 }
 .card-user-btn .text-1{
    font-size: 16px;
    color: #fff;
 }
 .card-user-btn .text-2 {
   font-size: 13px;
   color: #F9F5EB;
 }


  .wallet-modal {
    border-radius: 1px 1px 1px 1px;
    overflow: hidden;
  }
  .wallet-modal .ant-modal-content {
    border: 1px solid #1F454C;
    background: rgba(255,255,255,0);
    border-radius: 8px 8px 8px 8px;
    overflow: hidden;
    margin: 0 0.4rem;
  }

  .wallet-modal .ant-modal-close {
    /*margin-right: 8px;*/
  }
  .wallet-modal .ant-modal-close-icon {
    color: #fff;
    font-size: 20px;
  }

  .wallet-modal .ant-modal-close-x{
    line-height: 43px;
  }

  .wallet-modal .ant-modal-body {
    padding: 0;
    overflow: hidden;
  }

  .wallet-modal .veri-header {
    background: #031E35;
    /* padding: 15px 30px 15px 30px; */
    padding: .17rem .3rem .17rem .47rem;
    font-size: 17px;
    color: #fff;
    font-weight: 600;
  }

  .blind-box-modal {
    border-radius: 1px 1px 1px 1px;
    /*overflow: hidden;*/
  }
  .blind-box-modal .ant-modal-content {
    /*border: 1px solid #1F454C;*/
    background: rgba(255,255,255,0);
    /*background: red;*/
    border-radius: 8px 8px 8px 8px;
    /*overflow: hidden;*/
    padding-bottom: .9rem;
    padding-top: .9rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .blind-box-modal .ant-modal-close {
    /*margin-right: 8px;*/
    margin-right: .53rem;
  }
  .blind-box-modal .ant-modal-close-icon {
    color: #fff;
    font-size: 25px;
    font-weight: 700;
    color: #3E4E92;
    font-family: 'Gats';
  }

  .blind-box-modal .ant-modal-close-x{
    line-height:46px;
  }

  .blind-box-modal .ant-modal-body {
    padding: 0;
    overflow: hidden;
  }

  .blind-box-modal .veri-header {
    background: #031E35;
    /* padding: 15px 30px 15px 30px; */
    padding: .17rem .3rem 2.17rem .47rem;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    width: 100%;
    height: .5rem;
    background: rgba(255,255,255,0);
    display: flex;
    justify-content: flex-end;
  }

  /*注册弹窗*/
  .register-modal {
    border-radius: 1px 1px 1px 1px;
    /*overflow: hidden;*/
  }
  .register-modal .ant-modal-content {
    /*border: 1px solid #1F454C;*/
    background: rgba(255,255,255,0);
    /*background: red;*/
    border-radius: 8px 8px 8px 8px;
    /*overflow: hidden;*/
    padding-bottom: .5rem;
    padding-top: .5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .register-modal .ant-modal-close {
    /*margin-right: 8px;*/
    margin-right: .39rem;

  }
  .register-modal .ant-modal-close-icon {
    font-size: 30px;
    font-weight: 700;
    color: #3E4E92;
    display: none;
  }

  .register-modal .mobile-close-icon-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: .42rem;
  }

  .register-modal .mobile-close-icon-container .ant-modal-close-icon-mobile {
    font-size: 30px;
    font-weight: 700;
    color: #3E4E92;
  }

  .register-modal .ant-modal-close-x{
    line-height:46px;
  }

  .register-modal .ant-modal-body {
    padding: 0;
    overflow: hidden;
  }

  .register-modal .veri-header {
    background: #031E35;
    /* padding: 15px 30px 15px 30px; */
    padding: .07rem .3rem .07rem .3rem;
    font-size: 16px;
    color: #fff;
    font-weight: 600;
    width: 100%;
    height: .5rem;
    background: rgba(255,255,255,0);
    display: flex;
    justify-content: flex-end;
  }

  .register-modal .img-container {
    margin-top: .5rem;
    width: 5.7rem;
    height: 6.55rem;
  }

  .register-modal .img-container .register-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .create-wallet-loading {
    color: red;
    position: absolute;
    left: 50%;
    top: 75%;
    transform: translateX(-50%) translateY(-50%);
  }
  .create-wallet-loading .ant-spin-dot {
    font-size: 26px;
  }
  .create-wallet-loading .ant-spin-dot .ant-spin-dot-item{
    width: 13px;
    height: 13px;
    background-color: #D4A82B;
  }

  .create-wallet-btn-loading {
    color: red;
  }
  .create-wallet-btn-loading .ant-spin-dot {
    font-size: 25px;
  }
  .create-wallet-btn-loading .ant-spin-dot .ant-spin-dot-item{
    width: 12px;
    height: 12px;
    background-color: #D4A82B;
  }

 .wallet-success-icon {
  background: url('../src/imgs/coins_di_check.png') center no-repeat;
  background-size: contain;
  width: .29rem;
  height: .29rem;
  display: inline-block;
  margin-right: 5px;
  margin-bottom: .04rem;
}

    .free-challenge-progress .ant-progress-inner{
      background: #c7b7fd;
    }
    .free-challenge-progress .ant-progress-bg {
      height: .3rem!important;
    }


    .free-collect-modal .ant-modal-body {
      padding: 0rem .18rem;
    }
    .free-collect-modal  .ant-modal-content{
      background: url('http://static-img.igamie.com/free-collect-modal-bg.webp') center no-repeat;
      background-size: contain;
      /* height: 533px; */
      width: 5.71rem;
      height: 8.19rem;
      margin:  0 auto;
      padding-top: 1rem;
    }




    .free-help-modal .ant-modal-body  {
      padding: 0rem .18rem 0;
    }
    .free-help-modal .ant-modal-content {
      background: url('https://static-img.igamie.com/static/nftStatic/free_help_modal_1.png') center no-repeat;
      background-size: contain;
      /* height: 533px; */
      width: 5.71rem;
      height: 7.52rem;
      margin:  0 auto;
      padding-top: 1rem;
      padding-top: 0;
    }
    .free-help-modal .ant-modal-content .help-title {
      text-align: center;
      position: relative;
      top: -.36rem;
    }
    .free-help-modal .red-title {
       color: rgb(242,0,171);
       font-size: .5rem;
       margin-left: .2rem;
       font-weight: 600;
    }
    .free-help-modal .white-title {
       color: #fff;
       font-size: .4rem;
       font-weight: 700;
       margin-left: .2rem;
       line-height: 1.3;
       margin-bottom: .1rem;
    }
    .free-help-modal .award-title {
      margin-top: .3rem;
      color: #fff;
      font-size: .5rem;
      text-align: center;
    }
    .free-help-modal .award-wrapper {
      width: 100%;
      height: 4.9rem;
      padding: .4rem .4rem 0 .4rem;
    }
    .free-help-modal .award-pic-wra {
       width: 3rem;
       height: 2.6rem;
       margin: 0 auto;
    }
    .free-help-modal .award-pic {
      max-width: 100%;
      max-height: 100%;
    }
    .free-help-modal .award-name {
      font-size: .4rem;
      color: #fff;
      font-weight: 700;
      text-align: center;
      margin-top: .15rem;
    }
    .free-help-modal .modal-bottom-btn {
      background: linear-gradient(180deg, #c731ff 0%, #8641fe 100%);
      border-radius: 40px;
      width: 5rem;
      height: .9rem;
      line-height: .9rem;
      text-align: center;
      font-weight: 700;
      color: #fff;
      position: absolute;
      bottom: .35rem;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
    }

    .free-help-modal-complete .ant-modal-body  {
      padding: 0rem .18rem 0;
   }
   .free-help-modal-complete .ant-modal-content {
     background: url('https://static-img.igamie.com/static/nftStatic/free_help_modal_2.png') center no-repeat;
     background-size: contain;
     /* height: 533px; */
     width: 5.71rem;
     height: 7.47rem;
     margin:  0 auto;
     padding-top: 1rem;
     padding-top: 0;
   }
   .free-help-modal-complete .ant-modal-content .help-title {
     text-align: center;
     position: relative;
     top: 0.5rem;
   }
   .free-help-modal-complete .white-title {
     color: #fff;
     font-size: .4rem;
     font-weight: 600;
     margin-left: .2rem;
     line-height: 1.3;
  }
  .free-help-modal .award-pic-wra {
    margin-top: .2rem;
  }
  .free-help-modal-complete .award-progress {
    display: none;
  }
  .free-help-modal-complete .modal-bottom-btn {
    background: unset;
    font-size: .4rem;
    bottom: .1rem;
  }

  .free-help-modal-complete .award-wrapper {
    width: 100%;
    height: 4.9rem;
    padding: 1.8rem .4rem 0 .7rem!important;
  }


    .free-collect-modal .place-1 {
        height: 1rem;
        line-height: 1rem;
        padding-top: 0;
        font-size: .4rem;
        color: #fff;
        text-align: center;
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .free-collect-modal .place-2 {
        height: 4.1rem;
        padding-top: .1rem;
    }

    .free-collect-modal .free-modal-banner {
        width: 3.06rem;
        height: 2.58rem;
        margin: .5rem auto 0;
    }

    .free-collect-modal .free-modal-banner-pic {
        max-width: 100%;
        max-height: 100%;
    }
    .free-collect-modal .free-modal-banner-name {
        color: #fff;
        font-size: .34rem;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-weight: 700;
        margin-top: .15rem;
    }

    .free-collect-modal .place-3 {
        height: 3rem;
        padding: .26rem 0px 0;
    }

    .free-collect-modal .share-wrapper {
        font-size: .28rem;
        color: #fff;
        font-weight: 600;
    }

    .free-collect-modal .collect-yes-btn {
        width: 4.8rem;
        height: .8rem;
        line-height: .8rem;
        background: linear-gradient(180deg, #e20ffb 0%,  #6d1df9 100%);
        box-shadow: 0px 3px 1px 2px rgb(32 16 58 / 24%);
        border-radius: 100px;
        margin: 0 auto;
        color: #fff;
        font-size: .3rem;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
    }
    .free-collect-modal .collect-no-btn {
        width: 4.8rem;
        height: .8rem;
        line-height: .8rem;
        background: #d3dfff;
        box-shadow: 0px 3px 1px 2px rgb(32 16 58 / 24%);
        border-radius: 100px;
        margin: 0 auto;
        color: #fff;
        font-size: .3rem;
        font-weight: 600;
        text-align: center;
        margin-top: 10px;
    }

    .free-collect-modal .free-has-reward {
        margin-top: .6rem;
        text-align: center;

    }
    .free-collect-modal .free-has-reward .left {
      font-size: .28rem;
      font-weight: 700;
      color: #fff;
    }
    .free-collect-modal .free-has-reward .right {
      font-size: .28rem;
      font-weight: 700;
      color: #fc29bb;
      margin-left: .1rem;
      text-decoration: underline;
    }

    .free-help-modal .ant-modal-close {
      color: #fff;
      top: -1.3rem;
      right: -.36rem;
    }


    /*nft 富文本*/
    .nft-rich-text {
      color: white !important;
      white-space: break-spaces !important;
    }
    .nft-rich-text p {
      min-height: .01rem !important;
      margin-bottom: .15rem !important;
    }

    .nft-rich-text span {
      color: white !important;
    }
    .nft-rich-text p {
      color: white !important;
    }
    .nft-rich-text strong {
      color: white !important;
    }
    .nft-rich-text h1 {
      font-size: .46rem !important;
      line-height: .7rem !important;
      color: white !important;
    }
    .nft-rich-text h2 {
      font-size: .40rem !important;
      line-height: .6rem !important;
      color: white !important;
    }
    .nft-rich-text h3 {
      font-size: .34rem !important;
      line-height: .5rem !important;
      color: white !important;
    }
    .nft-rich-text h4 {
      font-size: .28rem !important;
      line-height: .4rem !important;
      color: white !important;
    }
    .nft-rich-text h5 {
      font-size: .22rem !important;
      line-height: .3rem !important;
      color: white !important;
    }
    .nft-rich-text h6 {
      font-size: .16rem !important;
      line-height: .2rem !important;
      color: white !important;
    }
    .nft-rich-text h1 span {
      font-size: .46rem !important;
      line-height: .7rem !important;
      color: white !important;
    }
    .nft-rich-text h2 span {
      font-size: .40rem !important;
      line-height: .6rem !important;
      color: white !important;
    }
    .nft-rich-text h3 span {
      font-size: .34rem !important;
      line-height: .5rem !important;
      color: white !important;
    }
    .nft-rich-text h4 span {
      font-size: .28rem !important;
      line-height: .4rem !important;
      color: white !important;
    }
    .nft-rich-text h5 span {
      font-size: .22rem !important;
      line-height: .3rem !important;
      color: white !important;
    }
    .nft-rich-text h6 span {
      font-size: .16rem !important;
      line-height: .2rem !important;
      color: white !important;
    }
    .nft-rich-text a {
      color: #069def !important;
    }
    .clear-rich-text a {
      color: #069def !important;
    }
    .rewards-rich-text a {
      color: #069def !important;
    }
    .nft-order-complete-modal {
       width: 5.24rem!important;
       zoom: unset;
    }

    .nft-order-complete-modal .ant-modal-body {
      padding: .6rem .6rem;

    }
    .nft-order-complete-modal .nft-order-modal-close-btn  {
      width: .4rem;
      height: .4rem;
      background: url('https://static-img.igamie.com/static/nftStatic/nft-complete-modal-close.png') center no-repeat;
      background-size: contain;
      position: absolute;
      top: -.8rem;
      right: 0;
      cursor: pointer;
    }
    .nft-order-complete-modal .nft-order-c-icon {
      width: 1.7rem;
      height: 1.7rem;
      margin: 0 auto;
    }
    .nft-order-complete-modal .icon-img {
      max-width: 100%;
      max-height: 100%;
    }

    .nft-order-complete-modal .nft-order-m-title {
      font-size: .3rem;
      color: #fff;
      margin-top: .25rem;
      font-weight: 600;
      text-align: center;
    }
    .nft-order-complete-modal .nft-order-m-des  {
      font-size: .24rem;
      color: #A3ACAF;
      margin-top: .25rem;
      text-align: center;
    }
    .nft-order-complete-modal .nft-order-modal-btn {
      background: linear-gradient(90deg, #1D73D0 0%, #8600C6 100%);
      box-shadow: 0px 10px 14px -5px rgba(98,54,255,0.05);
      border-radius: .08rem;
      width: 4rem;
      height: .82rem;
      margin-top: .3rem;
      color: #fff;
      line-height: .82rem;
      font-weight: 600;
      text-align: center;

    }


    .nft-order-complete-modal .ant-modal-content {
      background: url('http://static-img.igamie.com/static/nftStatic/nft-order-modal-c-bg.png') center no-repeat;
      height: 6.11rem;
      background-size: contain;
    }

    .nft-order-complete-modal-confirm .ant-modal-content{
      height: 4.5rem;
      background: url('../src/imgs/order_confirm.png') center no-repeat;
      background-size: contain;
    }
    .nft-order-complete-modal .ant-modal-body {
      height: 100%;
    }

    .blindbox-connect-modal .ant-modal-content {
      background: rgba(0,0,0,0);
    }
    .blindbox-connect-modal .with-draw-modal {
        background: #003049;
        height: 3.2rem;
        border-radius: 20px;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 0.75rem;
        padding: 0.35rem 0.4rem 0.5rem;
        position: relative;
    }
    .blindbox-connect-modal .close-btn {
      position: absolute;
        top: -.7rem;
        right: 0;
        background: url('../src/imgs/close_btn_icon.png') no-repeat;
        background-size: contain;
        width: .4rem;
        height: .4rem;
        cursor: pointer;
    }
    .blindbox-connect-modal .modal-title {
        color: #fff;
        font-size: .4rem;
        font-weight: 600;
        text-align: center;
    }
    .blindbox-connect-modal .modal-next-btn  {
        background: linear-gradient(90deg,#1d73d0,#8600c6);
        border: none;
        border-radius: 4px;
        -webkit-box-shadow: 0 10px 14px -5px rgb(98 54 255 / 5%);
        box-shadow: 0 10px 14px -5px rgb(98 54 255 / 5%);
        color: #fff;
        cursor: pointer;
        font-size: .34rem;
        font-weight: 600;
        height: 0.9rem;
        line-height: .8rem!important;
        margin-top: 0.3rem;
        text-align: center;
    }

    .blindbox-connect-modal .air-drop-winner-modal {
      padding: .5rem;
      display: block;
    }
    .app-layout .header-copy {
      /* display: none; */
      height: 1rem;
    }
    .content-container > .ant-layout {
      margin-right: 0;
      border-radius: 0!important;
    }
    .app-layout .aside-copy {
      display: none;
    }
    .footer {
      padding: .36rem .36rem .65rem .36rem;
    }
    .footer-wrapper {
       /* display: none; */
       width: 100%;
       font-size: 12px;
       flex-direction: column;
    }
    .footer-wrapper .footer-left {
      display: none;
    }
    .footer-logo-wrapper {
      display: none;
    }
    .copyright-wrapper {
      display: flex;
      flex-direction: column;
    }
    .copyright-wrapper a, .copyright-wrapper span {
      margin-bottom: 10px;
    }
    .footer-right .footer-link-wrapper {
       text-align: left;
       border-bottom: 1px solid #191F21;
    }
    .footer-right a {
      color: #7e8487;
      font-weight: 500;
      font-size: .26rem;
    }

    .footer-bottom {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: .4rem;
    }
    .footer-bottom  .footer-bottom-item,  .footer-bottom  .footer-bottom-item a {
         color: #7e8487;
         font-size: 12px;
         font-weight: 500;
         transform: scale(.9);

    }
    .footer-bottom  .footer-bottom-line {
       width: 1px;
       height: 10px;
       background-color: #45494b;
       margin: 0 10px;
    }

    .login-menu-ty {
      margin: 0 0px 0 .3rem;
    }


    .content-container {
      background: unset;
    }
    .menu-tooltip {
      display: none;
    }

    .discover-loading {
      top: 30%;
    }

    .airdrop-tabs {
       margin-top: .15rem;
    }
    .airdrop-tabs .ant-tabs-tab {
      font-size: .38rem;
      padding: .3rem 0;
    }
    .airdrop-tabs .ant-tabs-tab + .ant-tabs-tab {
      margin: 0 0 0 .8rem;
    }
    .airdrop-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
      font-size: .38rem;
    }

    .pc-show {
      display: none;
    }
    .mobile-show {
      display: block;
    }

    .game-iframe {
      width: 100%;
      height: 100%;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9999;
      border-radius: 0;
    }
    .game-modal-index {
      z-index: 99999;
    }
    .home-banner .ant-carousel .slick-dots li button {
       height: 2px;
    }
    .top-banner-skeleton {
      width: 100%!important;
      height: 3.2rem!important;
    }

    .app-layout .left-mobile-title {
       font-size: .38rem;
    }
    .app-layout .content-container .right-mobile-title {
      font-size: .26rem;
    }

    .mobile-show .ant-carousel .slick-initialized .slick-slide {
       min-height: 2.6rem;
    }

    .notice-bar-x {
      color: #fff;
      display: none;
    }
    .notice-bar-x-mobile {
      display: block;
      color: #fff;
      margin: .2rem 0 .2rem .4rem;
      background: url('../src/imgs/notice_bar_mobile.png') center no-repeat;
      background-size: 100% 100%;
      height: .8rem;
      font-size: .2rem;
      font-weight: 700;
      position: relative;
      padding-left: .5rem;
    }
    .notice-bar-x-mobile ::after {
      content: '';
      width: .8rem;
      height: .8rem;
      background: url('../src/imgs/notice_bar_music.png') center no-repeat;
      background-size: contain;
      position: absolute;
      left: -.9rem;
      top: -0.07rem;
    }
    .notice-bar-x-mobile .slick-list {
      /* height: .8rem!important; */
    }
    .notice-bar-x-mobile .slick-slider {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .notice-bar-x-mobile .slick-arrow {
      display: none!important;
    }
    .notice-bar-x-mobile .slick-slide {
      /* margin-top: .2rem; */
      overflow: hidden;
      white-space: nowrap;
    }
    .notice-bar-x-mobile .slick-track {
      padding-top: .12rem;
    }
    .nftSellChoose-modal {
      width: 6.74rem!important;
    }
    .nftSellChoose-modal .ant-modal-close-x {
      margin-top: .5rem;
      width: .34rem;
      height: .34rem;
    }
    .nftSellChoose-modal .ant-modal-content {
      padding: .5rem .45rem;
    }
    .my-masonry-grid {
      margin-left: -.15rem;
      width: 7.25rem;
    }
    .my-masonry-grid_column {
      padding-left: .15rem;
    }

  .gamie-zone-modal {

  }
  .gamie-zone-modal-wrap {
    z-index: 9999 !important;
  }
  .gamie-zone-modal .ant-modal-body {
    padding: 0 !important;
  }
  .gamie-zone-modal .ant-modal-content {
    background: rgba(0, 0, 0, 0);
    padding: 0 .2rem !important;
    border-radius: 10px !important;
  }
  .gamie-zone-modal .ant-modal-close {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .notice-popover-i {
    display: none;
  }
  .coin-logo-i {
    /* display: none; */
  }
  .footer-wrapper-1 .footer-bo-new {
    width: 100%;
  }
}


